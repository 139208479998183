import React, { Component } from 'react';
import './filters.css';
import FilterBlock from './filter-block.js';
import FilterBlockTalent from './filter-block-talent.js';
import FilterBlockSalary from './filter-block-salary.js';
import FilterData from '../../../data/filter-data.js';

class Filters extends Component {

  constructor(props) {
    super(props);
    this.state = {
      learningAgilityValues: {},
      leadershipAbilitiesValues: {},
    };
  }

  componentDidMount() {
    console.log("all the specczz ", this.props.specializations);
  }

  render() {
    console.log("REAL SPECIALTIES ", this.props.specialtiesDisplayed);
    let specialties = this.props.specialtiesDisplayed;
    if (!specialties) {
      specialties = {}
    }
    //console.log("after the fact ", specialties);
    //console.log("all the ones ", FilterData["specialties"]);
    return (
      <div className='filters'>
        <div className='filters-container'>
          <div className='filters-section-label'>
            {"Filter and sort by interview performance"}
          </div>

          <FilterBlockTalent
            filterName="Learning Agility"
            filterTalentChange={this.props.filterTalentChange}
          />

          <FilterBlockTalent
            filterName="Openness to Feedback"
            filterTalentChange={this.props.filterTalentChange}
          />

          <FilterBlockTalent
            filterName="Ambition"
            filterTalentChange={this.props.filterTalentChange}
          />

          <FilterBlockTalent
            filterName="Getting Work Done"
            filterTalentChange={this.props.filterTalentChange}
          />

          <FilterBlockTalent
            filterName="Leadership"
            filterTalentChange={this.props.filterTalentChange}
          />

          <FilterBlockTalent
            filterName="Collaboration"
            filterTalentChange={this.props.filterTalentChange}
          />



          <div className='filters-section-label'>
            Filter by job preferences
          </div>

          <FilterBlock
            filterName="Role type"
            filterReqChange={this.props.filterReqChange}
            options={FilterData["positionType"]}
          />

          <FilterBlock
            filterName="Role specializations"
            filterReqChange={this.props.filterReqChange}
            options={this.props.specialtiesDisplayed}
          />

          <FilterBlock
            filterName="Role level"
            filterReqChange={this.props.filterReqChange}
            options={FilterData["level"]}
          />

          <FilterBlock
            filterName="Location"
            filterReqChange={this.props.filterReqChange}
            options={FilterData["locations"]}
          />

          <FilterBlockSalary
            filterName="Salary"
            filterSalaryChange={this.props.filterSalaryChange}
          />

          <FilterBlock
            filterName="Company Size"
            filterReqChange={this.props.filterReqChange}
            options={FilterData["companySizes"]}
          />

          {/*}<FilterBlock
            filterName="Job search status"
            filterReqChange={this.props.filterReqChange}
            options={FilterData["jobSearchStatus"]}
          />*/}

          <FilterBlock
            filterName="Work authorization"
            filterReqChange={this.props.filterWorkAuthChange}
            options={FilterData["workAuth"]}
          />

          <FilterBlock
            filterName="Contacted"
            filterReqChange={this.props.filterReqChange}
            options={FilterData["contacted"]}
          />

        </div>
      </div>
  )}
}

export default Filters;
