import React from 'react'

const FilterPillX = props => (
  <svg width={9} height={9} {...props}>
    <path
      d="M4.5 4.5l3.182-3.182L4.5 4.5l3.182 3.182L4.5 4.5zm0 0L1.318 7.682 4.5 4.5 1.318 1.318 4.5 4.5z"
      stroke="#FFF"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="square"
    />
  </svg>
)

export default FilterPillX
