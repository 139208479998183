import React, { Component } from 'react';
import './filter-block-talent.css';
import FilterPlus from '../../../svg/filter-plus.js';
import FilterMinus from '../../../svg/filter-minus.js';
import FilterPillX from '../../../svg/filter-pill-x.js';
import ReactTooltip from 'react-tooltip'

import {DEFAULT_TALENT_MAX_PERCENTILE, DEFAULT_TALENT_MIN_PERCENTILE, DEFAULT_TALENT_MULTIPLIER} from "./filter-constants.js"
class FilterBlockTalent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
      minValue:DEFAULT_TALENT_MIN_PERCENTILE,
      maxValue:DEFAULT_TALENT_MAX_PERCENTILE,
      multiplierValue:DEFAULT_TALENT_MULTIPLIER,
    };
    this.toggleExpanded = this.toggleExpanded.bind(this);
  }

  toggleExpanded() {
    this.setState(state => ({
      isExpanded: !state.isExpanded
    }))
  }

  onMinChange(value) {
    this.setState({minValue: value});
    //this.props.filterTalentChange(this.props.filterName, this.state);
    this.props.filterTalentChange(this.props.filterName, "minValue", value);
  }

  onMaxChange(value) {
    this.setState({maxValue: value});
    this.props.filterTalentChange(this.props.filterName, "maxValue", value);
  }

  onMultiplierChange(value) {
    this.setState({multiplierValue: value});
    this.props.filterTalentChange(this.props.filterName, "multiplierValue", value);
  }

  render() {
    const borderStyle = {
      // borderBottom: this.state.isExpanded? "1px solid #226CFF":"1px solid #EBEDF2",
      // borderTop:this.state.isExpanded?"1px solid #226CFF":"none",
      // borderRight:this.state.isExpanded?"1px solid #226CFF":"none",
      // borderLeft:this.state.isExpanded?"1px solid #226CFF":"none",
    }


    return (
      <div className='filter-block-talent' style={borderStyle}>
        <div  onClick={this.toggleExpanded} className='filter-block-talent-top-container'>
          <div className='filter-block-talent-title'>
            {this.props.filterName}
          </div>
          <div className='filter-block-talent-plus-container'>
            {this.state.isExpanded ?

            <FilterMinus
              className='filter-block-talent-minus'
            />
            :
            <FilterPlus
              className='filter-block-talent-plus'
            />
            }
          </div>
        </div>

        {!this.state.isExpanded ? null:
        <div className='filter-block-talent-expanded'>
          <div className='filter-block-talent-input-label'>
            Minimum percentile shown
          </div>

          <input
            type='number'
            placeholder='e.g. 50'
            value={this.state.minValue}
            className='filter-block-talent-input'
            onChange={(e) => this.onMinChange(e.target.value)}
          />

          <div className='filter-block-talent-input-label'>
            Maximum percentile shown
          </div>

          <input
            type='number'
            placeholder='e.g. 50'
            value={this.state.maxValue}
            className='filter-block-talent-input'
            onChange={(e) => this.onMaxChange(e.target.value)}
          />

          <div className='filter-block-talent-input-label'>
            Importance multiplier (<a data-tip data-for="explainer" className='filter-tooltip-underline'>{"what's this?"}</a>)
            <ReactTooltip className='tooltip' id='explainer' place="right" type="dark" effect="solid">
              <span>
                As you increase the multiplier, more importance is placed on {this.props.filterName} in your search results (relative to the other interview skills).
                <br/><br/>
                The default is 1. Set multiplier to 0 to ignore {this.props.filterName} in search results.

              </span>
            </ReactTooltip>
          </div>

          <input
            type='number'
            placeholder='e.g. 1'
            value={this.state.multiplierValue}
            className='filter-block-talent-input'
            onChange={(e) => this.onMultiplierChange(e.target.value)}
          />


        </div>
        }
      </div>
  )}
}

export default FilterBlockTalent;
