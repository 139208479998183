const FilterData = {
  "locations":
    {
      "Anywhere":{"key": "locations", "label":"Anywhere","selected":false},
      "San Francisco, CA":{"key": "locations", "label":"San Francisco, CA","selected":false},
      "New York, NY":{"key": "locations", "label":"New York, NY","selected":false},
      "Los Angeles, CA":{"key": "locations", "label":"Los Angeles, CA","selected":false},
      "Boston, MA":{"key": "locations", "label":"Boston, MA","selected":false},
      "Seattle, WA":{"key": "locations", "label":"Seattle, WA","selected":false},
      "Austin, TX":{"key": "locations", "label":"Austin, TX","selected":false},
      "Chicago, IL":{"key": "locations", "label":"Chicago, IL","selected":false},
      "Remote":{"key": "locations", "label":"Remote","selected":false},
    },

  "positionType":
    {"Software Engineering": {"key": "positionType", "label":"Software Engineering","selected":false},
      "Product Management": {"key": "positionType", "label":"Product Management","selected":false},
      "Design": {"key": "positionType", "label":"Design","selected":false},
      "Data Science & Analytics": {"label":"Data Science & Analytics", "key": "positionType", "selected":false},
      "Product Management": {"label":"Product Management", "key": "positionType", "selected":false},
      "Marketing & Communications": {"label":"Marketing & Communications", "key": "positionType", "selected":false},
      "Sales": {"label":"Sales", "key": "positionType", "selected":false},
      "Project Management": {"label":"Project Management", "key": "positionType", "selected":false},
      "People Operations": {"label":"People Operations", "key": "positionType", "selected":false},
      "Finance/Accounting": {"label":"Finance/Accounting", "key": "positionType", "selected":false},
      "Operations": {"label":"Operations", "key": "positionType", "selected":false},
      "Business & Strategy": {"label":"Business & Strategy", "key": "positionType", "selected":false},
    },

    "level":
    {
      "Internship": {"label":"Internship", "key": "level", "selected":false},
      "Entry-level": {"label":"Entry-level", "key": "level", "selected":false},
      "Mid-level": {"label":"Mid-level", "key": "level", "selected":false},
      "Senior-level": {"label":"Senior-level", "key": "level", "selected":false},
      "Manager-level": {"label":"Manager-level", "key": "level", "selected":false},
      "Director-level": {"label":"Director-level", "key": "level", "selected":false},
      "VP/Executive-level": {"label":"VP/Executive-level", "key": "level", "selected":false},
    },

    "jobSearchStatus":
    {
      "Passively looking" : {"key": "jobSearchStatus", "label": "Passively looking", "selected": false},
      "Actively looking and ready to interview" : {"key": "jobSearchStatus", "label": "Actively looking and ready to interview", "selected": false},
      "In final stages of interviews" : {"key": "jobSearchStatus", "label": "In final stages of interviews", "selected": false},
      "Not looking for jobs anymore" : {"key": "jobSearchStatus", "label": "Not looking for jobs anymore", "selected": false},
    },
    "companySizes":
    {
      "Any size" : {"label": "Any size", "key": "companySizes", "selected": false},
      "1-20 employees" : {"label": "1-20 employees", "key": "companySizes", "selected": false},
      "21-100 employees" : {"label": "21-100 employees", "key": "companySizes", "selected": false},
      "101-500 employees" : {"label": "101-500 employees", "key": "companySizes", "selected": false},
      "501-2000 employees" : {"label": "501-2000 employees", "key": "companySizes", "selected": false},
      "2000+ employees" : {"label": "2000+ employees", "key": "companySizes", "selected": false},
    },
    "specialties" :
    {
      "AR/VR": {"label": "AR/VR", "selected": false, "positionType":"Software Engineering", "key": "specialties"},
      "Algorithms": {"label": "Algorithms", "selected": false, "positionType":"Software Engineering", "key": "specialties"},
      "Backend": {"label": "Backend", "selected": false, "positionType":"Software Engineering", "key": "specialties"},
      "Blockchain": {"label": "Blockchain", "selected": false, "positionType":"Software Engineering", "key": "specialties"},
      "Computer Vision": {"label": "Computer Vision", "selected": false, "positionType":"Software Engineering", "key": "specialties"},
      "Data": {"label": "Data", "selected": false, "positionType":"Software Engineering", "key": "specialties"},
      "DevOps": {"label": "DevOps", "selected": false, "positionType":"Software Engineering", "key": "specialties"},
      "Embedded": {"label": "Embedded", "selected": false, "positionType":"Software Engineering", "key": "specialties"},
      "Management": {"label": "Management", "selected": false, "positionType":"Software Engineering", "key": "specialties"},
      "Frontend": {"label": "Frontend", "selected": false, "positionType":"Software Engineering", "key": "specialties"},
      "Full Stack": {"label": "Full Stack", "selected": false, "positionType":"Software Engineering", "key": "specialties"},
      "Gaming": {"label": "Gaming", "selected": false, "positionType":"Software Engineering", "key": "specialties"},
      "Hardware": {"label": "Hardware", "selected": false, "positionType":"Software Engineering", "key": "specialties"},
      "Machine Learning": {"label": "Machine Learning", "selected": false, "positionType":"Software Engineering", "key": "specialties"},
      "Mobile": {"label": "Mobile", "selected": false, "positionType":"Software Engineering", "key": "specialties"},
      "NLP": {"label": "NLP", "selected": false, "positionType":"Software Engineering", "key": "specialties"},
      "QA": {"label": "QA", "selected": false, "positionType":"Software Engineering", "key": "specialties"},
      "Search": {"label": "Search", "selected": false, "positionType":"Software Engineering", "key": "specialties"},
      "Security": {"label": "Security", "selected": false, "positionType":"Software Engineering", "key": "specialties"},
      "Software Architecture": {"label": "Software Architecture", "selected": false, "positionType":"Software Engineering", "key": "specialties"},
      "Brand/Graphic Design": {"label": "Brand/Graphic Design", "selected": false, "positionType":"Design", "key": "specialties"},
      "Product Design": {"label": "Product Design", "selected": false, "positionType":"Design", "key": "specialties"},
      "UX Design": {"label": "UX Design", "selected": false, "positionType":"Design", "key": "specialties"},
      "UX Research": {"label": "UX Research", "selected": false, "positionType":"Design", "key": "specialties"},
      "Visual/UI Design": {"label": "Visual/UI Design", "selected": false, "positionType":"Design", "key": "specialties"},
      "Creative Director": {"label": "Creative Director", "selected": false, "positionType":"Design", "key": "specialties"},
      "Illustration": {"label": "Illustration", "selected": false, "positionType":"Design", "key": "specialties"},
      "Business Analyst": {"label": "Business Analyst", "selected": false, "positionType":"Data Science & Analytics", "key": "specialties"},
      "Data Analyst": {"label": "Data Analyst", "selected": false, "positionType":"Data Science & Analytics", "key": "specialties"},
      "Data Science": {"label": "Data Science", "selected": false, "positionType":"Data Science & Analytics", "key": "specialties"},
      "Data Visualization": {"label": "Data Visualization", "selected": false, "positionType":"Data Science & Analytics", "key": "specialties"},
      "Growth Marketing": {"label": "Growth Marketing", "selected": false, "positionType":"Marketing & Communications", "key": "specialties"},
      "Marketing Manager": {"label": "Marketing Manager", "selected": false, "positionType":"Marketing & Communications", "key": "specialties"},
      "Content": {"label": "Content", "selected": false, "positionType":"Marketing & Communications", "key": "specialties"},
      "Product Marketing": {"label": "Product Marketing", "selected": false, "positionType":"Marketing & Communications", "key": "specialties"},
      "Corporate Marketing": {"label": "Corporate Marketing", "selected": false, "positionType":"Marketing & Communications", "key": "specialties"},
      "Communications": {"label": "Communications", "selected": false, "positionType":"Marketing & Communications", "key": "specialties"},
      "Marketing Operations": {"label": "Marketing Operations", "selected": false, "positionType":"Marketing & Communications", "key": "specialties"},
      "Brand Strategy": {"label": "Brand Strategy", "selected": false, "positionType":"Marketing & Communications", "key": "specialties"},
      "Account Executive": {"label": "Account Executive", "selected": false, "positionType":"Sales", "key": "specialties"},
      "Account Manager": {"label": "Account Manager", "selected": false, "positionType":"Sales", "key": "specialties"},
      "Customer Success": {"label": "Customer Success", "selected": false, "positionType":"Sales", "key": "specialties"},
      "Partnerships & Events": {"label": "Partnerships & Events", "selected": false, "positionType":"Sales", "key": "specialties"},
      "Business Development": {"label": "Business Development", "selected": false, "positionType":"Sales", "key": "specialties"},
      "BD Manager": {"label": "BD Manager", "selected": false, "positionType":"Sales", "key": "specialties"},
      "SDR Manager": {"label": "SDR Manager", "selected": false, "positionType":"Sales", "key": "specialties"},
      "SDR": {"label": "SDR", "selected": false, "positionType":"Sales", "key": "specialties"},
      "Sales Engineer": {"label": "Sales Engineer", "selected": false, "positionType":"Sales", "key": "specialties"},
      "Sales Operations": {"label": "Sales Operations", "selected": false, "positionType":"Sales", "key": "specialties"},
      "VP/Head of Sales": {"label": "VP/Head of Sales", "selected": false, "positionType":"Sales", "key": "specialties"},
      "Business Operations": {"label": "Business Operations", "selected": false, "positionType":"Operations", "key": "specialties"},
      "Product Operations": {"label": "Product Operations", "selected": false, "positionType":"Operations", "key": "specialties"},
      "H.R.": {"label": "H.R.", "selected": false, "positionType":"People Operations", "key": "specialties"},
      "People Analytics": {"label": "People Analytics", "selected": false, "positionType":"People Operations", "key": "specialties"},
      "Office Manager": {"label": "Office Manager", "selected": false, "positionType":"Operations", "key": "specialties"},
      "Technical Recruiting": {"label": "Technical Recruiting", "selected": false, "positionType":"People Operations", "key": "specialties"},
      "Recruiting": {"label": "Recruiting", "selected": false, "positionType":"People Operations", "key": "specialties"},
      "Customer Service": {"label": "Customer Service", "selected": false, "positionType":"Operations", "key": "specialties"},
      "Operations Manager": {"label": "Operations Manager", "selected": false, "positionType":"Operations", "key": "specialties"},
    },
    "workAuth":
    {
      "No visa sponsorship required in the next year": {"selected": false, "label": "No visa sponsorship required in the next year", "key": "workAuth"},
      "No visa sponsorship required ever": {"selected": false, "label": "No visa sponsorship required ever", "key": "workAuth"}
    },

    "contacted":
    {
      "Contacted candidates": {"selected": false, "label": "Contacted candidates", "key": "contacted"},
      "Not contacted candidates": {"selected": false, "label": "Not contacted candidates", "key": "contacted"}
    }
}

export default FilterData;
