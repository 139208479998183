import React from 'react'

const Checkmark = props => (
  <svg width={14} height={14} {...props}>
    <g fill="none" fillRule="evenodd">
      <circle fill="#226CFF" cx={7} cy={7} r={7} />
      <g stroke="#FFF" strokeLinecap="square" strokeWidth={1.5}>
        <path d="M3.915 6.978L6.14 9.14M6.14 9.14L10 5" />
      </g>
    </g>
  </svg>
)

export default Checkmark
