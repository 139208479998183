import React, { Component } from 'react';
import './filter-block-talent.css';
import FilterPlus from '../../../svg/filter-plus.js';
import FilterMinus from '../../../svg/filter-minus.js';
import FilterPillX from '../../../svg/filter-pill-x.js';
import {DEFAULT_MAX_SALARY} from "./filter-constants.js"
class FilterBlockSalary extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
      minValue:"",
      maxValue:"",
    };
    this.toggleExpanded = this.toggleExpanded.bind(this);
  }

  toggleExpanded() {
    this.setState(state => ({
      isExpanded: !state.isExpanded
    }))
  }

  onMinChange(value) {
    this.setState({minValue: value});
    //this.props.filterTalentChange(this.props.filterName, this.state);
    this.props.filterSalaryChange("minValue", value);
  }

  onMaxChange(value) {
    this.setState({maxValue: value});
    this.props.filterSalaryChange("maxValue", value);
  }

  render() {
    const borderStyle = {
      // borderBottom: this.state.isExpanded? "1px solid #226CFF":"1px solid #EBEDF2",
      // borderTop:this.state.isExpanded?"1px solid #226CFF":"none",
      // borderRight:this.state.isExpanded?"1px solid #226CFF":"none",
      // borderLeft:this.state.isExpanded?"1px solid #226CFF":"none",
    }


    return (
      <div className='filter-block-talent' style={borderStyle}>
        <div  onClick={this.toggleExpanded} className='filter-block-talent-top-container'>
          <div className='filter-block-talent-title'>
            {this.props.filterName}
          </div>
          <div className='filter-block-talent-plus-container'>
            {this.state.isExpanded ?

            <FilterMinus
              className='filter-block-talent-minus'
            />
            :
            <FilterPlus
              className='filter-block-talent-plus'
            />
            }
          </div>
        </div>

        {!this.state.isExpanded ? null:
        <div className='filter-block-talent-expanded'>
          <div className='filter-block-talent-input-label'>
            Minimum salary shown
          </div>

          <input
            type='number'
            placeholder='e.g. 50000'
            value={this.state.minValue}
            className='filter-block-talent-input'
            onChange={(e) => this.onMinChange(e.target.value)}
          />

          <div className='filter-block-talent-input-label'>
            Maximum salary shown
          </div>

          <input
            type='number'
            placeholder='e.g. 300000'
            value={this.state.maxValue}
            className='filter-block-talent-input'
            onChange={(e) => this.onMaxChange(e.target.value)}
          />

        </div>
        }
      </div>
  )}
}

export default FilterBlockSalary;
