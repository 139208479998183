import React, { Component } from 'react';
import './refer-sidebar.css';
import TextareaAutosize from 'react-autosize-textarea';
import SideBarX from '../../svg/sidebar-x.js';
import Users from "../../store/users.js";
import Backend from "../../backend.js";
import AnimatingSpinnerButton from '../../svg/animating-spinner-button.js';
import Confetti from 'react-dom-confetti';

const confettiConfig = {
  angle: 92,
  spread: 45,
  startVelocity: 45,
  elementCount: 50,
  decay: 0.9
};

class ReferSidebar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      emailBody: "",
      friendEmail: "",
      friendName: "",
      isWaiting:false,
      insertedName: false,
      showConfetti: false,
    };

    this.sendInviteMessage = this.sendInviteMessage.bind(this);
    this.handleBodyChange = this.handleBodyChange.bind(this);
    this.saveAndCloseSidebar = this.saveAndCloseSidebar.bind(this);
    this.handleFriendNameChange = this.handleFriendNameChange.bind(this);
    this.handleFriendEmailChange = this.handleFriendEmailChange.bind(this);
  }
  componentDidMount() {
    let defaultEmailBody = 'Hey,';
    defaultEmailBody += "\n\nIf you're looking for a job, I thought you'd find Symbol useful: https://symbol.co"
    defaultEmailBody += "\n\nIt's a common first round interview used by 200+ top companies like Tesla and Spotify. It's a written interview that you can take on your own time, and once you take it, companies reach out to invite you to onsite interviews."
    defaultEmailBody += "\n\nAnd I hear all the cool people are using it 😉."
    defaultEmailBody += "\n\nBest,\n"+ Users.auth.firstName ;
    defaultEmailBody += "\n\nP.S. We both get $500 if you sign up and get a job through Symbol!"

    if (Users.auth.savedEmailBody) {
      defaultEmailBody = Users.auth.savedEmailBody;
    }

    if (Users.auth.savedSalary) {
      this.setState({"salary": Users.auth.savedSalary});
    }
    if (Users.auth.savedPosition) {
      this.setState({"position": Users.auth.savedPosition});
    }

    this.setState({"emailBody": defaultEmailBody});
  }

  async sendInviteMessage() {
    if (!this.validateForm()) {
      alert(`Please fill in all required fields.`)
      return;
    }

    if (!this.validateEmail(this.state.friendEmail)) {
      alert("Please enter a valid email.");
      return;
    }

    this.setState({isWaiting:true});

    let emailInfo = {}
    emailInfo["emailBody"] = this.state.emailBody;
    emailInfo["friendName"] = this.state.friendName;
    console.log("the email ", this.state.friendEmail);
    emailInfo["friendEmail"] = this.state.friendEmail;
    emailInfo["referrerName"] = Users.auth.firstName + " " + Users.auth.lastName;
    emailInfo["referrerEmail"] = Users.auth.email;

    console.log("sending ittt ");
    let result = await Backend.sendReferralEmail(emailInfo);
    console.log("broke? " , result);

    if (this.state.insertedName) {
      let currentEmailBody = this.state.emailBody;
      let friendFirstName = this.state.friendName.split(" ")[0];
      currentEmailBody = currentEmailBody.replace(friendFirstName, "");
      let indexOfComma = currentEmailBody.indexOf(",");
      currentEmailBody = currentEmailBody.slice(0, indexOfComma - 1) + currentEmailBody.slice(indexOfComma);
      this.setState({emailBody: currentEmailBody});
    }

    this.setState({isWaiting:false, insertedName: false, showConfetti: true, friendEmail: "", friendName: ""});
  }

  handleBodyChange(evt) {
    this.setState({emailBody: evt.target.value});
  }

  saveAndCloseSidebar() {
    //let savedBody = this.state.emailBody.replace(new RegExp(this.state.firstName, 'g'), "CANDIDATE_NAME");
    //Users.auth.savedEmailBody = savedBody;
    this.props.toggleSidebar();
  }


  validateForm() {
    if (!this.state.friendEmail || this.state.friendEmail=="" || this.state.friendEmail=="_EMPTY_") {
      return false
    }

    if (this.state.emailBody=="" || this.state.emailBody=="_EMPTY_") {
      return false
    }

    if (!this.state.friendName || this.state.friendName=="" || this.state.friendName=="_EMPTY_") {
      return false
    }

    return true
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }


  componentWillReceiveProps(nextProps) {
    // if (nextProps.savedEmailInfo !== this.props.savedEmailInfo) {
    //   if (nextProps.savedEmailInfo.savedEmailBody) {
    //     this.setState({emailBody: nextProps.savedEmailInfo.savedEmailBody });
    //   }
    // }
  }

  handleFriendEmailChange(evt) {
    this.setState({"friendEmail": evt.target.value});

    let emailBody = this.state.emailBody;
    let indexOfComma = emailBody.indexOf(",");
    if (indexOfComma == -1 || indexOfComma > 20) {
      // the comma is not in a good-looking insertable position for the name.
      return;
    }

    if (this.state.friendName != "" && !this.state.insertedName) {
      this.setState({insertedName: true});

      console.log("the index of the comma: ", );

      let firstName = this.state.friendName.split(" ")[0];
      var newEmailBody = emailBody.substr(0, indexOfComma) + " " + firstName + emailBody.substr(indexOfComma);
      this.setState({emailBody: newEmailBody});
    }
  }

  handleFriendNameChange(evt) {
    this.setState({"friendName": evt.target.value});
  }

  render() {
    return (
      <div className='email-sidebar' style={{marginRight:this.props.isSidebarOpen?"0px":"-534px",visibility:this.props.isSidebarOpen?'visible':'hidden'}}>
        <div className='email-sidebar-container'>
          <div className='email-sidebar-x-container'>
            <SideBarX
              className='email-sidebar-x'
              onClick={this.saveAndCloseSidebar}
            />
          </div>
          <div className='email-sidebar-title'>
            {"Refer friends, create win-wins 🙌"}
          </div>
          <div className='form-label'>{"Your friend's name"}</div>
          <input className='half-input' value={this.state.friendName} onChange={this.handleFriendNameChange} placeholder='e.g. Jackie Sparrow' type="text"></input>
          <div className='form-label'>{"Your friend's email"}</div>
          <input className='half-input' value={this.state.friendEmail} onChange={this.handleFriendEmailChange} placeholder='e.g. jackie@gmail.com' type="text"></input>

          <div className='form-label'>Personal message to your friend:</div>
          <div>
            <TextareaAutosize
              maxRows={10}
              rows={10}
              placeholder='Message to your friend'
              value={this.state.emailBody}
              onChange={this.handleBodyChange}
              className={'text-area'}/>
          </div>
          <div className='email-sidebar-button-container'>
            <Confetti active={!this.state.isWaiting} config={confettiConfig} />
            <button onClick={() => this.sendInviteMessage()} className={!this.state.isWaiting?'small-button':'small-button-waiting'}>
              {!this.state.isWaiting?
                <div>Send referral</div>
                :
                <AnimatingSpinnerButton
                  style={{marginTop:'4px'}}
                />
              }
            </button>
          </div>
        </div>
      </div>
  )}
}

export default ReferSidebar;
