import React, { Component } from 'react';
import {BrowserRouter as Router, Route, Link, withRouter} from 'react-router-dom';
import './onboarding-steps.css';
import './custom-form.css';
import ReactDOM from 'react-dom';
import {Helmet} from "react-helmet";
import HomepageLogo from '../../svg/homepage-logo.js';
import MultiSelect from './multi-select-symbol.js';
import TextareaAutosize from 'react-autosize-textarea';
import Backend from "../../backend.js";
import Users from "../../store/users.js";
import LightHeader from '../basics/light-header.js';
import GenericModal from '../modal/generic-modal.js';
import AnimatingSpinnerBig from '../../svg/animating-spinner-big.js';
//import ReactGA from 'react-ga';

const confettiConfig = {
  angle: 92,
  spread: 45,
  startVelocity: 45,
  elementCount: 50,
  decay: 0.9
};



class OnboardingStep3 extends Component {

  constructor(props) {
    super(props);

    let ethnicities = {
                      "asian": {"label": "Asian", "selected": false},
                      "black": {"label": "Black / African American", "selected": false},
                      "white": {"label": "White", "selected": false},
                      "latinx": {"label": "Latinx / Hispanic", "selected": false},
                      "native-american": {"label": "Native American", "selected": false},
                      "pacific-islander": {"label": "Pacific Islander", "selected": false},
                      "middle-eastern": {"label": "Middle Eastern", "selected": false},
                      "other": {"label": "Other", "selected": false},
                      "prefer not to say": {"label": "Prefer not to say", "selected": false},
                      }

    this.state = {
      ethnicities: ethnicities,
      jobSearchStatus: "",
      prevRoleLevel: "",
      workAuthUSA: false,
      visaSponsorshipRequired: false,
      visaSponsorshipFutureRequired: false,
      gender: "",
      veteranStatus: "",
      user: Users.auth,
      isSpinnerVisible:false,
      showConfetti: false,
    };

    this.toggleEthnicityOption = this.toggleEthnicityOption.bind(this);
    this.handleAuthChange = this.handleAuthChange.bind(this);
    this.handleSponsorshipChange = this.handleSponsorshipChange.bind(this);
    this.handleSponsorshipFutureChange = this.handleSponsorshipFutureChange.bind(this);
    this.nextStepClick = this.nextStepClick.bind(this);
    this.goBackClick = this.goBackClick.bind(this);
    this.handleJobSearchStatusChange = this.handleJobSearchStatusChange.bind(this);
    this.handleCurrentRoleLevelChange = this.handleCurrentRoleLevelChange.bind(this);
    this.handleVeteranStatusChange = this.handleVeteranStatusChange.bind(this);
    this.handleGenderChange = this.handleGenderChange.bind(this);
    // this.startSpinnerTimer = this.startSpinnerTimer.bind(this);
  }

  // async startSpinnerTimer() {
  //   this.setState({isSpinnerVisible:true});
  //   setTimeout(() => {
  //       console.log("TIME SHOULD BE GOOD NOW");
  //       this.setState({isSpinnerVisible:false});
  //   }, 2000);
  // }

  componentDidMount() {
    //ReactGA.pageview('/onboarding3');
  }

  getSelectedOptions(options) {
      let optionsArray = Object.keys(options).filter(option => options[option].selected);
      options = optionsArray.map(option => options[option].label);
      return options;
  }

  loadSelectedForOptions(allOptions, optionsSaved) {
    for (var option in allOptions) {
      var optionObj = allOptions[option]
        if (optionsSaved.indexOf(optionObj.label) > -1) {
          optionObj.selected = true;
        }
    }
    return allOptions;
  }

  validateForm() {

    if (!this.state.user.jobSearchStatus || this.state.user.jobSearchStatus=='Select One' || this.state.user.jobSearchStatus=='' || this.state.user.jobSearchStatus=='_EMPTY_') {
      console.log('1')
      return false
    }

    if (!this.state.user.workAuthorization || this.state.user.workAuthorization.visaSponsorshipRequired == undefined || this.state.user.workAuthorization.visaSponsorshipFutureRequired == undefined) {
      return false
    }

    console.log('6')
    return true
  }

  async nextStepClick() {
    if (!this.validateForm()) {
      alert('Make sure you complete all required fields');
      return;
    }

    Users.auth["jobSearchStatus"] = this.state.user.jobSearchStatus;

    let workAuthorization = this.state.user.workAuthorization;
    if (workAuthorization == undefined) {
      workAuthorization = {};
    } else {
      workAuthorization["workAuthUSA"] = this.state.user.workAuthorization.workAuthUSA;
      workAuthorization["visaSponsorshipRequired"] = this.state.user.workAuthorization.visaSponsorshipRequired;
      workAuthorization["visaSponsorshipFutureRequired"] = this.state.user.workAuthorization.visaSponsorshipFutureRequired;
    }
    Users.auth["workAuthorization"] = workAuthorization;


    let demographicInfo = this.state.user.demographicInfo;
    if (demographicInfo == undefined) {
      demographicInfo = {};
    } else {
      demographicInfo["gender"] = this.state.user.demographicInfo.gender;
      demographicInfo["ethnicity"] = this.state.user.demographicInfo.ethnicity;
      demographicInfo["veteran"] = this.state.user.demographicInfo.veteran;
    }
    Users.auth["demographicInfo"] = demographicInfo;

    if (this.state.user.whereInFlow == "/onboarding/3") {
      Users.auth["whereInFlow"] = "/interview";
    }

    Users.auth["shownToCompanies"] = true;

    Backend.saveApplicantInfo(Users.auth);

    if (this.state.user.whereInFlow=='/profile'){
      this.props.history.push("/profile");

    } else {
      this.props.history.push("/interview");
    }
  }

  goBackClick() {
    Users.auth["jobSearchStatus"] = this.state.user.jobSearchStatus;

    let workAuthorization = this.state.user.workAuthorization;
    if (workAuthorization == undefined) {
      workAuthorization = {};
    } else {
      workAuthorization["workAuthUSA"] = this.state.user.workAuthorization.workAuthUSA;
      workAuthorization["visaSponsorshipRequired"] = this.state.user.workAuthorization.visaSponsorshipRequired;
      workAuthorization["visaSponsorshipFutureRequired"] = this.state.user.workAuthorization.visaSponsorshipFutureRequired;
    }
    Users.auth["workAuthorization"] = workAuthorization;


    let demographicInfo = this.state.user.demographicInfo;
    if (demographicInfo == undefined) {
      demographicInfo = {};
    } else {
      demographicInfo["gender"] = this.state.user.demographicInfo.gender;
      demographicInfo["ethnicity"] = this.state.user.demographicInfo.ethnicity;
      demographicInfo["veteran"] = this.state.user.demographicInfo.veteran;
    }
    Users.auth["demographicInfo"] = demographicInfo;


    Backend.saveApplicantInfo(Users.auth);
    this.props.history.push("/onboarding/2");
  }

  toggleEthnicityOption(value) {
    let ethnicities = Object.assign({}, this.state.ethnicities);
    ethnicities[value]["selected"] = !ethnicities[value]["selected"];

    let ethnicitiesForDB = this.getSelectedOptions(ethnicities);
    if (Users.auth.demographicInfo) {
      Users.auth.demographicInfo.ethnicity = ethnicitiesForDB;
    } else {
      Users.auth.demographicInfo = {"ethnicity": ethnicitiesForDB};
    }
    this.setState({ethnicities: ethnicities});
  }

  handleAuthChange(evt) {
    console.log("authchange: ", evt.target.value);
    var user = JSON.parse(JSON.stringify(this.state.user));
    if (user.workAuthorization) {
      user.workAuthorization.workAuthUSA = evt.target.value;
    } else {
      user.workAuthorization = {"workAuthUSA": evt.target.value};
    }
    this.setState({user: user});
  }

  handleVeteranStatusChange(evt) {
    let status = evt.target.value;
    var user = JSON.parse(JSON.stringify(this.state.user));
    if (user.demographicInfo) {
      user.demographicInfo.veteran = evt.target.value;
    } else {
      user.demographicInfo = {"veteran": evt.target.value};
    }
    this.setState({user: user});
  }


  handleGenderChange(evt) {
    let status = evt.target.value;
    var user = JSON.parse(JSON.stringify(this.state.user));

    if (user.demographicInfo) {
      user.demographicInfo.gender = evt.target.value;
    } else {
      user.demographicInfo = {"gender": evt.target.value};
    }
    this.setState({user: user});
  }


  handleJobSearchStatusChange(evt) {
    let status = evt.target.value;
    var user = JSON.parse(JSON.stringify(this.state.user));
    user.jobSearchStatus = status;
    this.setState({user: user});
  }

  handleCurrentRoleLevelChange(evt) {
    var user = JSON.parse(JSON.stringify(this.state.user));
    user.currentRoleLevel = evt.target.value;
    this.setState({user: user});
  }

  handleSponsorshipChange(evt) {
    var user = JSON.parse(JSON.stringify(this.state.user));
    if (user.workAuthorization) {
      user.workAuthorization.visaSponsorshipRequired = evt.target.value;
    } else {
      user.workAuthorization = {"visaSponsorshipRequired": evt.target.value};
    }
    this.setState({user: user});
  }

  handleSponsorshipFutureChange(evt) {
    var user = JSON.parse(JSON.stringify(this.state.user));
    if (user.workAuthorization) {
      user.workAuthorization.visaSponsorshipFutureRequired = evt.target.value;
    } else {
      user.workAuthorization = {"visaSponsorshipFutureRequired": evt.target.value};
    }
    this.setState({user: user});
  }

  render() {

    console.log('USER.AUTH',Users.auth)

    let loadedEthnicities = this.state.ethnicities;
    if (Users.auth.demographicInfo && Users.auth.demographicInfo.ethnicity) {
      loadedEthnicities = this.loadSelectedForOptions(this.state.ethnicities, Users.auth.demographicInfo.ethnicity);
    }

    let workAuthUSA = this.state.user.workAuthorization ? this.state.user.workAuthorization.workAuthUSA : false;
    if (workAuthUSA == 'false') {
      workAuthUSA = false;
    } else if (workAuthUSA == 'true') {
      workAuthUSA = true;
    }

    let visaSponsorshipRequired = this.state.user.workAuthorization ? this.state.user.workAuthorization.visaSponsorshipRequired : false;
    if (visaSponsorshipRequired == 'false') {
      visaSponsorshipRequired = false;
    } else if (visaSponsorshipRequired == 'true') {
      visaSponsorshipRequired = true;
    }

    let visaSponsorshipFutureRequired = this.state.user.workAuthorization ? this.state.user.workAuthorization.visaSponsorshipFutureRequired : false;
    if (visaSponsorshipFutureRequired == 'false') {
      visaSponsorshipFutureRequired = false;
    } else if (visaSponsorshipFutureRequired == 'true') {
      visaSponsorshipFutureRequired = true;
    }


    return (
      <div className='onboarding-step'>
        <Helmet>
          <title>Symbol - Get a job</title>
        </Helmet>
        <LightHeader userHasAuthenticated={this.props.userHasAuthenticated} />

        <div className='onboarding-step-number-container'>
          <div className='onboarding-step-number'>
            Step 3 of 3
          </div>
        </div>

        <div className='onboarding-form-container'>
          <div className='onboarding-form-body'>
            <div className='onboarding-form-body-container'>
              <div className='onboarding-form-body-container-inner'>
                <div className='onboarding-form-header'>
                  <div className='onboarding-form-header-title'>
                    Build Your Profile
                  </div>
                  <div className='onboarding-form-header-subtitle'>
                    STEP 3 - FINAL DETAILS
                  </div>
                  <div className='onboarding-form-header-text'>
                    A few more things before you can start your first-round interview.
                  </div>
                </div>

                <form>
                  <div className='form-title'>
                    Status
                  </div>
                  <div className='form-block'>
                    <div className='form-label'>Where are you in the job search?</div>
                    <div>
                      <select onChange={this.handleJobSearchStatusChange} value={this.state.user.jobSearchStatus}>
                        <option className="placeholder">Select One</option>
                        <option value="passive">Passively looking</option>
                        <option value="active">Actively looking and ready to interview</option>
                        <option value="active">In final stages of interviews</option>
                        <option value="closed">Not looking for jobs anymore</option>
                      </select>
                    </div>
                  </div>

                  <div className='form-block'>
                    <div className='form-label'>USA work sponsorship?</div>

                    <div className='form-label-secondary'>Do you currently require or will require sponsorship within a year for employment visa status (e.g. H-1B Visa)? This is apart from OPT. If your OPT is valid for over a year from now, check not required.</div>
                    <div className='radio-section' onChange={this.handleSponsorshipChange}>
                      <div className='radio-container'>
                        <label class="container">Not required
                          <input type="radio" onChange={this.handleSponsorshipChange} value={false} checked={this.state.user.workAuthorization && this.state.user.workAuthorization.visaSponsorshipRequired ?  !visaSponsorshipRequired : false}/>
                          <span class="checkmark"></span>
                        </label>
                      </div>

                      <div className='radio-container'>
                        <label class="container">Sponsorship required
                          <input type="radio" onChange={this.handleSponsorshipChange} value={true} checked={this.state.user.workAuthorization && this.state.user.workAuthorization.visaSponsorshipRequired ? visaSponsorshipRequired : false}/>
                          <span class="checkmark"></span>
                        </label>
                      </div>

                    </div>

                    <div className='form-label-secondary'>Will you require sponsorship for employment visa status in the future (e.g. H-1B Visa after your OPT)?</div>
                    <div className='radio-section' onChange={this.handleSponsorshipFutureChange}>

                      <div className='radio-container'>
                        <label class="container">Not required
                          <input type="radio" onChange={this.handleSponsorshipFutureChange} value={false} checked={this.state.user.workAuthorization && this.state.user.workAuthorization.visaSponsorshipFutureRequired ?  !visaSponsorshipFutureRequired : false}/>
                          <span class="checkmark"></span>
                        </label>
                      </div>

                      <div className='radio-container'>
                        <label class="container">Will require sponsorship
                          <input type="radio" onChange={this.handleSponsorshipFutureChange} value={true} checked={this.state.user.workAuthorization && this.state.user.workAuthorization.visaSponsorshipFutureRequired ?  visaSponsorshipFutureRequired : false}/>
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>



                  </div>

                  <div className='form-divider'></div>

                  <div className='form-title'>
                    Demographics (completion is voluntary)
                  </div>
                  <div className='form-block'>
                    <div className='form-label'>Gender (optional)</div>
                    <div>
                      <select onChange={this.handleGenderChange} value={this.state.user.demographicInfo ? this.state.user.demographicInfo.gender : ""}>
                        <option className="placeholder">Select</option>
                        <option value="female">Female</option>
                        <option value="male">Male</option>
                        <option value="nonBinary">Non Binary</option>
                        <option value="notDisclosed">Prefer not to say</option>
                      </select>
                    </div>
                  </div>

                  <div className='form-block'>
                    <div className='form-label'>Ethnicity (optional)</div>
                    <div className='form-label-secondary'>Select all that you identify with</div>
                    <div>
                      <MultiSelect options={loadedEthnicities} toggleOption={this.toggleEthnicityOption}/>
                    </div>
                  </div>
                  </form>

                  {this.state.user.whereInFlow=='/profile'? null:
                  <div className='onboarding-form-message-area'>
                    <div className='onboarding-message'>Once you click "Finish and next", your profile information will be saved and you can start your first round interview. You will be able to make edits to your profile information later.</div>
                  </div>
                  }

                <div className='onboarding-form-buttons-area-2'>

                  <div className='step-buttons-container'>
                    <div className='go-back-button' onClick={this.goBackClick}>Go back</div>
                    <button className='small-button' onClick={this.nextStepClick}>{this.state.user.whereInFlow=='/profile'?"Save and Finish":"Finish and Next"}</button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className='searching-overlay' style={{opacity: this.state.isSpinnerVisible? '0.9': '0', visibility:this.state.isSpinnerVisible? 'visible': 'hidden'}}>
          <div className='searching-container'>
            <div className='searching-container-vertical-center'>
              <div className='searching-message-container'>
                <div className='searching-message'>
                  {"Generating your first round interview"}
                </div>
              </div>
              <div className='searching-message-container'>
                <AnimatingSpinnerBig />
              </div>
            </div>
          </div>
        </div>
    </div>
  )}
}

export default withRouter(OnboardingStep3);
