import React, { Component } from 'react';
import './applicant-card.css';
import SkillBlock from './skill-block.js';
import StatsIcon1 from '../../svg/stats-icon-1.js';
import StatsIcon2 from '../../svg/stats-icon-2.js';
import StatsIcon3 from '../../svg/stats-icon-3.js';
import StatsIcon4 from '../../svg/stats-icon-4.js';
import EmailSidebar from '../employer/email-sidebar.js';
import {BrowserRouter as Router, Route, Link, withRouter} from 'react-router-dom';

class ApplicantCard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isSidebarOpen: false,
      contacted:true,
    };

    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.setContacted = this.setContacted.bind(this);
  }

  toggleSidebar(value) {
    this.forceUpdate();
    this.setState({isSidebarOpen: !this.state.isSidebarOpen});
  }

  setContacted() {
    this.setState({contacted: true});
  }


  workExperiencesDisplay(user) {
    let displayText = ""
    let workExperiences = user.workExperiences

    if (workExperiences && workExperiences.length !==0 && workExperiences[0].companyName!=="" && workExperiences[0].companyName!=="_EMPTY_") {
      for (var i = 0; i < workExperiences.length; i++) {
        let workExperience = workExperiences[i];
        if (workExperiences.companyName!=='_EMPTY_'){
          if (i == workExperiences.length - 1) {
            // don't add the comma in the last one.
            if (workExperience.title && workExperience.title!=="" && workExperience.title!=="_EMPTY_") {
              displayText += workExperience.title + ' @ ' + workExperience.companyName
            } else {
              displayText += workExperience.companyName
            }
          } else {

            if (workExperience.title && workExperience.title!=="" && workExperience.title!=="_EMPTY_") {
              displayText += workExperience.title + ' @ ' + workExperience.companyName + " • "
            } else {
              displayText += workExperience.companyName + " • • "
            }
          }
        }
      }
    }
      return displayText
  }

  collegesDisplay(user) {
    let displayText = ""
    let colleges = user.colleges

    if (colleges && colleges.length !==0 && colleges[0].collegeName!=="") {
      for (var i = 0; i < colleges.length; i++) {
        let college = colleges[i];
        if (college.collegeName!=='_EMPTY_'){
          if (i == colleges.length - 1) {
            // don't add the comma in the last one.
            if (college.degree && college.degree!=="" && college.degree!=="_EMPTY_") {
              displayText += college.collegeName + ", " + college.degree
            } else {
              displayText += college.collegeName
            }
          } else {

            if (college.degree && college.degree!=="" && college.degree!=="_EMPTY_") {
              displayText += college.collegeName + ", " + college.degree + " • "
            } else {
              displayText += college.collegeName + college.degree + " • "
            }
          }
        }
      }
    }
      return displayText
  }

  skillsDisplay(user) {
    let displayText = ""
    let positionType
    let specialties
    let roleLevel
    if (user.lookingForJobs) {
      positionType = user.lookingForJobs.positionType
      specialties = user.lookingForJobs.specialties
      roleLevel = user.lookingForJobs.roleLevel

      if (positionType && positionType!=='Select One' && positionType!=='_EMPTY_') {
        displayText += positionType
      }

      if (roleLevel && positionType!=='Select One' && positionType!=='_EMPTY_') {
        if (positionType) {
          displayText += ' • ' + roleLevel + ' experience'
        } else {
          displayText += roleLevel + ' experience'
        }
      }

      if (specialties && specialties.length !==0 && specialties[0]!=="" && specialties[0]!=="_EMPTY_") {
        displayText += ' • ' + 'Specialties include '
        for (var i = 0; i < specialties.length; i++) {
          let specialty = specialties[i];
          if (specialties[0]!=="" && specialties[0]!=="_EMPTY_") {
            if (i == specialties.length - 1) {
              // don't add the comma in the last one.
              displayText += specialty
            } else {
              displayText += specialty + ", "
            }

          }

        }
      }
    }

      return displayText
  }

  preferencesDisplay(user) {
    let displayText = ""
    let salary
    let locations
    if (user.lookingForJobs) {
      salary = user.lookingForJobs.salary
      locations = user.lookingForJobs.locations

      if (salary && salary!=='') {
        displayText += 'Prefers salary of $' + salary.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }

      if (locations && locations.length !==0) {
        if (salary && salary!=='') {
          displayText += ' • ' + 'Open to '
        } else {
          displayText += 'Open to '

        }

        for (var i = 0; i < locations.length; i++) {
          let location = locations[i];

          if (i == locations.length - 1) {
            // don't add the comma in the last one.
              if (location=='Anywhere' || location=='Remote') {
                displayText += location.toLowerCase()
              } else {
                displayText += location.split(',')[0]
              }

          } else {
            if (location=='Anywhere' || location=='Remote') {
              displayText += location.toLowerCase() + ', '
            } else {
              displayText += location.split(',')[0] + ', '
            }

          }
        }
      }
    }

      return displayText
  }


  skillBlocks() {
    let skillBlocks = []
    if (this.props.user && this.props.user.interviewResponses) {
      skillBlocks = Object.keys(this.props.user.interviewResponses).map(skillName => {
        let interviewResponse = this.props.user.interviewResponses[skillName];
        return (
          <SkillBlock interviewResponse={interviewResponse}/>
        );
      });
    }
    return skillBlocks
  }

  render() {
    let skillBlocks = this.skillBlocks();

    return (
      <div className='applicant-card'>
        <div className='applicant-card-container'>
          <div className='applicant-card-top-container'>
            <div className='applicant-card-top-left'>
            {/* PROFILE PICTURE */}
              <div className='profile-picture-container'>
                <div className='profile-picture-border'>
                  <div className='profile-picture'>

                    {this.props.user.profilePhoto ?
                      <img src={this.props.user.profilePhoto}/>
                      :
                      <div className='profile-picture-letter'>
                        {this.props.user.firstName ? this.props.user.firstName[0].toUpperCase() : ""}
                      </div>
                    }
                  </div>
                </div>
              </div>

              {/* TOP INFO*/}
              <div className='applicant-card-top-information-container'>
                <div className='applicant-card-top-name'>
                  {this.props.user.firstName} {this.props.user.lastName}
                  {this.props.contacted?
                    <div className='filter-block-pill-flex'>
                      Contacted
                    </div>
                  :null}
                </div>
                <div className='applicant-card-top-bio'>
                  {this.props.user.shortBlurb}
                </div>
              </div>
            </div>

            {/* ACTION BUTTONS */}
            {this.props.viewedByCompany ?
            <div className='applicant-card-action-button-container'>
              <button onClick={() => this.props.toggleApplicantProfile(this.props.user)} className='applicant-card-button'>
                View interview
              </button>
              <button onClick={() => this.props.toggleSidebar(this.props.user)} className='applicant-card-button-second'>
                {this.props.contacted?"Send follow-up":"Invite to onsite"}
              </button>
            </div>

            :

            <div className='applicant-card-action-button-container'>
              <button onClick={() => this.props.history.push("/onboarding/1")} className='applicant-card-button'>
                Edit profile
              </button>
              <button onClick={() => this.props.history.push("/interview/1")} className='applicant-card-button-second'>
                Edit interview
              </button>
            </div>

          }

          </div>
          {this.props.viewedByCompany &&
            <div className='applicant-card-interview-container'>
              <div className='applicant-card-interview-title-container'>
                <div className='applicant-card-interview-title'>
                  Interview performance
                </div>
              </div>
              <div className='applicant-card-interview-performance-container'>
                {skillBlocks}
              </div>
            </div>
           }


          <div className='applicant-card-stats-container'>
          {this.workExperiencesDisplay(this.props.user)==""?null:
            <div className='applicant-card-stats-row'>
              <div className='applicant-card-stats-row-icon'>
                <StatsIcon1/>
              </div>
              <div className='applicant-card-stats-row-text'>
                {this.workExperiencesDisplay(this.props.user)}
              </div>
            </div>
            }
            {this.collegesDisplay(this.props.user)==""? null:
            <div className='applicant-card-stats-row'>
              <div className='applicant-card-stats-row-icon'>
                <StatsIcon2/>
              </div>
              <div className='applicant-card-stats-row-text'>
                {this.collegesDisplay(this.props.user)}
              </div>
            </div>
            }

            {this.skillsDisplay(this.props.user)==""? null:

            <div className='applicant-card-stats-row'>
              <div className='applicant-card-stats-row-icon'>
                <StatsIcon3/>
              </div>
              <div className='applicant-card-stats-row-text'>
                {this.skillsDisplay(this.props.user)}
              </div>
            </div>

            }

            {this.preferencesDisplay(this.props.user)==""? null:

            <div className='applicant-card-stats-row'>
              <div className='applicant-card-stats-row-icon'>
                <StatsIcon4/>
              </div>
              <div className='applicant-card-stats-row-text'>
                {this.preferencesDisplay(this.props.user)}
              </div>
            </div>
            }
          </div>
        </div>

      </div>
  )}
}

export default withRouter(ApplicantCard);
