
export const ENV = "PROD_ENV" //DEV_ENV or PROD_ENV


export const DEV_API = "http://18.191.216.157"
export const BLUE_API = "https://api-blue.symbol.co"
export const PROD_API = "https://api.symbol.co"

//export const PROD_API = BLUE_API // COMMENT OR UNCOMMENT

export const DEV_ENV = "DEV_ENV"
export const PROD_ENV = "PROD_ENV"

export const ENV_API = ENV == DEV_ENV ? DEV_API : BLUE_API // BLUE OR PROD

export const ENV_LOGS_OFF = ENV !== DEV_ENV

/* COGNITO CONFIGURATIONS */
export const COGNITO_REGION = "us-east-2"
export const COGNITO_PROD_USER_POOL_ID = "us-east-2_tc3BoTdRM"
export const COGNITO_PROD_APP_CLIENT_ID = "6nlb684dfe88lqfr1l94t279m1"

export const COGNITO_DEV_USER_POOL_ID = "us-east-2_NEFPjon9o"
export const COGNITO_DEV_APP_CLIENT_ID = "36vadvjr6j8ui33outslagahvo"

export const COGNITO_USER_POOL_ID = ENV == DEV_ENV ? COGNITO_DEV_USER_POOL_ID : COGNITO_PROD_USER_POOL_ID
export const COGNITO_APP_CLIENT_ID = ENV == DEV_ENV ? COGNITO_DEV_APP_CLIENT_ID : COGNITO_PROD_APP_CLIENT_ID

export const CLOUDINARY_CLOUD_NAME = 'symbolimages'
export const CLOUDINARY_UPLOAD_PRESET = 's5yvzj6l'
