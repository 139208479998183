import React from 'react'

const BigCheckmark = props => (
  <svg width={21} height={21} {...props}>
    <g fill="none" fillRule="evenodd">
      <circle fill="#226CFF" cx={10.5} cy={10.5} r={10.5} />
      <g stroke="#FFF" strokeLinecap="square" strokeWidth={2.25}>
        <path d="M5.873 10.468l3.336 3.24M9.209 13.709L15 7.5" />
      </g>
    </g>
  </svg>
)

export default BigCheckmark
