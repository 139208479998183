import React, { Component } from 'react';
import './generic-modal.css';
import ModalX from '../../svg/modal-x.js';
import AnimatingSpinnerButton from '../../svg/animating-spinner-button.js'
import { Auth } from "aws-amplify";

// This generic modal takes the following propsss
// isModalOpen
// toggleModal (function to toggle isModalOpen)
// title (the title of the modal)
// message (the body text of the modal)
// primaryAction (function executed when primary button clicked)
// primaryActionLabel (button label)
// secondaryButtonLabel (button label; optional; button only closes modal)



class GenericModal extends Component {
  constructor(props) {
      super(props);
      this.state = {
        isWaiting:false,
        xDoesPrimaryAction: this.props.xDoesPrimaryAction ? true : false
      };
    this.onPrimaryAction = this.onPrimaryAction.bind(this);
    this.onXClicked = this.onXClicked.bind(this);
    }

  async onPrimaryAction() {
    this.props.toggleModal();
    console.log("HEY ON PRIMARY ACTION");
    this.setState({isWaiting:true});
    if (this.props.primaryAction) {
      await this.props.primaryAction();
    }
    this.setState({isWaiting:false});

  }

  onXClicked() {
    if (this.state.xDoesPrimaryAction) {
      this.onPrimaryAction();
    } else {
      this.props.toggleModal();
    }
  }

  render() {
    return (
        <div style={{visibility: this.props.isModalOpen ? 'visible' : 'hidden', opacity:this.props.isModalOpen? '1' : '0'}} className='generic-modal-overlay'>
          <div className='generic-modal-container'>
            <div className='generic-modal'>
              <div className='generic-modal-responsive-container'>
                <div className='generic-modal-top-bar'>
                    <div className='generic-modal-x-container'>
                      <div onClick={this.onXClicked} className='generic-modal-x'>
                        <ModalX />
                      </div>
                    </div>
                </div>

                <div className='generic-modal-title-body'>
                  <div className='generic-modal-title-container'>
                    <div className='generic-modal-title'>
                      <div className='generic-modal-title-label'>
                        {this.props.title}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='generic-modal-scroll-container'>
                  <div className='generic-modal-section'>
                    <div className='generic-modal-section-container'>
                      {this.props.message}
                    </div>
                  </div>
                </div>
                <div className='generic-modal-button-section'>
                  <div className='generic-modal-button-section-container'>
                    {!this.props.secondaryActionLabel? null:
                      <div onClick={this.props.toggleModal} className='generic-modal-secondary-button'>

                        <div className='generic-modal-secondary-button-label'>
                          {this.props.secondaryActionLabel}
                        </div>

                      </div>
                    }
                    <button style={{width:!this.props.secondaryActionLabel? '210px':'169px'}} onClick={this.onPrimaryAction} className={!this.state.isWaiting?'small-button':'small-button'}>


                        {!this.state.isWaiting?
                          this.props.primaryActionLabel
                          :
                          <AnimatingSpinnerButton
                            style={{marginTop:'4px'}}
                          />
                        }




                    </button>




                  </div>
                </div>

            </div>
          </div>
        </div>
      </div>


    );
  }
}

export default GenericModal;
