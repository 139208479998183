import React from 'react'

const StatsIcon1 = props => (
  <svg width={16} height={14} {...props}>
    <g fill="#000" fillRule="evenodd">
      <path d="M10 9.637a.564.564 0 0 1-.17.409.544.544 0 0 1-.401.173H6.57a.544.544 0 0 1-.401-.173.564.564 0 0 1-.17-.409V8.183H0v4.363c0 .4.14.742.42 1.027.28.285.616.427 1.009.427H14.57c.393 0 .73-.142 1.01-.427.28-.285.419-.627.419-1.027V8.183h-6v1.454z" />
      <path d="M6.839 8.183h2.259v1.151H6.839z" />
      <path
        d="M15.58 2.76a1.362 1.362 0 0 0-1.009-.428H11.43V.874a.852.852 0 0 0-.25-.619.818.818 0 0 0-.607-.255H5.429a.819.819 0 0 0-.608.255.852.852 0 0 0-.25.62v1.457H1.43c-.393 0-.73.143-1.01.428C.14 3.046 0 3.39 0 3.79v3.498h16V3.79c0-.401-.14-.744-.42-1.03zm-5.294-.428H5.714V1.166h4.572v1.166z"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default StatsIcon1
