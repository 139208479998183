import React, { Component } from 'react';
import {BrowserRouter as Router, Route, Link, withRouter} from 'react-router-dom';
import './onboarding-steps.css';
import './custom-form.css';
import ReactDOM from 'react-dom';
import {Helmet} from "react-helmet";
import HomepageLogo from '../../svg/homepage-logo.js';
import MultiSelect from './multi-select-symbol.js';
import TextareaAutosize from 'react-autosize-textarea';
import Backend from "../../backend.js";
import Users from "../../store/users.js";
import LightHeader from '../basics/light-header.js';
import FilterData from '../../data/filter-data.js';

const defaultSpecialties = {
  "AR/VR": {"label": "AR/VR", "selected": false, "positionType":"Software Engineering"},
  "Algorithms": {"label": "Algorithms", "selected": false, "positionType":"Software Engineering"},
  "Backend": {"label": "Backend", "selected": false, "positionType":"Software Engineering"},
  "Blockchain": {"label": "Blockchain", "selected": false, "positionType":"Software Engineering"},
  "Computer Vision": {"label": "Computer Vision", "selected": false, "positionType":"Software Engineering"},
  "Data": {"label": "Data", "selected": false, "positionType":"Software Engineering"},
  "DevOps": {"label": "DevOps", "selected": false, "positionType":"Software Engineering"},
  "Embedded": {"label": "Embedded", "selected": false, "positionType":"Software Engineering"},
  "Management": {"label": "Management", "selected": false, "positionType":"Software Engineering"},
  "Frontend": {"label": "Frontend", "selected": false, "positionType":"Software Engineering"},
  "Full Stack": {"label": "Full Stack", "selected": false, "positionType":"Software Engineering"},
  "Gaming": {"label": "Gaming", "selected": false, "positionType":"Software Engineering"},
  "Hardware": {"label": "Hardware", "selected": false, "positionType":"Software Engineering"},
  "Machine Learning": {"label": "Machine Learning", "selected": false, "positionType":"Software Engineering"},
  "Mobile": {"label": "Mobile", "selected": false, "positionType":"Software Engineering"},
  "NLP": {"label": "NLP", "selected": false, "positionType":"Software Engineering"},
  "QA": {"label": "QA", "selected": false, "positionType":"Software Engineering"},
  "Search": {"label": "Search", "selected": false, "positionType":"Software Engineering"},
  "Security": {"label": "Security", "selected": false, "positionType":"Software Engineering"},
  "Software Architecture": {"label": "Software Architecture", "selected": false, "positionType":"Software Engineering"},
  "Brand/Graphic Design": {"label": "Brand/Graphic Design", "selected": false, "positionType":"Design"},
  "Product Design": {"label": "Product Design", "selected": false, "positionType":"Design"},
  "UX Design": {"label": "UX Design", "selected": false, "positionType":"Design"},
  "UX Research": {"label": "UX Research", "selected": false, "positionType":"Design"},
  "Visual/UI Design": {"label": "Visual/UI Design", "selected": false, "positionType":"Design"},
  "Creative Director": {"label": "Creative Director", "selected": false, "positionType":"Design"},
  "Illustration": {"label": "Illustration", "selected": false, "positionType":"Design"},
  "Business Analyst": {"label": "Business Analyst", "selected": false, "positionType":"Data Science & Analytics"},
  "Data Analyst": {"label": "Data Analyst", "selected": false, "positionType":"Data Science & Analytics"},
  "Data Science": {"label": "Data Science", "selected": false, "positionType":"Data Science & Analytics"},
  "Data Visualization": {"label": "Data Visualization", "selected": false, "positionType":"Data Science & Analytics"},
  "Growth Marketing": {"label": "Growth Marketing", "selected": false, "positionType":"Marketing & Communications"},
  "Marketing Manager": {"label": "Marketing Manager", "selected": false, "positionType":"Marketing & Communications"},
  "Content": {"label": "Content", "selected": false, "positionType":"Marketing & Communications"},
  "Product Marketing": {"label": "Product Marketing", "selected": false, "positionType":"Marketing & Communications"},
  "Corporate Marketing": {"label": "Corporate Marketing", "selected": false, "positionType":"Marketing & Communications"},
  "Communications": {"label": "Communications", "selected": false, "positionType":"Marketing & Communications"},
  "Marketing Operations": {"label": "Marketing Operations", "selected": false, "positionType":"Marketing & Communications"},
  "Brand Strategy": {"label": "Brand Strategy", "selected": false, "positionType":"Marketing & Communications"},
  "Account Executive": {"label": "Account Executive", "selected": false, "positionType":"Sales"},
  "Account Manager": {"label": "Account Manager", "selected": false, "positionType":"Sales"},
  "Customer Success": {"label": "Customer Success", "selected": false, "positionType":"Sales"},
  "Partnerships & Events": {"label": "Partnerships & Events", "selected": false, "positionType":"Sales"},
  "Business Development": {"label": "Business Development", "selected": false, "positionType":"Sales"},
  "BD Manager": {"label": "BD Manager", "selected": false, "positionType":"Sales"},
  "SDR Manager": {"label": "SDR Manager", "selected": false, "positionType":"Sales"},
  "SDR": {"label": "SDR", "selected": false, "positionType":"Sales"},
  "Sales Engineer": {"label": "Sales Engineer", "selected": false, "positionType":"Sales"},
  "Sales Operations": {"label": "Sales Operations", "selected": false, "positionType":"Sales"},
  "VP/Head of Sales": {"label": "VP/Head of Sales", "selected": false, "positionType":"Sales"},
  "Business Operations": {"label": "Business Operations", "selected": false, "positionType":"Operations"},
  "Product Operations": {"label": "Product Operations", "selected": false, "positionType":"Operations"},
  "H.R.": {"label": "H.R.", "selected": false, "positionType":"People Operations"},
  "People Analytics": {"label": "People Analytics", "selected": false, "positionType":"People Operations"},
  "Office Manager": {"label": "Office Manager", "selected": false, "positionType":"Operations"},
  "Technical Recruiting": {"label": "Technical Recruiting", "selected": false, "positionType":"People Operations"},
  "Recruiting": {"label": "Recruiting", "selected": false, "positionType":"People Operations"},
  "Customer Service": {"label": "Customer Service", "selected": false, "positionType":"Operations"},
  "Operations Manager": {"label": "Operations Manager", "selected": false, "positionType":"Operations"},
}

class OnboardingStep1 extends Component {

  constructor(props) {
    super(props);



    this.state = {
      specialties: FilterData.specialties,
      locations: FilterData.locations,
      companySizes: FilterData.companySizes,
      positionType: "Select One",
      roleLevel: "",
      specificCompanies: "",
      user: Users.auth,
    };

    this.toggleSpecialtyOption = this.toggleSpecialtyOption.bind(this);
    this.toggleLocationOption = this.toggleLocationOption.bind(this);
    this.toggleCompanySizeOption = this.toggleCompanySizeOption.bind(this);
    this.handlePositionTypeChange = this.handlePositionTypeChange.bind(this);
    this.handleRoleLevelChange = this.handleRoleLevelChange.bind(this);
    this.handleSpecificCompaniesChange = this.handleSpecificCompaniesChange.bind(this);
    this.handleSalaryChange = this.handleSalaryChange.bind(this);
    this.nextStepClick = this.nextStepClick.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.filterSpecialties = this.filterSpecialties.bind(this)
  }

  async componentDidMount() {
    if (this.state.user.whereInFlow == undefined) {
      Users.auth.whereInFlow = "/onboarding/1";
    }
    //ReactGA.pageview('/onboarding1');
    // let fullUser = await Backend.fetchUser(Users.auth.userId);
    // console.log('fullUser', fullUser)
    // console.log('before', Users.auth)
    // Users.auth = fullUser
    // console.log('after', Users.auth)
  }

  loadSelectedForOptions(allOptions, optionsSaved) {
    for (var option in allOptions) {
      var optionObj = allOptions[option]
        if (optionsSaved.indexOf(optionObj.label) > -1) {
          optionObj.selected = true;
        }
    }
    return allOptions;
  }

  getSelectedOptions(options) {
      let optionsArray = Object.keys(options).filter(option => options[option].selected);
      options = optionsArray.map(option => options[option].label);
      return options;
  }

  validateForm() {
    console.log("VALIDATING");
    if (!this.state.user.lookingForJobs || !this.state.user.lookingForJobs.positionType || this.state.user.lookingForJobs.positionType=='Select One' || this.state.user.lookingForJobs.positionType=='' || this.state.user.lookingForJobs.positionType=='_EMPTY_') {
      console.log('1')
      return false
    }

    if (!this.state.user.lookingForJobs || !this.state.user.lookingForJobs.roleLevel || this.state.user.lookingForJobs.roleLevel=='Select One' || this.state.user.lookingForJobs.roleLevel=='' || this.state.user.lookingForJobs.roleLevel=='_EMPTY_') {
      console.log('2')
      return false
    }

    if (!this.state.user.lookingForJobs || !this.state.locations || this.state.locations.length == 0 ) {
      console.log('3')
      return false
    }

    if (!this.state.user.lookingForJobs || !this.state.companySizes || this.state.companySizes.length == 0 ) {
      console.log('4')
      return false
    }

    if (!this.state.user.lookingForJobs || !this.state.user.lookingForJobs.salary || this.state.user.lookingForJobs.salary=='Select One' || this.state.user.lookingForJobs.salary=='' || this.state.user.lookingForJobs.salary=='_EMPTY_') {
      console.log('5')
      return false
    }

    console.log('6')
    return true
  }

  nextStepClick() {
    if (!this.validateForm()) {
      alert('Make sure you complete all required fields')
      return;
    }


    let lookingForJobs = this.state.user.lookingForJobs;
    if (lookingForJobs == undefined) {
      lookingForJobs = {};
    } else {
      lookingForJobs["positionType"] = this.state.user.lookingForJobs.positionType;
      lookingForJobs["specialties"] = this.getSelectedOptions(this.state.specialties);
      lookingForJobs["level"] = this.state.user.lookingForJobs.roleLevel;
      lookingForJobs["locations"] = this.getSelectedOptions(this.state.locations);
      let interestedCompanySizes = this.getSelectedOptions(this.state.companySizes);
      lookingForJobs["companySizes"] = interestedCompanySizes;
      lookingForJobs["specificCompanies"] = this.state.user.lookingForJobs.specificCompanies;
    }

    Users.auth["lookingForJobs"] = lookingForJobs;
    if (this.state.user.whereInFlow == "/onboarding/1") {
      Users.auth["whereInFlow"] = "/onboarding/2";
    }

    Backend.saveApplicantInfo(Users.auth);
    this.props.history.push("/onboarding/2");

  }

  handleSpecificCompaniesChange(evt) {
    var user = JSON.parse(JSON.stringify(this.state.user));
    if (user.lookingForJobs) {
      user.lookingForJobs.specificCompanies = evt.target.value;
    } else {
      user.lookingForJobs = {"specificCompanies": evt.target.value};
    }
    this.setState({user: user});
  }

  toggleLocationOption(value) {

    var user = JSON.parse(JSON.stringify(this.state.user));

    let locations = Object.assign({}, this.state.locations);
    locations[value]["selected"] = !locations[value]["selected"];

    let locationsForDB = this.getSelectedOptions(locations);
    if (Users.auth.lookingForJobs) {
      Users.auth.lookingForJobs.locations = locationsForDB;
      user["lookingForJobs"]["locations"] = locationsForDB;
    } else {
      Users.auth.lookingForJobs = {"locations": locationsForDB};
      user["lookingForJobs"] = {"locations": locationsForDB};
    }
    this.setState({locations: locations});
  }

  toggleSpecialtyOption(value) {
    let specialties = Object.assign({}, this.state.specialties);

    specialties[value]["selected"] = !specialties[value]["selected"];

    let specialtiesForDB = this.getSelectedOptions(specialties);
    if (Users.auth.lookingForJobs) {
      Users.auth.lookingForJobs.specialties = specialtiesForDB;
    } else {
      Users.auth.lookingForJobs = {"specialties": specialtiesForDB};
    }
    this.setState({specialties: specialties});
  }

  toggleCompanySizeOption(value) {
    let companySizes = Object.assign({}, this.state.companySizes);
    console.log('HERE')
    console.log('specialties',companySizes)
    console.log('value',value)
    companySizes[value]["selected"] = !companySizes[value]["selected"];

    var user = JSON.parse(JSON.stringify(this.state.user));
    let companySizesForDB = this.getSelectedOptions(companySizes);
    if (Users.auth.lookingForJobs) {
      Users.auth.lookingForJobs.companySizes = companySizesForDB;
      user["lookingForJobs"]["companySizes"] = companySizesForDB;
    } else {
      Users.auth.lookingForJobs = {"companySizes": companySizesForDB};
      user["lookingForJobs"]["companySizes"] = companySizesForDB;
    }

    this.setState({companySizes: companySizes});
  }

  handlePositionTypeChange(evt) {
    let positionType = evt.target.value;
    this.setState({positionType: positionType});
    var user = JSON.parse(JSON.stringify(this.state.user));
    if (user.lookingForJobs) {
      console.log('hey guys 1')
      user.lookingForJobs.positionType = positionType;
      if (positionType!==this.state.user.lookingForJobs.positionType || positionType!=='Select One') {
        console.log('made it')
        Users.auth.lookingForJobs.specialties = [];
        this.state.user.lookingForJobs.specialties = [];
        this.setState({specialties: defaultSpecialties});
      }
    } else {
      user.lookingForJobs = {"positionType": positionType};
    }
    this.setState({user: user});
  }

  handleRoleLevelChange(evt) {
    let roleLevel = evt.target.value;
    var user = JSON.parse(JSON.stringify(this.state.user));
    if (user.lookingForJobs) {
      user.lookingForJobs.roleLevel = roleLevel;
    } else {
      user.lookingForJobs = {"roleLevel": roleLevel};
    }
    this.setState({user: user});
  }

  handleSalaryChange(evt) {
    console.log('user state', this.state.user)
    let salary = evt.target.value;
    salary = salary.replace(/[^0-9\.]+/g, '');
    var user = JSON.parse(JSON.stringify(this.state.user));
    if (user.lookingForJobs) {
      user.lookingForJobs.salary = salary;
    } else {
      user.lookingForJobs = {"salary": salary};
    }
    this.setState({user: user});
  }

  // filter_specialities(specialty) {
  //   return specialty["positionType"] == this.state.positionType;
  // }

  filterSpecialties(specialties) {
    let filteredSpecialties = Object.keys(specialties).map(key => {return specialties[key]}).filter(specialty => {return specialty["positionType"] == this.state.user.lookingForJobs.positionType})
    let loadedSpecialties={}
    for (var i = 0; i < filteredSpecialties.length; i++) {
      loadedSpecialties[filteredSpecialties[i]["label"]]=filteredSpecialties[i]
    }
    return loadedSpecialties
  }


  render() {

    console.log('USER.AUTH', Users.auth)

    console.log('what is this??',this.state.user)

    let loadedSpecialties = this.state.specialties;
    console.log('unfiltered',loadedSpecialties)

    if (Users.auth.lookingForJobs && Users.auth.lookingForJobs.specialties) {
      loadedSpecialties = this.loadSelectedForOptions(this.state.specialties, Users.auth.lookingForJobs.specialties);

    }


    console.log('filtered',loadedSpecialties)
    loadedSpecialties=this.filterSpecialties(loadedSpecialties)

    let loadedLocations = this.state.locations;
    if (Users.auth.lookingForJobs && Users.auth.lookingForJobs.locations) {
      loadedLocations = this.loadSelectedForOptions(this.state.locations, Users.auth.lookingForJobs.locations);
    }

    let loadedCompanySizes = this.state.companySizes;
    if (Users.auth.lookingForJobs && Users.auth.lookingForJobs.companySizes) {
      loadedCompanySizes = this.loadSelectedForOptions(this.state.companySizes, Users.auth.lookingForJobs.companySizes);
    }

    let positionOptions = Object.keys(FilterData["positionType"]).map((positionType) => {
      return (<option value={positionType}>{positionType}</option>);
    });


    return (
      <div className='onboarding-step'>
        <Helmet>
          <title>Symbol - Get a job</title>
        </Helmet>
        <LightHeader userHasAuthenticated={this.props.userHasAuthenticated} />

        <div className='onboarding-step-number-container'>
          <div className='onboarding-step-number'>
            Step 1 of 3
          </div>
        </div>

        <div className='onboarding-form-container'>
          <div className='onboarding-form-body'>
            <div className='onboarding-form-body-container'>
              <div className='onboarding-form-body-container-inner'>
                <div className='onboarding-form-header'>
                  <div className='onboarding-form-header-title'>
                    Build Your Profile
                  </div>
                  <div className='onboarding-form-header-subtitle'>
                    STEP 1 - DEFINE YOUR IDEAL JOB
                  </div>
                  <div className='onboarding-form-header-text'>
                    This information will help us match you with the right jobs at the right companies.
                  </div>
                </div>

                <form>
                  <div className='form-title'>
                    Position
                  </div>
                  <div className='form-block'>
                    <div className='form-label'>What type of position are you looking for?</div>
                    <div>
                      <select onChange={this.handlePositionTypeChange} value={this.state.user.lookingForJobs ? this.state.user.lookingForJobs.positionType : ""}>
                        <option className="placeholder">Select One</option>
                        {positionOptions}
                      </select>
                    </div>
                  </div>

                  {(this.state.user.lookingForJobs && (!this.state.user.lookingForJobs.positionType || (this.state.user.lookingForJobs.positionType=='Select One' || this.state.user.lookingForJobs.positionType=='Product Management' || this.state.user.lookingForJobs.positionType=='Project Management' || this.state.user.lookingForJobs.positionType=='Finance/Accounting' || this.state.user.lookingForJobs.positionType=='Business & Strategy')))? null:

                  <div className='form-block'>
                    <div className='form-label'>Which specialties are you interested in?</div>
                    <div className='form-label-secondary'>{"Select up to three"}</div>
                    <div>
                      <MultiSelect options={loadedSpecialties} toggleOption={this.toggleSpecialtyOption}/>
                    </div>
                  </div>
                }

                  <div className='form-block'>
                    <div className='form-label'>What level role are you looking for?</div>
                    <div>
                      <select onChange={this.handleRoleLevelChange} value={this.state.user.lookingForJobs ? this.state.user.lookingForJobs.roleLevel : ""}>
                        <option className="placeholder">Select One</option>
                        <option value="Internship-level">Internship</option>
                        <option value="Entry-level">Entry-Level (Full Time)</option>
                        <option value="Mid-level">Mid-Level (Full Time)</option>
                        <option value="Senior-level">Senior (Full Time)</option>
                        <option value="Manager-level">Manager (Full Time)</option>
                        <option value="Director-level">Director (Full Time)</option>
                        <option value="VP/Executive-level">VP/Executive (Full Time)</option>
                      </select>
                    </div>
                  </div>

                  <div className='form-divider'></div>

                  <div className='form-title'>
                    Company
                  </div>
                  <div className='form-block'>
                    <div className='form-label'>Where do you want to work?</div>
                    <div className='form-label-secondary'>{"Select all that apply"}</div>
                    <div>
                      <MultiSelect options={loadedLocations} toggleOption={this.toggleLocationOption}/>
                    </div>
                  </div>

                  <div className='form-block'>
                    <div className='form-label'>What size company are you interested in?</div>
                    <div className='form-label-secondary'>{"Select all that apply"}</div>
                    <div>
                      <MultiSelect options={this.state.companySizes} toggleOption={this.toggleCompanySizeOption}/>
                    </div>
                  </div>

                  <div className='form-block'>
                    <div className='form-label'>Are there any companies or industries you are specifically interested in? (optional)</div>
                    <div>
                      <TextareaAutosize
                        data-hj-whitelist
                        maxRows={5}
                        rows={3}
                        placeholder="List the names of companies you are interested in. List the industries you are most interested in and which you would NOT want to work in. This list won't be shared with companies."
                        value={this.state.user.lookingForJobs ? this.state.user.lookingForJobs.specificCompanies : ""}
                        onChange={this.handleSpecificCompaniesChange}
                        className={'text-area'}
                      />
                    </div>
                  </div>

                  <div className='form-divider'></div>

                  <div className='form-title'>
                    Salary
                  </div>
                  <div className='form-block'>
                    <div className='form-label'>What is your target base salary?</div>
                    <div className='form-label-secondary'>{"Indicate base yearly salary only. Other types of compensation will be discussed with companies directly. This number is not binding."}</div>
                    <div className='salary-block'>
                      <div className='money-sign'>
                        <div className='money-sign-text'>
                          $
                        </div>
                      </div>

                      <input data-hj-whitelist className='inside-money' value={this.state.user.lookingForJobs ? this.state.user.lookingForJobs.salary : ""} onChange={this.handleSalaryChange} type="number"></input>
                    </div>
                  </div>

                </form>

                <div className='onboarding-form-buttons-area'>
                  <div className='step-buttons-container'>
                    <button className='small-button' onClick={this.nextStepClick}>{this.state.user.whereInFlow=='/profile'?"Save and Next":"Next Step"}</button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
    </div>
  )}
}

export default withRouter(OnboardingStep1);
