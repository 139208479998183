import React, { Component } from 'react';
import './multi-select-symbol.css';
import ReactDOM from 'react-dom';

class MultiSelect extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    let options = Object.keys(this.props.options).map(optionKey => {
        let option = this.props.options[optionKey];
        let selected = this.props.options[optionKey]["selected"];
        let className = selected ? 'option-box-container-selected' : 'option-box-container';

        return (
            <div onClick={() => this.props.toggleOption(optionKey)} className={className} key={optionKey}>
              <div className='option-box-label'>
              {option.label}
              </div>
            </div>)
    });
    return (
      <div className='all-options-box'>
        {options}
      </div>
  )}
}

export default MultiSelect;
