import React, { Component } from 'react';
import './email-sidebar.css';
import TextareaAutosize from 'react-autosize-textarea';
import SideBarX from '../../svg/sidebar-x.js';
import Users from "../../store/users.js";
import Backend from "../../backend.js";
import AnimatingSpinnerButton from '../../svg/animating-spinner-button.js';

class EmailSidebar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      name:this.props.user?this.props.user.firstName + ' ' + this.props.user.lastName:'',
      emailBody: "",
      position:this.props.savedEmailInfo.savedPosition,
      salary: this.props.savedEmailInfo.savedSalary,
      isWaiting:false,
    };

    this.sendInviteMessage = this.sendInviteMessage.bind(this);
    this.handleBodyChange = this.handleBodyChange.bind(this);
    this.saveAndCloseSidebar = this.saveAndCloseSidebar.bind(this);
    this.handlePositionChange = this.handlePositionChange.bind(this);
  }
  componentDidMount() {
    let defaultEmailBody = 'Hi ' + this.props.user.firstName + ",";
    defaultEmailBody += "\n\nCongrats! We just finished reviewing your first round interview on Symbol and think you’d be a great fit for our team at " + Users.auth.companyName  + "."
    defaultEmailBody += " We'd love to move forward and invite you to an onsite interview so you can meet our team."
    defaultEmailBody += "\n\nAs a next step, let's schedule a quick call so I can tell you more about the role and so we can find a time for your onsites."
    defaultEmailBody += "\n\nCan you send over a few times you're available for a call this week?"
    defaultEmailBody += "\n\nThanks,\n"+ Users.auth.firstName + " " + Users.auth.lastName;

    if (Users.auth.savedEmailBody) {
      defaultEmailBody = Users.auth.savedEmailBody;
    }

    if (Users.auth.savedSalary) {
      this.setState({"salary": Users.auth.savedSalary});
    }
    if (Users.auth.savedPosition) {
      this.setState({"position": Users.auth.savedPosition});
    }

    this.setState({"emailBody": defaultEmailBody});
  }

  async sendInviteMessage() {
    if (!this.validateForm()) {
      alert(`To send an invite to ${this.props.user.firstName + " " + this.props.user.lastName}, make sure you fill in all required fields`)
      return;
    }

    this.setState({isWaiting:true});

    let emailInfo = {}
    emailInfo["emailBody"] = this.state.emailBody;
    emailInfo["position"] = this.state.position;
    emailInfo["applicantName"] = this.props.user.firstName;
    emailInfo["salary"] = "$" + this.state.salary.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    emailInfo["companyName"] = Users.auth.companyName;
    emailInfo["companyRepName"] = Users.auth.firstName + " " + Users.auth.lastName;
    emailInfo["companyRepEmail"] = Users.auth.email;
    emailInfo["applicantEmail"] = this.props.user.email;

    await Backend.sendCompanyInterviewEmail(emailInfo);

    this.setState({isWaiting:false});
    this.saveAndCloseSidebar();
    this.props.setContacted(this.props.user);
  }

  handleBodyChange(evt) {
    this.setState({emailBody: evt.target.value});
  }

  saveAndCloseSidebar() {

    let savedBody = this.state.emailBody.replace(new RegExp(this.props.user.firstName, 'g'), "CANDIDATE_NAME");
    Users.auth.savedEmailBody = savedBody;
    Users.auth.savedSalary = this.state.salary;
    Users.auth.savedPosition = this.state.position;

    let emailInfo = {"savedEmailBody": savedBody,
                      "savedPosition": this.state.position,
                      "savedSalary": this.state.salary};
    this.props.onEmailInfoChange(emailInfo);
    Backend.saveCompanyRepInfo(Users.auth);
    this.props.toggleSidebar(this.props.user);
  }


  validateForm() {
    if (!this.state.salary || this.state.salary=="" || this.state.salary=="_EMPTY_") {
      return false
    }

    if (this.state.emailBody=="" || this.state.emailBody=="_EMPTY_") {
      return false
    }

    if (!this.state.position || this.state.position=="" || this.state.position=="_EMPTY_") {
      return false
    }

    return true
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.savedEmailInfo !== this.props.savedEmailInfo) {
      if (nextProps.savedEmailInfo.savedEmailBody) {
        this.setState({emailBody: nextProps.savedEmailInfo.savedEmailBody });
      }
    }
  }

  handlePositionChange(evt) {
    this.setState({"position": evt.target.value});
  }

  render() {
    return (
      <div className='email-sidebar' style={{marginRight:this.props.isSidebarOpen?"0px":"-534px",visibility:this.props.isSidebarOpen?'visible':'hidden'}}>
        <div className='email-sidebar-container'>
          <div className='email-sidebar-x-container'>
            <SideBarX
              className='email-sidebar-x'
              onClick={this.saveAndCloseSidebar}
            />
          </div>
          <div className='email-sidebar-title'>
            {this.props.user.firstName + ' ' + this.props.user.lastName} - Invite to onsite interview
          </div>
          <div className='form-label'>Name of role</div>
          <input className='half-input' value={this.state.position} onChange={this.handlePositionChange} placeholder='e.g. Senior Software Engineer' type="text"></input>
          <div className='form-label'>Salary</div>
          <div className='salary-block'>
            <div className='money-sign'>
              <div className='money-sign-text'>
                $
              </div>
            </div>

            <input className='inside-money' value={this.state.salary} onChange={(evt) => this.setState({"salary": evt.target.value})} type="number"></input>
          </div>
          <div className='form-label'>Personal message to {this.props.user.firstName + ' ' + this.props.user.lastName}</div>
          <div>
            <TextareaAutosize
              maxRows={10}
              rows={10}
              placeholder='List the names or URLs of companies you are interested in'
              value={this.state.emailBody}
              onChange={this.handleBodyChange}
              className={'text-area'}/>
          </div>
          <div className='email-sidebar-button-container'>
            <button onClick={this.sendInviteMessage} className={!this.state.isWaiting?'small-button':'small-button-waiting'}>
              {!this.state.isWaiting?
                <div>Invite to interview</div>
                :
                <AnimatingSpinnerButton
                  style={{marginTop:'4px'}}
                />
              }
            </button>
          </div>
        </div>
      </div>
  )}
}

export default EmailSidebar;
