import React from 'react'

const HomepageLogo = props => (
  <svg width={32} height={36} {...props}>
    <g stroke="#226CFF" strokeWidth={2} fill="none" fillRule="evenodd">
      <path d="M15.278 1.268L2.555 8.613a2 2 0 0 0-1 1.732v14.69a2 2 0 0 0 1 1.733l12.723 7.345a2 2 0 0 0 2 0L30 26.768a2 2 0 0 0 1-1.732v-14.69a2 2 0 0 0-1-1.733L17.278 1.268a2 2 0 0 0-2 0z" />
      <path
        d="M23.574 5.437h0a16.282 16.282 0 0 0-7 12.124h0a16.282 16.282 0 0 1-7 12.125"
        strokeLinecap="square"
      />
    </g>
  </svg>
)

export default HomepageLogo
