import React, { Component } from 'react';
import {BrowserRouter as Router, Route, Link, withRouter} from 'react-router-dom';
import ReactDOM from 'react-dom';
import {Helmet} from "react-helmet";
import HomepageLogo from '../../svg/homepage-logo.js';
import TextareaAutosize from 'react-autosize-textarea';
import Backend from "../../backend.js";
import Users from "../../store/users.js";
import './interview.css';
import './react-add-to-calendar.css';
import LightHeader from '../basics/light-header.js';
import Checkmark from '../../svg/checkmark.js'
import BigCheckmark from '../../svg/big-checkmark.js'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AddToCalendar from 'react-add-to-calendar';
import AnimatingSpinnerButton from '../../svg/animating-spinner-button.js';
import Confetti from 'react-dom-confetti';

const confettiConfig = {
  angle: 90,
  spread: 65,
  startVelocity: 45,
  elementCount: 50,
  decay: 0.9
};

class Interview extends Component {

  constructor(props) {
    super(props);

    let startDate = new Date();
    if (Users.auth.interviewDate) {
      startDate = new Date(Users.auth.interviewDate);
    }
    let isInterviewScheduled = false;
    if (Users.auth.hasScheduledInterview)  {
      isInterviewScheduled = Users.auth.hasScheduledInterview;
    }

    this.state = {
      startDate: startDate,
      isInterviewScheduled:isInterviewScheduled,
      isWaiting:false,
      user: Users.auth,
      readyToInterview: (startDate - new Date()) < 3600000,
    };

    this.onSchedule = this.onSchedule.bind(this);
    this.onChangeTime = this.onChangeTime.bind(this);
    this.nextStepClick = this.nextStepClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  nextStepClick() {
    this.props.history.push("/interview/1");
  }

  handleChange(date) {

     this.setState({
       startDate: date,
     });


     console.log('startDAte',this.state.startDate)
     console.log('endDAte',this.state.endDate)
   }

   async onSchedule() {
     this.setState({
       isWaiting:true
     });


     console.log("interview startDate: ", this.state.startDate);

     let result = await Backend.scheduleInterview(Users.auth, this.state.startDate);
     Users.auth.hasScheduledInterview = true;
     Users.auth.interviewDate = this.state.startDate;
     console.log("result from schedule ", result);

     //setTimeout(() => {
     this.setState({
       isInterviewScheduled: true
     });
     this.setState({
       isWaiting:false
     });

     this.setState({
       readyToInterview: (new Date(this.state.startDate) - new Date()) < 3600000
     });

       console.log('readyToInterview',this.state.readyToInterview)

     // }, 1000);



    }

  onChangeTime() {
     this.setState({
       isInterviewScheduled: false
     });
   }

  render() {
    const end = new Date(this.state.startDate)
    const endTime = end.setHours(end.getHours()+1)
    let title = "First Round Interview (Symbol.co)"
    let newLine = "\n\n"
    let smallLine = "\n"
    let htmlFriendlyBody = "Take the interview at https://symbol.co/interview." + newLine

    htmlFriendlyBody += "Here's some important info that you should review to make sure you're well-prepared:" + newLine
    htmlFriendlyBody += "1. Take time to brainstorm accomplishments and examples that best demonstrate your talent and experience in the role you selected. If you don't have much experience, think of accomplishments and examples that show why you would be great at the role." + newLine
    htmlFriendlyBody += "2. Take time to brainstorm examples from your life or work that help demonstrate the following skills:"+smallLine
    htmlFriendlyBody += "- Collaboration - a time you successfully worked with people who had different ideas, areas of expertise, or work styles" + smallLine
    htmlFriendlyBody += "- Leadership - a time you led, motivated, or influenced other people" + smallLine
    htmlFriendlyBody += "- Ambition - a time you took on an ambitious project (inside or outside of work)" + smallLine
    htmlFriendlyBody += "- Getting Work Done - a time when you had too much to do, but not enough resources (time, money, people, etc.)" + smallLine
    htmlFriendlyBody += "- Learning - a time when you quickly learned something new or challenging" + smallLine
    htmlFriendlyBody += "- Openness to Feedback - a time you received and acted on feedback (from a co-worker, customer, friend, etc.)" + smallLine
    htmlFriendlyBody += "3. During the interview, here are a few things to remember:" + newLine
    htmlFriendlyBody += "- Write your answers clearly" + smallLine
    htmlFriendlyBody += "- Give context to terms not everyone will know" + smallLine
    htmlFriendlyBody += "- Treat your responses as independent answers (each response will be reviewed separately)." + newLine
    htmlFriendlyBody += "The interview should take you about an hour to complete. It's a single interview that can be used with 200+ top companies, so make sure you do your best job." + newLine
    htmlFriendlyBody += "Once you finish, your responses will be reviewed and companies can start reaching out to invite you to onsite interviews." + newLine
    htmlFriendlyBody += "Let us know if you have any questions before your interview." + newLine
    htmlFriendlyBody += "Good luck!" + smallLine
    htmlFriendlyBody += "-The Symbol Team"



    let event = {
        title: title,
        description: htmlFriendlyBody,
        location: 'https://symbol.co/interview',
        startTime: this.state.startDate,
        endTime:endTime

    };

    return (
      <div className='onboarding-step'>
        <Helmet>
          <title>Symbol - Get a job</title>
        </Helmet>
        <LightHeader userHasAuthenticated={this.props.userHasAuthenticated} />


        <div className='onboarding-step-number-container'>

          <div className='onboarding-step-number'>
              {this.state.isInterviewScheduled? null: "Congrats! Profile complete"}
              {this.state.isInterviewScheduled? null:
              <div className='checkmark-2'>
                <Checkmark />
              </div>
              }

          </div>

        </div>


        <div className='onboarding-form-container'>
          <div className='interview-form-body'>
            <div className='onboarding-form-body-container'>
              <div className='onboarding-form-body-container-inner'>
                <div className='interview-welcome-header'>
                  <div className='interview-form-header-title'>
                  {this.state.isInterviewScheduled?
                    <div>{this.state.readyToInterview?"Ready to interview ":"Your interview is scheduled "}<BigCheckmark className='big-checkmark' /></div>
                    :
                    <div>Next Step: Schedule your interview</div>
                  }
                  </div>
                  {this.state.isInterviewScheduled?
                  <div className='onboarding-form-header-text-no-margin'>
                    {this.state.readyToInterview?"It's time to start your first round interview. You have 1-hour to submit your answers. Click 'Start interview' to get started. Good luck!": "Come back at your scheduled time to complete your Symbol first round interview:"}
                  </div>
                  :
                  <div className='onboarding-form-header-text-no-margin'>
                  Your profile is complete and looking good. The next step is to complete Symbol's common first round interview. Schedule your interview start time below.
                  </div>
                  }
                  <br/>

                  <DatePicker
                    selected={this.state.startDate}
                    onChange={this.handleChange}
                    showTimeSelect
                    disabled={this.state.isInterviewScheduled}
                    timeIntervals={30}
                    dateFormat="MMMM d, yyyy h:mm aa"
                  />

                  {this.state.isInterviewScheduled?
                  <div className='select-time-container'>
                    <AddToCalendar event={event} />
                    <div className='edit-schedule' onClick={this.onChangeTime}>Change time</div>
                  </div>
                  :null}

                  {!this.state.isInterviewScheduled?

                  <div>
                    <div className='onboarding-form-header-text-no-margin'>
                      <span className='interview-cta'>What is this?:</span> Symbol's written interview takes 1 hour to complete, lets you show off your abilities in detail, and is accepted by all of our 200+ partner companies. Once completed, companies will review your responses and can start inviting you to final round interviews, usually in less than 3 business days.
                    </div>
                    <br/>
                    <div className='onboarding-form-header-text-no-margin'>
                      <span className='interview-cta'>Interview prep:</span> Once scheduled, we'll send you more information to help you prepare for your interview, so you know what to expect.
                    </div>
                  </div>
                  :
                  <div>
                    {!this.state.readyToInterview?
                    <div>
                      <div className='onboarding-form-header-text-no-margin'>
                        <span className='interview-cta'>Next steps:</span> Return here at your scheduled time to complete your interview. Add the interview to your calender so you don't forget. Review the materials we email you in preparation for the interview.
                      </div>
                      <br/>
                      <div className='onboarding-form-header-text-no-margin'>
                        <span className='interview-cta'>What is this?:</span> Symbol's written interview takes 1 hour to complete, lets you show off your abilities in detail, and is accepted by all of our 200+ partner companies. Once completed, companies will review your responses and can start inviting you to final round interviews, usually in less than 3 business days.
                      </div>
                    </div>
                    :
                    <div>
                      <div className='onboarding-form-header-text-no-margin'>
                        <span className='interview-cta'>What to expect:</span> Symbol's written interview takes 1 hour to complete, lets you show off your abilities in detail, and is accepted by all of our 200+ partner companies. Once completed, companies will review your responses and start inviting you to final round interviews in less than 3 business days.
                      </div>
                    </div>
                  }
                  </div>

                }

                </div>


                <div className='onboarding-form-buttons-area'>

                  <div className='step-buttons-container'>

                    <div className='go-back-button' onClick={() => this.props.history.push("/onboarding/3")}>Go back</div>

                    {this.state.isInterviewScheduled?
                      <div>
                      {this.state.readyToInterview?
                        <button onClick={() => this.props.history.push("/interview/1")} className='small-button'>Start interview</button>
                        :
                        <button className='small-button-disabled'>Start interview</button>
                      }
                      </div>
                    :
                    <div>

                      <button className={!this.state.isWaiting?'small-button':'small-button-waiting'} onClick={this.onSchedule}>
                        {!this.state.isWaiting?
                          <div>Schedule interview</div>
                          :
                          <AnimatingSpinnerButton
                            style={{marginTop:'4px'}}
                          />
                        }
                      </button>
                    </div>
                    }
                      <Confetti className='scoot-left' active={!this.state.isWaiting} config={confettiConfig} />
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='homepage-companies-section'>
          <div className='interview-companies-section-title'>
            ONE INTERVIEW FOR 200+ TOP COMPANIES
          </div>
          <div className='interview-companies-section-logos-container'>

            <img className='interview-logos' src='https://uploads-ssl.webflow.com/5be8cea3dc46203a5ad06bda/5c002680e59b2e356b078506_Group%205.png'/>

          </div>
        </div>
    </div>
  )}
}

export default withRouter(Interview);
