import React, { Component } from 'react';
import './light-header.css';
import HomepageLogo from '../../svg/homepage-logo.js';
import { Auth } from "aws-amplify";
import Users from "../../store/users.js";
import {BrowserRouter as Router, Route, Link, withRouter} from 'react-router-dom';


class LightHeader extends Component {

  constructor(props) {
    super(props);
    this.onLogoutClick = this.onLogoutClick.bind(this);
  }

  async onLogoutClick() {
    console.log("LOGOUT CLICK ")
    Users.auth = Users.emptyUser
    console.log("new auth ", Users.auth);
    await Auth.signOut();
    this.props.userHasAuthenticated(false);
    this.props.history.push("/");
  }


  render() {
    return (
      <div className='light-header-top-bar'>
        <div className='light-header-top-bar-container'>
          <div className='light-header-logo'>
            <div className='light-header-logo-container'>
              <HomepageLogo />
            </div>
            <div className='light-header-logo-text-container'>
              symbol
            </div>
          </div>
          <div className='light-header-links-container'>
            {Users.auth.whereInFlow=="/profile"?
              <a onClick={()=>this.props.history.push("/profile")}>Profile</a>
              : null
            }
            <a onClick={this.onLogoutClick}>Log out</a>
          </div>
        </div>
      </div>
  )}
}

export default withRouter(LightHeader);
