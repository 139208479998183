let emptyUser = {"skills": [], "userId": "", "username": "", "firstName": "", "lastName": "",
                "lookingForJobs": {"level": "", "positionType": "", "locations": [], "companySizes": [], "specificCompanies": ""},
                "workExperiences": [], "colleges": [],
                "interviewResponses": {},
                }

let emptyUser2 = {"skills": [], "userId": "", "username": "", "firstName": "", "lastName": "",
                "lookingForJobs": {"level": "", "positionType": "", "locations": [], "companySizes": [], "specificCompanies": ""},
                "workExperiences": [], "colleges": [],
                "interviewResponses": {},
                }


let Users = {"auth": emptyUser, "otherUsers": {}, "emptyUser": emptyUser2}

export default Users
