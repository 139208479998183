import React, { Component } from 'react';
import './filter-block.css';
import FilterPlus from '../../../svg/filter-plus.js';
import FilterMinus from '../../../svg/filter-minus.js';
import FilterPillX from '../../../svg/filter-pill-x.js';

class FilterBlock extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
      options: this.props.options,
    };
    this.toggleExpanded = this.toggleExpanded.bind(this);
    this.toggleOptionSelect = this.toggleOptionSelect.bind(this);
  }

  toggleExpanded() {
    this.setState(state => ({
      isExpanded: !state.isExpanded
    }))
  }

  toggleOptionSelect(option) {
    let options = this.props.options;
    console.log("the options available ", options);
    let selectedOption = options[option];
    console.log("selected ", option, selectedOption);
    selectedOption.selected = !selectedOption.selected;
    options[option] = selectedOption
    this.props.filterReqChange(selectedOption);
  }

  render() {
    const borderStyle = {
      // borderBottom: this.state.isExpanded? "1px solid #226CFF":"1px solid #EBEDF2",
      // borderTop:this.state.isExpanded?"1px solid #226CFF":"none",
      // borderRight:this.state.isExpanded?"1px solid #226CFF":"none",
      // borderLeft:this.state.isExpanded?"1px solid #226CFF":"none",
    }

    function filter_unselected(option) {
      return option.selected == false;
    }

    function filter_selected(option) {
      return option.selected == true;
    }

    const unselectedOptionsArray = Object.keys(this.props.options).map(key => {return this.props.options[key]}).filter(filter_unselected);
    const selectedOptionsArray = Object.keys(this.props.options).map(key => {return this.props.options[key]}).filter(filter_selected);

    const unselectedOptions = unselectedOptionsArray.map((option, index) => {
        return (
          <div key={index}>
            <div onClick={() => this.toggleOptionSelect(option.label)} className='filter-block-option'>
              {option.label}
            </div>
          </div>
        );
    });

    const selectedOptions = selectedOptionsArray.map((option, index) => {
        return (
          <div key={index} className='filter-block-pill'>
            {option.label}
            <FilterPillX
              className='filter-pill-x'
              onClick={() => this.toggleOptionSelect(option.label)}
            />
          </div>
        );
    });


    return (
      <div className='filter-block' style={borderStyle}>
        <div onClick={this.toggleExpanded} className='filter-block-top-container'>
          <div className='filter-block-title'>
            {this.props.filterName}
          </div>
          <div className='filter-block-plus-container'>
            {this.state.isExpanded ?

            <FilterMinus
              className='filter-block-minus'
            />
            :
            <FilterPlus
              className='filter-block-plus'
            />
            }
          </div>
        </div>

        <div className='filter-block-pills-container'>
          {selectedOptions}
        </div>

        {!this.state.isExpanded ? null:
        <div className='filter-block-expanded'>
          {unselectedOptions}
        </div>
        }
      </div>
  )}
}

export default FilterBlock;
