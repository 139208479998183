import React, { Component } from 'react';
import './review-lander.css';
import Backend from "../../backend.js";
import { Auth } from "aws-amplify";
import BackArrowDark from '../../svg/back-arrow-dark.js';
import FaqCard from './faq-card.js';
import Faqs from './faqs.js';
import {Helmet} from "react-helmet";
import DarkHeader from '../basics/dark-header.js';
import Users from "../../store/users.js";

const TOTAL_STARTING_NUMREVIEWS = 25;

class ReviewLander extends Component {
  constructor(props) {
      super(props);
      this.state = {
        user:{
          "firstName":"Max",
          "lastName":"Deutsch",
          "profilePhoto":"https://res.cloudinary.com/symbolimages/image/upload/v1541113321/20689635_10155071136853757_3824635970969336790_o_iw1cft.jpg",
          "location":"San Francisco, CA",
          "title":"Product Manager @ Intuit"
        }
      };
      this.onReturnToProfileClick = this.onReturnToProfileClick.bind(this);
      this.goToReviews = this.goToReviews.bind(this);
  }

  // async componentDidMount() {
  //
  //   let isAuthUsersProfile = this.props.match.params.username
  //   this.setState({isShownToExternalViewer: !isAuthUsersProfile})
  //   this.setState({isUsernameError: false});
  //   await this.setUser();
  //   //await Backend.setUsername(this.state.username);
  //   let done = await this.fetchReviewerInfo();
  //   console.log('this is the all might review queue: ',this.state.reviewQueue, done)
  //   let numberOfCaseStudies = this.calculateNumberOfCaseStudies();
  //   let percentageLeft = this.calculateReviewPercentageLeft();
  //   this.setState({percentageLeft: percentageLeft});
  //   this.setState({numberOfCaseStudies: numberOfCaseStudies});
  // }
  //
  // async setUser() {
  //   let user = await Backend.fetchUserByUsername(this.state.username);
  //
  //   let username = user["username"]
  //   Users.otherUsers[username] = {"username": username}
  //   for (var property in user) {
  //       if (user.hasOwnProperty(property)) {
  //         Users.otherUsers[username][property] = user[property]
  //       }
  //   }
  //   this.setState({user: user});
  // }

  onReturnToProfileClick() {
    this.props.history.push('/profile');
  }

  goToReviews() {
    this.props.history.push('/reviews');
  }
  //
  // async fetchReviewerInfo() {
  //   let reviewQueue = await Backend.getBestQueueForReviewer(Users.auth.userId)
  //   console.log("whole review queue: ", reviewQueue);
  //   this.setState({reviewQueue: reviewQueue});
  //   return true
  // }

  // calculateReviewPercentageLeft() {
  //   let total = 0
  //   let numberLeft = 0
  //   if (this.state.reviewQueue) {
  //     for (let i = 0; i < this.state.reviewQueue.length; i++) {
  //       let skillName = this.state.reviewQueue[i]["skill"];
  //       let skills = this.state.reviewQueue[i]["project"]["skills"];
  //       numberLeft += skills[skillName]["ownerNumReqReviews"];
  //       total += TOTAL_STARTING_NUMREVIEWS;
  //     }
  //
  //   }
  //
  //   if (total!==0) {
  //     return Math.ceil(((total-numberLeft)/total)*100)
  //
  //   }
  //   return 0
  // }
  //
  //
  // calculateNumberOfCaseStudies() {
  //   let total = 0
  //   let projects=[]
  //   for (let i = 0; i < this.state.reviewQueue.length; i++) {
  //     let skillName = this.state.reviewQueue[i]["skill"];
  //     let projectId = this.state.reviewQueue[i]["project"]["projectId"];
  //     if (projects.indexOf(projectId) === -1) {
  //       projects.push(projectId)
  //       total += 1
  //     }
  //   }
  //
  //   return total
  //
  // }


  render() {

    const faqs = Faqs.map(faq => {
      return (
        <div className='review-lander-faq-section-cards'>
          <FaqCard
            question={faq.question}
            answer={faq.answer}
          />
        </div>

      );
    });

    //let reviewLanderDescription = <div>Review and score other candidates' interviews to boost your hiring bonus. <span className='blue-text'>Earn $15 per review up to $3000.</span> Your current hiring bonus is $500.<br/><br/>If your reviews are high-quality and thoughtful, we will boost the visilibity of your profile to companies (we've found that your ability to identify other people's talent correlates highly with your own abilities').</div>

    return (
      <div className="review-lander">
        <Helmet>
          <title>Peer Reviews | Symbol</title>
        </Helmet>
        <div className='review-lander-top-bar'>
          <div className='review-lander-top-bar-container'>
            <div onClick={this.onReturnToProfileClick} className='review-lander-back-to-profile'>
              <BackArrowDark />
              <div className='review-lander-back-to-profile-label'>
                Back to profile
              </div>
            </div>
          </div>
        </div>


        <div className='review-lander-hero-section'>
          <div className='review-lander-hero-section-container'>
            <div className='review-lander-hero-section-column-left'>
              <div className='review-lander-hero-section-blue-benefit'>
                BOOST YOUR PROFILE
              </div>
              <div className='review-lander-hero-section-title'>
                Earn more money and boost your visibility to companies by assessing other responses.
              </div>
              <div className='review-lander-hero-section-body'>
                {"See two candidates' interview answers at a time and submit which one you think is better."} Earn $10 per review, earn up to $3000. {"Your current hiring bonus is $" + Users.auth.hiringBonus +'.'}
                <br/><br/>
                {"The more high-quality reviews you submit, the more visible you become in employer search results. (Learn more in below's FAQ)"}
              </div>
            </div>
            <div className='review-lander-hero-section-column-right'>
              <div className='review-lander-hero-image-right'>
                <div className='review-lander-hero-table'>
                  <div className='review-lander-hero-table-row'>
                    <div className='review-lander-hero-table-row-label'>
                      Current hiring bonus
                    </div>
                    <div className='review-lander-hero-table-row-value'>
                      ${Users.auth.hiringBonus}
                    </div>

                  </div>
                  <div className='review-lander-hero-table-row'>
                    <div className='review-lander-hero-table-row-label'>
                      Maximum hiring bonus
                    </div>
                    <div className='review-lander-hero-table-row-value'>
                      $3000
                    </div>
                  </div>
                  <div className='review-lander-hero-table-button-row'>
                    <button className='large-button' onClick={this.goToReviews}>
                      Begin assessing →
                    </button>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>

        <div className='review-lander-faq-section'>
          <div className='review-lander-faq-section-container-margin'>
            <div className='review-lander-faq-section-container'>
              <div className='review-lander-faq-section-title'>
                Frequently Asked Questions
              </div>
            </div>
            <div className='review-lander-faq-section-container'>
              {faqs}
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default ReviewLander;
