import React, { Component } from 'react';
import {BrowserRouter as Router, Route, Link, withRouter} from 'react-router-dom';
import './employer-applicant-profile.css';
import '../profile/onboarding-steps.css';
import ReactDOM from 'react-dom';
import {Helmet} from "react-helmet";
import HomepageLogo from '../../svg/homepage-logo.js';
import ApplicantCard from '../applicant/applicant-card.js';
import ApplicantInterviewCard from '../applicant/applicant-interview-card.js';
import DarkHeader from '../basics/dark-header.js';
import Users from "../../store/users.js";

class EmployerApplicantProfile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      user: Users.auth
    };

    this.handleBackToResults = this.handleBackToResults.bind(this);
  }

  handleBackToResults() {
    this.props.toggleApplicantProfile(this.props.user);
    this.props.history.push("/candidates");
  }

  render() {

    let interviewResponses = []

    if (this.props.user && this.props.user.interviewResponses) {
      interviewResponses = Object.keys(this.props.user.interviewResponses).map(responseKey => {
        let interviewResponse = this.props.user.interviewResponses[responseKey];
        console.log("int response: ", interviewResponse);

        return (
          <div className='applicant-profile-cards-container'>
            <ApplicantInterviewCard
              user={this.props.user}
              interviewResponse={interviewResponse}
              viewedByCompany={true}
            />
          </div>
        )
      });
    }



    return (
      <div className='employer-applicant-profile'>
        <Helmet>
          <title>Candidate Profile - Symbol</title>
        </Helmet>
        <div className='background'></div>
        <DarkHeader userHasAuthenticated={this.props.userHasAuthenticated} />
        <div onClick={this.handleBackToResults} className='applicant-profile-back'>
          ← Back to search results
        </div>
        <div className='applicant-profile-cards'>
          <div className='applicant-profile-cards-container'>
            <ApplicantCard
              user={this.props.user}
              toggleSidebar={this.props.toggleSidebar}
              viewedByCompany={true}
            />
          </div>
          {interviewResponses}
      </div>
    </div>
  )}
}

export default withRouter(EmployerApplicantProfile);
