import React from 'react'

const FilterMinus = props => (
  <svg width={9} height={3} {...props}>
    <path
      d="M10 1.591H1"
      stroke="#000"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="square"
    />
  </svg>
)

export default FilterMinus
