import React, { Component } from 'react';
import './header-bar.css';
import Reviewer from './data/reviewer.js'

const HeaderBar = props => {


  return(
    <div className='header-bar'>
      <div className='header-bar-container'>
        <div className='header-bar-person'>
          <div className='header-bar-person-container'>
            <div className='header-bar-person-labels-container'>
              <div className='header-bar-person-label'>
                Interview by <span className='underline'>Person A</span>
              </div>
              <div className='header-bar-person-skill-label'>
                Skill under review: <span className='blue'>{props.skillToReview}</span>
              </div>
            </div>
          </div>
        </div>
        <div className='header-bar-person'>
          <div className='header-bar-person-container'>
            <div className='header-bar-person-labels-container'>
              <div className='header-bar-person-label'>
                Interview by <span className='underline'>Person B</span>
              </div>
              <div className='header-bar-person-skill-label'>
                Skill under review: <span className='blue'>{props.skillToReview}</span>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>

    );

}

export default HeaderBar;
