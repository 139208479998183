import React from 'react'

const TrashIcon = props => (
  <svg width="11" height="14" viewBox="0 0 11 14" xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" fill="none" fillRule="evenodd">
        <g id="Onboarding-Copy" transform="translate(-1023 -1137)" fill="#838383">
            <g id="rubbish-bin" transform="translate(1023 1137)">
                <path d="M10.0512859,1.64848302 L8.19316667,1.64848302 L8.19316667,0.433796094 C8.19316667,0.189296457 7.99712582,0 7.75410131,0 C7.73051961,0 7.71479248,0.00791974861 7.70711765,0.0158214156 C7.6992451,0.00791974861 7.68351797,0 7.6756634,0 L3.30860948,0 L3.27733497,0 L3.24588072,0 C3.00285621,0 2.81468791,0.189296457 2.81468791,0.433796094 L2.81468791,1.6485011 L0.948714052,1.6485011 C0.41548366,1.6485011 0,2.06647578 0,2.60290313 L0,3.2970022 L0,4.1645763 L0.823256536,4.1645763 L0.823256536,13.0534996 C0.823256536,13.5899451 1.23088562,14 1.76411601,14 L9.23590196,14 C9.76913235,14 10.184598,13.5899451 10.184598,13.0534996 L10.184598,4.1645763 L11,4.1645763 L11,3.2970022 L11,2.60290313 C11,2.06647578 10.5843546,1.64848302 10.0512859,1.64848302 Z M3.6770915,0.867574106 L7.3229085,0.867574106 L7.3229085,1.64848302 L3.6770915,1.64848302 L3.6770915,0.867574106 Z M9.31433987,13.0535177 C9.31433987,13.1088655 9.29075817,13.1324078 9.23590196,13.1324078 L1.76411601,13.1324078 C1.7092598,13.1324078 1.6856781,13.1088475 1.6856781,13.0535177 L1.6856781,4.16455822 L9.31433987,4.16455822 L9.31433987,13.0535177 Z M10.1297418,3.29698412 L0.87025817,3.29698412 L0.87025817,2.60288505 C0.87025817,2.54768187 0.893839869,2.51605712 0.948696078,2.51605712 L10.0512859,2.51605712 C10.1061601,2.51605712 10.1297418,2.54768187 10.1297418,2.60288505 L10.1297418,3.29698412 Z"
                id="Shape" fillRule="nonzero" />
                <rect id="Rectangle" x="7.333" y="5.353" width="1" height="7" />
                <rect id="Rectangle" x="5.107" y="5.353" width="1" height="7" />
                <rect id="Rectangle" x="2.852" y="5.353" width="1" height="7" />
            </g>
        </g>
    </g>
</svg>
)

export default TrashIcon
