import React, { Component } from 'react';
import './projects-preview-section.css';
//import Projects from './data/projects.js';
import TextareaAutosize from 'react-autosize-textarea';
import ReactDOM from 'react-dom';
import BlueLinkIcon from '../../svg/blue-link-icon.js';

class ProjectsPreviewSection extends Component {

  constructor(props) {
    super(props);
    this.refA = React.createRef();
    this.refB = React.createRef();
  }

  scrollToDomRef = () => {
    const refA = ReactDOM.findDOMNode(this.refA.current)
    refA.scrollIntoView()
    const refB = ReactDOM.findDOMNode(this.refB.current)
    refB.scrollIntoView()
  }

  componentWillReceiveProps(props) {
    const { responseA, responseB } = this.props;
    if (props.responseA !== responseA && props.responseB !== responseB) {
      this.scrollToDomRef();
    }
  }

  render() {

    return(
      <div className='projects-preview-section'>
        <div className='projects-preview-section-container'>
          <div className='project-section-container'>
            <div className='project-container'>

              <div ref={this.refA} className='project-section'>
                <div className='project-section-body'>
                  <div className='project-section-body-container'>
                    <div className='project-section-body-text-container'>
                    <div className='project-section-body-text'>
                      <div className='project-section-label'>
                        {this.props.responseA.firstPrompt}
                      </div>
                      <TextareaAutosize
                         value={this.props.responseA.firstAnswer}
                         readOnly={true}
                         className='project-section-body-textarea'
                       />
                    </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div className='project-section-container'>
            <div className='project-container'>

              <div ref={this.refB} className='project-section'>
                <div className='project-section-body'>
                  <div className='project-section-body-container'>
                    <div className='project-section-body-text-container'>
                    <div className='project-section-body-text'>
                      <div className='project-section-label'>
                        {this.props.responseB.firstPrompt}
                      </div>
                      <TextareaAutosize
                         value={this.props.responseB.firstAnswer}
                         readOnly={true}
                         className='project-section-body-textarea'
                       />
                    </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>

      </div>

      );
  }
}

export default ProjectsPreviewSection;
