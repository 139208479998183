import React, { Component } from 'react';
import {BrowserRouter as Router, Route, Link, withRouter} from 'react-router-dom';
import './employer-create-account.css';
import '../profile/custom-form.css';
import ReactDOM from 'react-dom';
import {Helmet} from "react-helmet";
import HomepageLogo from '../../svg/homepage-logo.js';
import Backend from "../../backend.js";
import LightHeader from '../basics/light-header.js';

class EmployerCreateAccount extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };

  }

  render() {

    return (
      <div className='onboarding-step'>
        <Helmet>
          <title>Symbol - Get a job</title>
        </Helmet>
        <LightHeader userHasAuthenticated={this.props.userHasAuthenticated} />

        <div className='onboarding-step-number-container'>
          <div className='onboarding-step-number'>

          </div>
        </div>

        <div className='onboarding-form-container'>
          <div className='interview-form-body'>
            <div className='onboarding-form-body-container'>
              <div className='onboarding-form-body-container-inner'>
                <div className='interview-welcome-header'>
                  <div className='interview-form-header-title'>
                    {"Welcome to Symbol. Let's get you setup."}
                  </div>
                  <div className='onboarding-form-header-text-no-margin'>
                    Schedule a quick call with your account manager to setup your account and start hiring.
                  </div>

                </div>


                <div className='onboarding-form-buttons-area'>
                  <div className='step-buttons-container'>
                    <button className='small-button' onClick={()=> window.open('https://calendly.com/symbol/setup','_blank')}>Schedule setup call</button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>


    </div>
  )}
}

export default withRouter(EmployerCreateAccount);
