import React, { Component } from 'react';
import './dark-header.css';
import HomepageLogo from '../../svg/homepage-logo.js';
import { Auth } from "aws-amplify";
import Users from "../../store/users.js";
import {BrowserRouter as Router, Route, Link, withRouter} from 'react-router-dom';

class DarkHeader extends Component {

  constructor(props) {
    super(props);
    this.onLogoutClick = this.onLogoutClick.bind(this);
  }

  async onLogoutClick() {
    await Auth.signOut();
    Users.auth = Users.emptyUser
    this.props.userHasAuthenticated(false);
    this.props.history.push("/");
    //window.location.reload();
  }

  render() {
    return (
      <div className='dark-header-top-bar'>
        <div className='dark-header-top-bar-container'>
          <div className='dark-header-logo'>
            <div className='dark-header-logo-container'>
              <HomepageLogo />
            </div>
            <div className='dark-header-logo-text-container'>
              symbol
            </div>
          </div>
          <div className='dark-header-links-container'>
            <a onClick={this.onLogoutClick}>Log out</a>
          </div>
        </div>
      </div>
  )}
}

export default withRouter(DarkHeader);
