import React, { Component } from 'react';
import {BrowserRouter as Router, Route, Link, withRouter} from 'react-router-dom';
import '../profile/custom-form.css';
import ReactDOM from 'react-dom';
import {Helmet} from "react-helmet";
import HomepageLogo from '../../svg/homepage-logo.js';
import TextareaAutosize from 'react-autosize-textarea';
import Backend from "../../backend.js";
import Users from "../../store/users.js";
import AnimatingSpinnerButton from '../../svg/animating-spinner-button.js';
import { getWordCount } from './utils'
import LightHeader from '../basics/light-header.js';
import GenericModal from '../modal/generic-modal.js'

const SECOND_WORD_LIMIT = 300;
const FIRST_WORD_LIMIT = 300;
const FIRST_WORD_MIN = 150;
const SECOND_WORD_MIN = 150;

class InterviewQuestion extends Component {

  constructor(props) {
    super(props);
    this.state = {
      user: Users.auth,
      firstAnswer: "",
      firstSupportingLink: "",
      firstWordcount: 0,
      secondAnswer: "",
      secondWordcount: 0,
      isTrustModalOpen: false,
      hasPasted: false,
      skillName: "Product Management",
      firstPrompt: "Describe an example (from work, a project, etc.) that best highlights your abilities to think like a product manager (i.e. identify and breakdown problems, think and plan solutions to these problems).",
      secondPrompt: "Explain your general approach to product and how it applies to the first in Part 1.",
      isWaiting:false,
    };

    this.nextStepClick = this.nextStepClick.bind(this);
    this.handleFirstChange = this.handleFirstChange.bind(this);
    this.handleSecondChange = this.handleSecondChange.bind(this);
    this.toggleTrustModal = this.toggleTrustModal.bind(this);
  }


  handleFirstChange(evt) {
    let prevFirstAnswer = this.getFirstAnswer()
    let prevWordCount = getWordCount(prevFirstAnswer);
    let wordCount = getWordCount(evt.target.value);
    console.log("hey this is the current firstAnswer ", prevWordCount);
    console.log("and new ", wordCount);
    if (wordCount - prevWordCount >= 10) {
      console.log("PASTED! ");
      this.setState({hasPasted: true});
    }

    let firstAnswer = evt.target.value;
    console.log("EXAMPLE DESC: ", firstAnswer);
    this.setState({firstAnswer: evt.target.value, firstWordcount: wordCount});

    var user = JSON.parse(JSON.stringify(this.state.user));
    if (!user["interviewResponses"][this.props.filler.firstLabel]) {
      user["interviewResponses"][this.props.filler.firstLabel] = {}
    }
    user["interviewResponses"][this.props.filler.firstLabel]["firstAnswer"] = firstAnswer;
    Users.auth = user;
    this.setState({user: user});
  }

  handleSecondChange(evt) {
    let prevSecondAnswer = this.getSecondAnswer()
    let prevWordCount = getWordCount(prevSecondAnswer);
    let wordCount = getWordCount(evt.target.value);

    if (wordCount - prevWordCount >= 10) {
      this.setState({hasPasted: true});
    }

    let secondAnswer = evt.target.value;
    this.setState({secondAnswer: evt.target.value, secondWordcount: wordCount});

    var user = JSON.parse(JSON.stringify(this.state.user));
    if (!user["interviewResponses"][this.props.filler.secondLabel]) {
      user["interviewResponses"][this.props.filler.secondLabel] = {}
    }
    user["interviewResponses"][this.props.filler.secondLabel]["firstAnswer"] = secondAnswer;
    Users.auth = user;
    this.setState({user: user});
  }


  async nextStepClick() {
    let firstAnswer = this.getFirstAnswer()
    let secondAnswer = this.getSecondAnswer()
    //let firstSupportingLink = this.getFirstSupportingLink()

    let firstWordcount = getWordCount(firstAnswer)
    let secondWordcount = getWordCount(secondAnswer)

    if (firstWordcount > FIRST_WORD_LIMIT || firstWordcount < FIRST_WORD_MIN) {
      alert("Make sure your answer to Part 1 is within the required word count");
      return;
    }

    if (secondWordcount > SECOND_WORD_LIMIT || secondWordcount < SECOND_WORD_MIN) {
      alert("Make sure your answer to Part 2 is within the required word count");
      return;
    }

    if (this.state.hasPasted) {
      this.toggleTrustModal();
      this.setState({hasPasted: false});
      return;
    }


    if (this.state.user.whereInFlow == "/interview") {
      Users.auth["whereInFlow"] = "/interview/2";
      console.log('here 1')
    } else if (this.state.user.whereInFlow == "/interview/2") {
      Users.auth["whereInFlow"] = "/interview/3";
      console.log('here 2')
    } else if (this.state.user.whereInFlow == "/interview/3") {
      Users.auth["whereInFlow"] = "/interview/4";
      console.log('here 3')
    } else if (this.state.user.whereInFlow == "/interview/4") {
      Users.auth["whereInFlow"] = "/profile";
      console.log('here 4')
    }

    //let section1Skill = ""


    let interviewResponse1 = {
      "creatorId": Users.auth.userId,
      "skillName": this.props.filler.firstLabel,
      "firstAnswer": firstAnswer,
      "secondAnswer": secondAnswer,
      "firstPrompt": this.props.filler.firstPrompt,
      "secondPrompt": this.props.filler.secondPrompt,
      "firstLabel": this.props.filler.firstLabel,
      "secondLabel": this.props.filler.secondLabel,
      "state": "waiting_to_submit"
    };

    let interviewResponse2 = {
      "creatorId": Users.auth.userId,
      "skillName": this.props.filler.secondLabel,
      "firstAnswer": secondAnswer,
      "secondAnswer": firstAnswer,
      "firstPrompt": this.props.filler.secondPrompt,
      "secondPrompt": this.props.filler.firstPrompt,
      "firstLabel": this.props.filler.secondLabel,
      "secondLabel": this.props.filler.firstLabel,
      "state": "waiting_to_submit"
    };


    if (!Users.auth.interviewResponses) {
      Users.auth.interviewResponses = {};
    }

    console.log("hey doing this for ", this.props.filler.secondLabel, this.props.filler.firstLabel);
    Users.auth.interviewResponses[this.props.filler.secondLabel] = interviewResponse2;
    Users.auth.interviewResponses[this.props.filler.firstLabel] = interviewResponse1;
    //Users.auth.interviewResponses[this.props.filler.skillName] = interviewResponse1;
    this.setState({isWaiting:true})

    let response = await Backend.saveApplicantInfo(Users.auth);
    this.setState({isWaiting:false})
    console.log("response is ", response);
    if (response.status != "success") {
      alert("There was a problem saving your response.\n\nPlease check your internet connection and save your response somewhere else because it has not saved on our database.\n\nIf this message doesn't show up when you click next, it means it's saved.");
      return;
    }
    //Backend.saveInterviewResponse(interviewResponse);
    this.props.history.push(this.props.filler.nextLink);
  }

  getSecondAnswer() {
    let secondAnswer = ""
    if (this.state.user.interviewResponses && this.state.user.interviewResponses[this.props.filler.secondLabel]) {
      secondAnswer = this.state.user.interviewResponses[this.props.filler.secondLabel].firstAnswer; // firstAnswer is the real answer of the secondLabel question
    }
    if (!secondAnswer) {
      secondAnswer = ""
    }

    return secondAnswer
  }

  // getFirstSupportingLink() {
  //   let firstSupportingLink = ""
  //   if (this.state.user.interviewResponses && this.state.user.interviewResponses[this.props.filler.skillName]) {
  //     firstSupportingLink = this.state.user.interviewResponses[this.props.filler.skillName].firstSupportingLink;
  //   }
  //   return firstSupportingLink
  // }

  getFirstAnswer() {
    let firstAnswer = ""
    if (this.state.user.interviewResponses && this.state.user.interviewResponses[this.props.filler.firstLabel]) {
      firstAnswer = this.state.user.interviewResponses[this.props.filler.firstLabel].firstAnswer;
    }
    if (!firstAnswer) {
      firstAnswer = ""
    }
    return firstAnswer
  }

  toggleTrustModal() {
    this.setState(state => ({
      isTrustModalOpen: !state.isTrustModalOpen
    }));
  }

  render() {


    let firstAnswer = this.getFirstAnswer()
    //let firstSupportingLink = this.getFirstSupportingLink()
    let secondAnswer = this.getSecondAnswer()

    let firstWordcount = getWordCount(firstAnswer)
    let secondWordcount = getWordCount(secondAnswer)

    return (
      <div className='onboarding-step'>
        <Helmet>
          <title>Symbol - Get a job</title>
        </Helmet>
        <LightHeader userHasAuthenticated={this.props.userHasAuthenticated} />

        <div className='onboarding-step-number-container'>
          <div className='onboarding-step-number'>
            {this.props.filler.interviewNumberDescription}
          </div>
        </div>

        <div className='onboarding-form-container'>
          <div className='onboarding-form-body'>
            <div className='onboarding-form-body-container'>
              <div className='onboarding-form-body-container-inner'>
                <div className='onboarding-form-header'>
                  <div className='onboarding-form-header-title'>
                    First Round Interview
                  </div>
                  <div className='onboarding-form-header-subtitle'>
                    {this.props.filler.title}
                  </div>
                  <div className='onboarding-form-header-text'>
                    {this.props.filler.intro}
                  </div>
                </div>

                <form>

                  <div className='form-section'>
                  <div className='form-title'>
                    Part 1 — {this.props.filler.firstLabel}
                  </div>

                  <div className='form-block'>
                      <div className='form-label'>{this.props.filler.firstPrompt}</div>
                      <div>
                        <TextareaAutosize
                          data-hj-whitelist
                          maxRows={20}
                          rows={10}
                          placeholder={"Describe this example in detail. " + FIRST_WORD_MIN + " words minimum. " + FIRST_WORD_LIMIT + " words maximum."}
                          value={firstAnswer}
                          onChange={this.handleFirstChange}
                          className={'text-area'}/>
                        <div className='word-count-bar'>
                          <div className='form-label-secondary'>150 words minimum. 300 words maximum.</div>
                          <div className='form-label-secondary' style={{color: firstWordcount > FIRST_WORD_LIMIT ? 'rgb(184, 73, 73)' : '#838383'}}>{"Word count: " + firstWordcount}</div>
                        </div>
                      </div>
                  </div>
                  </div>

                  <div className='form-title'>
                    Part 2 — {this.props.filler.secondLabel}
                  </div>
                  <div className='form-block'>
                    <div className='form-label'>{this.props.filler.secondPrompt}</div>
                    <div>
                      <TextareaAutosize
                        data-hj-whitelist
                        maxRows={20}
                        rows={10}
                        placeholder={"Describe this example in detail. "+ SECOND_WORD_MIN + " words minimum. "+SECOND_WORD_LIMIT+" words maximum."}
                        value={secondAnswer}
                        onChange={this.handleSecondChange}
                        className={'text-area'}/>
                      <div className='word-count-bar'>
                        <div className='form-label-secondary'>150 words minimum. 300 words maximum.</div>
                        <div className='form-label-secondary' style={{color: secondWordcount > SECOND_WORD_LIMIT ? 'rgb(184, 73, 73)' : '#838383'}}>{"Word count: " + secondWordcount}</div>
                      </div>
                    </div>
                  </div>
                  </form>

                {this.state.user.whereInFlow=='/profile'? null:
                <div className='onboarding-form-message-area-2'>
                  {this.props.filler.skillName!=="Adaptability"?
                  <div className='onboarding-message'>By clicking "Submit and Next", your answers to this interview question will be submitted for review and you will be taken to the next question. You will be able to make edits to these interview answers later.</div>
                  :
                  <div className='onboarding-message'>By clicking "Submit and Finish", your answers to this interview question will be submitted for review. You will be able to make edits to these interview answers later.<br/><br/>This is the last question in the interview. Once complete, we will start sharing your profile and interview results with companies.</div>

                }
                </div>
                }

                <div className={this.state.user.whereInFlow=='/profile'?'onboarding-form-buttons-area':'onboarding-form-buttons-area-2'}>
                  <div className='step-buttons-container'>
                    {(this.state.userwhereInFlow=='/profile' && this.props.filler.skillName=='Role')?null:
                    <div className='go-back-button' onClick={() => this.props.history.push(this.props.filler.backLink)}>Go back</div>
                    }

                    <button className={!this.state.isWaiting?'small-button':'small-button-waiting'} onClick={this.nextStepClick}>
                      {!this.state.isWaiting?
                        <div>{this.state.user.whereInFlow=='/profile'?this.props.filler.skillName!=="Adaptability"?"Save and Next":"Save and Finish":this.props.filler.skillName!=="Adaptability"?"Submit and Next":"Submit and Finish"}</div>
                        :
                        <AnimatingSpinnerButton
                          style={{marginTop:'4px'}}
                        />
                      }
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Truth MODAL */}
        <GenericModal
           isModalOpen={this.state.isTrustModalOpen}
           toggleModal={this.toggleTrustModal}
           title={"Copy and paste?"}
           message={<div>
              <p>We detected that you copy and pasted part of your answer.</p>
              <p>This is a friendly reminder to make sure you're using personal real-world examples and not something taken from the internet or placeholder text.</p>
              <p>Anything submitted will start being reviewed.</p>
             </div>}
           primaryActionLabel={"Got it"}
        />
    </div>
  )}
}

export default withRouter(InterviewQuestion);
