import React, { Component } from 'react';
import {BrowserRouter as Router, Route, Link, withRouter} from 'react-router-dom';
import './employer-applicant-search.css';
import '../profile/onboarding-steps.css';
import ReactDOM from 'react-dom';
import {Helmet} from "react-helmet";
import ApplicantCard from '../applicant/applicant-card.js';
import ReactSlider from "react-slider";
import Filters from './filters/filters.js';
import DarkHeader from '../basics/dark-header.js';
import EmailSidebar from './email-sidebar.js';
import EmployerApplicantProfile from './employer-applicant-profile.js';
import Users from "../../store/users.js";
import Backend from "../../backend.js";
import {DEFAULT_TALENT_MAX_PERCENTILE, DEFAULT_TALENT_MIN_PERCENTILE, DEFAULT_TALENT_MULTIPLIER, DEFAULT_MAX_SALARY} from "./filters/filter-constants.js"
import FilterData from '../../data/filter-data.js';

class EmployerApplicantSearch extends Component {

  constructor(props) {
    super(props);

    let talentFilters = {
      "Learning Agility": {"minValue": DEFAULT_TALENT_MIN_PERCENTILE, "maxValue": DEFAULT_TALENT_MAX_PERCENTILE, "multiplierValue": DEFAULT_TALENT_MULTIPLIER},
      "Leadership": {"minValue": DEFAULT_TALENT_MIN_PERCENTILE, "maxValue": DEFAULT_TALENT_MAX_PERCENTILE, "multiplierValue": DEFAULT_TALENT_MULTIPLIER},
      "Openness to Feedback": {"minValue": DEFAULT_TALENT_MIN_PERCENTILE, "maxValue": DEFAULT_TALENT_MAX_PERCENTILE, "multiplierValue": DEFAULT_TALENT_MULTIPLIER},
      "Getting Work Done": {"minValue": DEFAULT_TALENT_MIN_PERCENTILE, "maxValue": DEFAULT_TALENT_MAX_PERCENTILE, "multiplierValue": DEFAULT_TALENT_MULTIPLIER},
      "Collaboration": {"minValue": DEFAULT_TALENT_MIN_PERCENTILE, "maxValue": DEFAULT_TALENT_MAX_PERCENTILE, "multiplierValue": DEFAULT_TALENT_MULTIPLIER},
      "Ambition": {"minValue": DEFAULT_TALENT_MIN_PERCENTILE, "maxValue": DEFAULT_TALENT_MAX_PERCENTILE, "multiplierValue": DEFAULT_TALENT_MULTIPLIER},
    }

    let reqFilters = {
      "locations": {},
      "salary": {},
      "positionType": {},
      "Role specializations": {},
      "jobSearchStatus": {},
      "companySizes": {},
      "specialties": {},
      "level": {},
      "workAuth": {"visaSponsorshipRequired": false, "visaSponsorshipFutureRequired": false},
    }

    this.state = {
      isApplicantProfileOpen: false,
      user: Users.auth,
      loadedUsers: [],
      userToSee: {},
      talentFilters: talentFilters,
      reqFilters: reqFilters,
      specialtiesDisplayed: {},
      minSalary: "",
      maxSalary: "",
      visaSponsorshipNotRequired: false,
      visaSponsorshipFutureNotRequired: false,
      contactedCandidates: false,
      notContactedCandidates: false,
      savedEmailInfo: {},
      isSidebarOpen: false,
    };

    this.toggleApplicantProfile = this.toggleApplicantProfile.bind(this);
    this.filterTalentChange = this.filterTalentChange.bind(this);
    this.filterSalaryChange = this.filterSalaryChange.bind(this);
    this.filterWorkAuthChange = this.filterWorkAuthChange.bind(this);
    this.filterReqChange = this.filterReqChange.bind(this);
    this.filterSpecialties = this.filterSpecialties.bind(this);
    this.onEmailInfoChange = this.onEmailInfoChange.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.setContacted = this.setContacted.bind(this);
    this.removeSpecialtiesForPosition = this.removeSpecialtiesForPosition.bind(this);
  }

  async componentDidMount() {
    if (this.props.match.params.candidateUsername) {
      let userToSee = await Backend.fetchUserByUsername(this.props.match.params.candidateUsername);
      if (userToSee) {
        this.setState({isApplicantProfileOpen: !this.state.isApplicantProfileOpen, userToSee: userToSee});
      } else {
        this.props.history.push("/candidates");
      }
    }
    let candidates = await Backend.fetchCandidates();
    this.setState({loadedUsers: candidates});
  }

  toggleApplicantProfile(userToSee) {

    this.setState({isApplicantProfileOpen: !this.state.isApplicantProfileOpen, userToSee: userToSee});

    this.props.history.push("/candidates/" + userToSee.username);
  }

  removeSpecialtiesForPosition(positionType) {
    let reqFilters = JSON.parse(JSON.stringify(this.state.reqFilters));
    let currentSpecialties = reqFilters["specialties"];
    let newSpecialties = Object.keys(currentSpecialties).map(key => {
      return (currentSpecialties[key]);
    }).filter(specialtyObject => {
      return (specialtyObject["positionType"] != positionType);
    });

    let newSpecialtiesDict = {};
    for (var i = 0; i< newSpecialties.length; i++) {
      newSpecialtiesDict[newSpecialties[i]["label"]] = newSpecialties[i];
    }
    reqFilters["specialties"] = newSpecialtiesDict;
    //this.setState({specialtiesDisplayed: newSpecialtiesDict});
    this.setState({reqFilters: reqFilters});
  }

  filterSpecialties(specialties, positionTypes) {
    function positionTypeOn(positionType, positionTypes) {
      let isOn = false;
      if (positionType in positionTypes) {
        isOn = positionType in positionTypes && (positionTypes[positionType]["selected"] == true);
      }
      return isOn;
    }

    let filteredSpecialties = Object.keys(specialties).map(key => {return specialties[key]}).filter(specialty => {return positionTypeOn(specialty["positionType"], this.state.reqFilters.positionType)})
    let loadedSpecialties = {}
    for (var i = 0; i < filteredSpecialties.length; i++) {
      loadedSpecialties[filteredSpecialties[i]["label"]]=filteredSpecialties[i]
    }

    let reqFilters = JSON.parse(JSON.stringify(this.state.reqFilters));
    let currentSpecialties = reqFilters["specialties"];
    reqFilters["specialties"] = loadedSpecialties;
    this.setState({"reqFilters": reqFilters});
    this.setState({specialtiesDisplayed: loadedSpecialties});
    //return loadedSpecialties
  }

  filterReqs(user) {
    if (!user.lookingForJobs) {
      console.log("user didn't have lookingForJobs ", user.lookingForJobs)
      return false;
    }

    let reqKeys = Object.keys(this.state.reqFilters)
    for (var i = 0; i < reqKeys.length; i++) {
      let reqKey = reqKeys[i];

      // if (reqKey == "jobSearchStatus") {
      //   let filterValue = this.state.reqFilters[reqKey];
      //   let passesFilter = this.filterReqValues(user.jobSearchStatus, filterValue);
      //   if (!passesFilter) {
      //     return false;
      //   }
      // }

      if (reqKey in user.lookingForJobs) {
        let userValue = user.lookingForJobs[reqKey];
        let filterValue = this.state.reqFilters[reqKey];
        let passesFilter = this.filterReqValues(userValue, filterValue);
        if (!passesFilter) {
          return false;
        }
      }
    }

    return true;
  }


  filterValuesEmpty(filterValues) {
    if (filterValues.length == 0) {
      return true;
    }

    let filterOptionsKeys = Object.keys(filterValues);
    for (var i = 0; i<filterOptionsKeys.length; i++) {
      if (filterValues[filterOptionsKeys[i]]["selected"]) {
        return false;
      }
    }
    return true;
  }


  filterValuesContainAny(filterValues) {
    let filterOptionsKeys = Object.keys(filterValues);
    for (var i = 0; i<filterOptionsKeys.length; i++) {

      if (filterValues[filterOptionsKeys[i]] && filterValues[filterOptionsKeys[i]]["selected"]) {
        let label = filterValues[filterOptionsKeys[i]]["key"];
        if (label == "Anywhere" || label == "Any size") {
          return true;
        }
      }

    }
    return false;
  }

  filterReqValues(userValue, filterValues) {
    function valueInFilter(value, filter) {
      if (value in filter && filter[value]["selected"]) {
        return true;
      }
      if (value == "Anywhere" || value == "Any size") {
        return true;
      }
      return false;
    }

    if (this.filterValuesEmpty(filterValues)) {
      return true;
    }

    if (this.filterValuesContainAny(filterValues)) {
      return true;
    }

    if (Array.isArray(userValue)) { // user open to several options
      for (var i = 0; i < userValue.length; i++) {
        let value = userValue[i];
        if (valueInFilter(value, filterValues)) {
          return true;
        }
      }

    } else {
      if (valueInFilter(userValue, filterValues)) {
        return true;
      }
    }

    return false;
  }

  filterContactedChange(filterName, key, value) {
    if (key == "Contacted candidates") {
      this.setState({contactedCandidates: value});
    }
    if (key == "Not contacted candidates") {
      this.setState({notContactedCandidates: value});
    }

  }

  filterContacted(user) {
    if (this.state.contactedCandidates == this.state.notContactedCandidates) {
      return true;
    }

    if (this.state.contactedCandidates) {
      if (user.contactedByReps && Users.auth.userId in user.contactedByReps) {
        return true;
      }
    }

    if (this.state.notContactedCandidates) {
      if (!user.contactedByReps || !(Users.auth.userId in user.contactedByReps)) {
        return true;
      }
    }

    return false;
  }

  filterReqChange(fullOption) {
    let filterName = fullOption.key;
    let key = fullOption.label;
    let value = fullOption.selected;
    let filterValues = JSON.parse(JSON.stringify(this.state.reqFilters));
    console.log("the filtername and the key ", filterName, key)
    if (filterName == "contacted") {
      this.filterContactedChange(filterName, key, value);
      return;
    }

    filterValues[filterName][key] = {"selected": value, "key": key, "label": filterName}//["selected"] = value;
    if (filterName == "specialties") {
      filterValues[filterName][key]["label"] = key;
      filterValues[filterName][key]["key"] = filterName;
      filterValues[filterName][key]["positionType"] = fullOption.positionType;
    }

    this.setState({reqFilters: filterValues}, () => {
        if (filterName == "positionType") {
          this.filterSpecialties(FilterData.specialties, this.state.reqFilters.positionType);
          if (!value) { // if removing a positionType, also remove all selected specialties of that position.
            this.removeSpecialtiesForPosition(key);
          }
        }
    });
  }

  filterWorkAuthChange(fullOption) {
    if (fullOption.label == "No visa sponsorship required in the next year") {
        this.setState({"visaSponsorshipNotRequired": fullOption.selected});
    }

    if (fullOption.label == "No visa sponsorship required ever") {
        this.setState({"visaSponsorshipFutureNotRequired": fullOption.selected});
    }

  }

  filterWorkAuth(user) {
    let userVisaSponsorshipRequired = user.workAuthorization.visaSponsorshipRequired == "false" || !user.workAuthorization.visaSponsorshipRequired;
    let userVisaSponsorshipFutureRequired = user.workAuthorization.visaSponsorshipFutureRequired == "false" || !user.workAuthorization.visaSponsorshipFutureRequired;
    if (this.state.visaSponsorshipNotRequired && !userVisaSponsorshipRequired) {
      return false;
    }
    if (this.state.visaSponsorshipFutureNotRequired && !userVisaSponsorshipFutureRequired) {
      return false;
    }
    return true;
  }

  filterSalaryChange(key, value) {
    if (key == "minValue") {
      this.setState({minSalary: value});
    }
    if (key == "maxValue") {
      this.setState({maxSalary: value});
    }
  }

  filterSalaries(user) {
    if (!user.lookingForJobs || !user.lookingForJobs.salary) {
      // if somehow the user doesn't have any of these..
      user.lookingForJobs.salary = 10;
      //return false;
    }
    let userPreferredSalary = parseInt(user.lookingForJobs.salary);
    let minSalary = parseInt(this.state.minSalary);
    let maxSalary = parseInt(this.state.maxSalary);
    if (this.state.minSalary == "") {
      minSalary = 0;
    }
    if (this.state.maxSalary == "") {
      maxSalary = DEFAULT_MAX_SALARY;
    }
    return userPreferredSalary >= minSalary && userPreferredSalary <= maxSalary;
  }

  filterTalentChange(filterName, key, value) {
    let filterValues = JSON.parse(JSON.stringify(this.state.talentFilters));
    filterValues[filterName][key] = value;
    this.setState({talentFilters: filterValues});
  }

  filterTalents(user) {
    if (!user.interviewResponses) {
      return false;
    }

    let intResKeys = Object.keys(user.interviewResponses)
    for (var i = 0; i < intResKeys.length; i++) {
      let interviewResponse = user.interviewResponses[intResKeys[i]];
      let responseSkillName = interviewResponse.skillName;
      if (responseSkillName in this.state.talentFilters) {
        let filterValue = this.state.talentFilters[responseSkillName];
        let passesFilter = this.filterLimitValues(interviewResponse.displayBucket, filterValue.minValue, filterValue.maxValue);
        if (!passesFilter) {
          return false;
        }
      }
    }

    return true;
  }

  filterLimitValues(skillValue, minValue, maxValue) {
    if (!skillValue) {
      skillValue = 50; // default if the user doesn't have a score for this yet.
    }
    return skillValue >= minValue && skillValue <= maxValue;
  }

  talentScore(candidate) {
    let candidateScore = 0;
    if (!candidate && !candidate.interviewResponses) {
      return 0;
    }

    let intResKeys = Object.keys(candidate.interviewResponses);
    for (var i = 0; i < intResKeys.length; i++) {
      let interviewResponse = candidate.interviewResponses[intResKeys[i]];
      let responseSkillName = interviewResponse.skillName;
      if (responseSkillName in this.state.talentFilters) {
        let filterValue = this.state.talentFilters[responseSkillName];
        let displayBucket = interviewResponse.displayBucket;
        if (!displayBucket) {
          displayBucket = 50;
        }
        let multiplier = filterValue.multiplierValue;
        candidateScore += displayBucket * multiplier;
      }
    }
    return candidateScore;
  }

  compareTalent(candidateA, candidateB) {
    let candidateAScore = this.talentScore(candidateA);
    let candidateBScore = this.talentScore(candidateB);
    return candidateBScore - candidateAScore;
  }

  onEmailInfoChange(emailInfo) {
    this.setState({savedEmailInfo: emailInfo});
  }

  toggleSidebar(user) {
    let defaultEmailBody = 'Hi ' + user.firstName + ",";
    defaultEmailBody += "\n\nCongrats! We just finished reviewing your first round interview on Symbol and think you’d be a great fit for our team at " + Users.auth.companyName  + "."
    defaultEmailBody += " We'd love to move forward and invite you to an onsite interview so you can meet our team."
    defaultEmailBody += "\n\nAs a next step, let's schedule a quick call so I can tell you more about the role and so we can find a time for your onsites."
    defaultEmailBody += "\n\nCan you send over a few times you're available for a call this week?"
    defaultEmailBody += "\n\nThanks,\n"+ Users.auth.firstName + " " + Users.auth.lastName;

    let savedEmailInfo = JSON.parse(JSON.stringify(this.state.savedEmailInfo));

    // if it's closed and we're going to open it for a new candidate:
    if (!this.state.isSidebarOpen) {

      if (Users.auth.savedEmailBody) {
        let withNewName = Users.auth.savedEmailBody.replace(new RegExp("CANDIDATE_NAME", 'g'), user.firstName);
        defaultEmailBody = withNewName
        savedEmailInfo.savedEmailBody = defaultEmailBody

       } else {

          if ("savedEmailBody" in this.state.savedEmailInfo) {
            let withNewName = savedEmailInfo.savedEmailBody.replace(new RegExp("CANDIDATE_NAME", 'g'), user.firstName);
            defaultEmailBody = withNewName
          }

         if (Users.auth.savedEmailBody) {
           let withNewName = Users.auth.savedEmailBody.replace(new RegExp("CANDIDATE_NAME", 'g'), user.firstName);
           defaultEmailBody = withNewName
         }
          savedEmailInfo.savedEmailBody = defaultEmailBody;
       }

      this.setState({savedEmailInfo: savedEmailInfo}, () =>
                this.setState({userToSee: user}, () => this.setState({isSidebarOpen: !this.state.isSidebarOpen})));
    } else {
      this.setState({userToSee: user}, () => this.setState({isSidebarOpen: !this.state.isSidebarOpen}));
    }
  }

  setContacted(userContacted) {
    let loadedUsers = JSON.parse(JSON.stringify(this.state.loadedUsers));

    for (var i = 0; i < loadedUsers.length; i++) {
      let user = loadedUsers[i];
      if (user.userId == userContacted.userId) {
        if (!user.contactedByReps) {
          user.contactedByReps = {}
        }
        user.contactedByReps[Users.auth.userId] = true;
        loadedUsers.splice(i, 1, user);
      }
    }
    this.setState({loadedUsers: loadedUsers});

    if (!userContacted.contactedByReps) {
      userContacted.contactedByReps = {}
    }
    userContacted.contactedByReps[Users.auth.userId] = true;

    Backend.setApplicantContactedByReps(userContacted.userId, userContacted.contactedByReps);
  }

  filterSameCompany(user) {

    let workExperiences = user.workExperiences;

    for (var i = 0; i < workExperiences.length; i++) {
      let workExp = workExperiences[i];
      console.log("companyName ", workExp.companyName, Users.auth.companyName);
      if (workExp.companyName == Users.auth.companyName) {
        return false;
      }
    }
    // let allWorkedCompanies = workExperiences.map(workExperience  => workExperiences.companyName);
    // console.log("all worked companies ", allWorkedCompanies);
    // if (allWorkedCompanies.indexOf(Users.auth.companyName) != -1) {
    //   return false;
    // }
    return true;
  }

  allFilters(user) {
    return this.filterTalents(user) && this.filterReqs(user) && this.filterSalaries(user) && this.filterWorkAuth(user) && this.filterSameCompany(user) && this.filterContacted(user);
  }

  sortedUserCards() {
    let filteredUsers = this.state.loadedUsers;
    filteredUsers = filteredUsers.filter((user) => this.allFilters(user));

    let sortedUsers = filteredUsers.sort((candidateA, candidateB) => this.compareTalent(candidateA, candidateB));

    let userCards = sortedUsers.map(user => {
      let userContactedBy = user.contactedByReps;
      let contacted = false;
      if (userContactedBy && Users.auth.userId in userContactedBy) {
        contacted = true;
      }

      return (
        <ApplicantCard
          key={user.userId}
          user={user}
          contacted={contacted}
          toggleSidebar={this.toggleSidebar}
          toggleApplicantProfile={this.toggleApplicantProfile}
          viewedByCompany={true}
        />
      );
    });

    return userCards
  }

  render() {
    let userCards = this.sortedUserCards();

    return (
      <div className='applicant-search'>
        <div className='background'></div>
        <Helmet>
          <title>Candidate Search - Symbol</title>
        </Helmet>
        <EmailSidebar
          user={this.state.userToSee}
          onEmailInfoChange={this.onEmailInfoChange}
          savedEmailInfo={this.state.savedEmailInfo}
          toggleSidebar={this.toggleSidebar}
          setContacted={this.setContacted}
          isSidebarOpen={this.state.isSidebarOpen}
        />
        {this.state.isApplicantProfileOpen?
          <EmployerApplicantProfile
            user={this.state.userToSee}
            toggleSidebar={this.toggleSidebar}
            toggleApplicantProfile={this.toggleApplicantProfile}
            userHasAuthenticated={this.props.userHasAuthenticated}
          />
        :null}

        {false ? null:
        <div style={{overflow:this.state.isApplicantProfileOpen?"hidden":"scroll"}} className='applicant-search-container'>
          <DarkHeader userHasAuthenticated={this.props.userHasAuthenticated}/>
          <div className='applicant-search-main'>
            <div className='applicant-search-main-container'>
              <div className='applicant-search-filters-container'>
                <Filters filterTalentChange={this.filterTalentChange} filterWorkAuthChange={this.filterWorkAuthChange} filterSalaryChange={this.filterSalaryChange} filterReqChange={this.filterReqChange} specialtiesDisplayed={this.state.reqFilters.specialties}/>
              </div>
              <div className='applicant-search-applicant-card-container'>
                {userCards}
              </div>
            </div>
          </div>
        </div>
      }


    </div>
  )}
}

export default withRouter(EmployerApplicantSearch);
