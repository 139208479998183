import React, { Component } from 'react';
import {BrowserRouter as Router, Route, Link, withRouter} from 'react-router-dom';
import '../profile/custom-form.css';
import ReactDOM from 'react-dom';
import {Helmet} from "react-helmet";
import HomepageLogo from '../../svg/homepage-logo.js';
import TextareaAutosize from 'react-autosize-textarea';
import Backend from "../../backend.js";
import Users from "../../store/users.js";
import AnimatingSpinnerButton from '../../svg/animating-spinner-button.js';
import { getWordCount } from './utils'


const PROCESS_WORD_LIMIT = 200;
const EXAMPLE_WORD_LIMIT = 300;
const EXAMPLE_WORD_MIN = 150;
const PROCESS_WORD_MIN = 30;

class InterviewQuestion4 extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      user: Users.auth,
      exampleDescription: "",
      exampleSupportingLink: "",
      exampleWordcount: 0,
      processDescription: "",
      processWordcount: 0,
      skillName: "Initiative",
    };
    
    this.nextStepClick = this.nextStepClick.bind(this);
    this.handleExampleChange = this.handleExampleChange.bind(this);
    this.handleProcessChange = this.handleProcessChange.bind(this);
    this.handleSupportingLinkChange = this.handleSupportingLinkChange.bind(this);
  }

  
  handleExampleChange(evt) {
    let wordCount = getWordCount(evt.target.value);
    let exampleDescription = evt.target.value;
    this.setState({exampleDescription: evt.target.value, exampleWordcount: wordCount});
    
    var user = JSON.parse(JSON.stringify(this.state.user));
    if (!user["interviewResponses"][this.state.skillName]) {
      user["interviewResponses"][this.state.skillName] = {}
    }
    user["interviewResponses"][this.state.skillName]["exampleDescription"] = exampleDescription;
    Users.auth = user;
    this.setState({user: user});
  }
  
  handleProcessChange(evt) {
    let wordCount = getWordCount(evt.target.value);
    let processDescription = evt.target.value;
    this.setState({processDescription: evt.target.value, processWordcount: wordCount});
    
    var user = JSON.parse(JSON.stringify(this.state.user));
    if (!user["interviewResponses"][this.state.skillName]) {
      user["interviewResponses"][this.state.skillName] = {}
    }
    user["interviewResponses"][this.state.skillName]["processDescription"] = processDescription;
    Users.auth = user;
    this.setState({user: user});
  }
  
  handleSupportingLinkChange(evt) {
    this.setState({exampleSupportingLink: evt.target.value});
    
    var user = JSON.parse(JSON.stringify(this.state.user));
    if (!user["interviewResponses"][this.state.skillName]) {
      user["interviewResponses"][this.state.skillName] = {}
    }
    user["interviewResponses"][this.state.skillName]["exampleSupportingLink"] = evt.target.value;
    Users.auth = user;
    this.setState({user: user});
  }
  
  nextStepClick() {
    
    let exampleDescription = this.getExampleDescription()
    let processDescription = this.getProcessDescription()
    let exampleSupportingLink = this.getExampleSupportingLink()
    
    let exampleWordcount = getWordCount(exampleDescription)
    let processWordcount = getWordCount(processDescription)
    
    
    if (processWordcount > PROCESS_WORD_LIMIT || processWordcount < PROCESS_WORD_MIN) {
      alert("hey check your process word count");
      return;
    }
    
    if (exampleWordcount > EXAMPLE_WORD_LIMIT || exampleWordcount < EXAMPLE_WORD_MIN) {
      alert("hey check your example word count");
      return;
    }
    
    let interviewResponse = {
      "creatorId": Users.auth.userId,
      "skillName": this.state.skillName,
      "exampleDescription": exampleDescription,
      "exampleSupportingLink": exampleSupportingLink,
      "processDescription": processDescription,
      "state": "waiting_to_submit"
    };
    
    Backend.saveInterviewResponse(interviewResponse);
    this.props.history.push("/profile");
  }
  
  getProcessDescription() {
    let processDescription = ""
    if (this.state.user.interviewResponses && this.state.user.interviewResponses[this.state.skillName]) {
      processDescription = this.state.user.interviewResponses[this.state.skillName].processDescription;
    }
    if (!processDescription) {
      processDescription = ""
    }
    
    return processDescription
  }
  
  getExampleSupportingLink() {
    let exampleSupportingLink = ""
    if (this.state.user.interviewResponses && this.state.user.interviewResponses[this.state.skillName]) {
      exampleSupportingLink = this.state.user.interviewResponses[this.state.skillName].exampleSupportingLink;
    }
    return exampleSupportingLink
  }
  
  getExampleDescription() {
    let exampleDescription = ""
    if (this.state.user.interviewResponses && this.state.user.interviewResponses[this.state.skillName]) {
      exampleDescription = this.state.user.interviewResponses[this.state.skillName].exampleDescription;
    }
    return exampleDescription
  }
  

  render() {
    
    let exampleDescription = this.getExampleDescription()
    let exampleSupportingLink = this.getExampleSupportingLink()
    let processDescription = this.getProcessDescription()
    
    let exampleWordcount = getWordCount(exampleDescription)
    let processWordcount = getWordCount(processDescription)
      
    return (
      <div className='onboarding-step'>
        <Helmet>
          <title>Symbol - Get a job</title>
        </Helmet>
        <div className='onboarding-top-bar'>
          <div className='onboarding-top-bar-container'>
            <div className='onboarding-logo'>
              <div className='onboarding-logo-container'>
                <HomepageLogo />
              </div>
              <div className='onboarding-logo-text-container'>
                symbol
              </div>
            </div>
            <div className='onboarding-links-container'>
              <a href='/'>Log out</a>
            </div>
          </div>
        </div>

        <div className='onboarding-step-number-container'>
          <div className='onboarding-step-number'>
            Interview Question 4 of 4
          </div>
        </div>

        <div className='onboarding-form-container'>
          <div className='onboarding-form-body'>
            <div className='onboarding-form-body-container'>
              <div className='onboarding-form-body-container-inner'>
                <div className='onboarding-form-header'>
                  <div className='onboarding-form-header-title'>
                    First Round Interview
                  </div>
                  <div className='onboarding-form-header-subtitle'>
                    QUESTION 4 - INITIATIVE
                  </div>
                  <div className='onboarding-form-header-text'>
                    This is your chance to highlight your ability to take initiative. Use this opportunity to show off how you took stepped up to lead a team, started a new project, or brought up an important issue.                  
                  </div>
                </div>

                <form>
                
                  <div className='form-section'>
                  <div className='form-title'>
                    Part 1 — Example  
                  </div>
                  
                  <div className='form-block'>
                      <div className='form-label'>Describe an example (from work, a project, etc.) that best highlights how you have taken initiative.</div>
                      <div>
                        <TextareaAutosize 
                          maxRows={20}
                          rows={20}
                          placeholder={"Describe this example in detail. " + EXAMPLE_WORD_MIN + " words minimum. " + EXAMPLE_WORD_LIMIT + " words maximum. You can use a link as supporting material below. \n\nExample:\nI worked on bla bla to wee wee, and woah woah happened. (TODO: put better example here)"} 
                          value={exampleDescription}
                          onChange={this.handleExampleChange}
                          className={'text-area'}/>
                        <div className='word-count-bar'>
                          <div className='form-label-secondary'>150 words minimum. 300 words maximum.</div>
                          <div className='form-label-secondary' style={{color: exampleWordcount > EXAMPLE_WORD_LIMIT ? 'rgb(184, 73, 73)' : '#838383'}}>{"Word count: " + exampleWordcount}</div> 
                        </div>
                      </div>
                  </div>
                  <div className='form-block'>
                    <div>
                      <div className='form-label'>Support your example with a link (optional).</div>
                      <div className='form-label-secondary'>{"Add a link as supporting evidence. This can be a link to a Github repository, a Behance project, a blog post, a press article, a shared document in Google Drive or Dropbox, etc. You can reference this link in your written description above."}</div>
                      <div>
                        <input className='half-input' placeholder='http://example.com' value={exampleSupportingLink} onChange={this.handleSupportingLinkChange} type="text"></input>
                      </div>
                    </div>
                  </div>
                  </div>
                  
                  <div className='form-title'>
                    Part 2 - Process
                  </div>
                  <div className='form-block'>
                    <div className='form-label'>{"Explain your general approach to taking initiative and how it applies to the example in Part 1."}</div>
                    <div>
                      <TextareaAutosize 
                        maxRows={13}
                        rows={13}
                        placeholder={"Explain your process in detail. "+ PROCESS_WORD_MIN + " words minimum. "+PROCESS_WORD_LIMIT+" words maximum.\n\nExample:\nI usually do blu blu when faced with blo blo because bli bli. (TODO: put better example here)"}
                        value={processDescription}
                        onChange={this.handleProcessChange}
                        className={'text-area'}/>
                      <div className='word-count-bar'>
                        <div className='form-label-secondary'>30 words minimum. 200 words maximum.</div>
                        <div className='form-label-secondary' style={{color: processWordcount > PROCESS_WORD_LIMIT ? 'rgb(184, 73, 73)' : '#838383'}}>{"Word count: " + processWordcount}</div> 
                      </div>
                    </div>
                  </div>
                  </form>

                <div className='onboarding-form-buttons-area'>
                  <div className='step-buttons-container'>
                    <div className='go-back-button' onClick={() => this.props.history.push("/interview/3")}>Go back</div>
                    <button onClick={this.nextStepClick}>Submit Interview</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  )}
}

export default withRouter(InterviewQuestion4);