import React, { Component } from 'react';
import './refer-friend.css';
import ReferSidebar from './refer-sidebar.js';

class ReferFriend extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isSidebarOpen: false,
    };

    this.toggleSidebar = this.toggleSidebar.bind(this);
  }

  // onReferClick() {
  //   console.log("referring")
  //   this.toggleSidebar();
  // }

  toggleSidebar() {
    this.setState({isSidebarOpen: !this.state.isSidebarOpen});
  }

  render() {
    return (
      <div className='refer-friend'>
        <div className='refer-friend-container'>
          <div className='refer-friend-text-container'>
            <div className='refer-friend-title'>
              Refer your friends
            </div>
            <div className='refer-friend-text'>
              Have friends looking for jobs? If you are the first person to refer them to Symbol and they get hired, you both get $500.
            </div>
          </div>
          <div className='refer-friend-button-container'>
            <button onClick={this.toggleSidebar} className='small-button'>Refer friends →</button>
          </div>
        </div>

        <ReferSidebar
          toggleSidebar={this.toggleSidebar}
          isSidebarOpen={this.state.isSidebarOpen}
        />
      </div>
  )}
}

export default ReferFriend;
