import React from 'react'

const SideBarX = props => (
  <svg width={11} height={11} {...props}>
    <path
      d="M5.5 5.5l4.596 4.596L5.5 5.5 10.096.904 5.5 5.5zm0 0L.904 10.096 5.5 5.5.904.904 5.5 5.5z"
      stroke="#000"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="square"
    />
  </svg>
)

export default SideBarX
