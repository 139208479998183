import React, { Component } from 'react';
import './App.css';
import HomePage from './components/homepage/homepage.js';
import Jobs from './components/homepage/jobs.js';
import HomePageEmployers from './components/homepage/homepage-employers.js';
import EmployerCreateAccount from './components/homepage/employer-create-account.js';
import {BrowserRouter as Router, Route, Link, Redirect, Switch } from 'react-router-dom'
import OnboardingStep1 from './components/profile/onboarding-step-1.js';
import OnboardingStep2 from './components/profile/onboarding-step-2.js';
import OnboardingStep3 from './components/profile/onboarding-step-3.js';
import Interview from './components/interview/interview.js';
import InterviewQuestion from './components/interview/interview-question.js';
import InterviewQuestionSingle from './components/interview/interview-question-single.js';
import InterviewQuestion2 from './components/interview/interview-question-2.js';
import InterviewQuestion3 from './components/interview/interview-question-3.js';
import InterviewQuestion4 from './components/interview/interview-question-4.js';
import { Auth } from "aws-amplify";
import Users from "./store/users.js";
import Backend from "./backend.js"
import EmployerApplicantSearch from './components/employer/employer-applicant-search.js';
import ApplicantProfile from './components/profile/applicant-profile.js';
import ReviewsPage from './components/reviews/reviews-page.js';
import ReviewLander from './components/reviews/review-lander.js';
import ScrollToTop from './components/basics/scroll-to-top.js'
import InterviewQuestions from './data/interview-questions.js';
import { hotjar } from 'react-hotjar';

class App extends Component {

  constructor(props) {
      super(props);
      if (!Object.entries) {
        Object.entries = function( obj ){
          var ownProps = Object.keys( obj ),
              i = ownProps.length,
              resArray = new Array(i); // preallocate the Array
          while (i--)
            resArray[i] = [ownProps[i], obj[ownProps[i]]];

          return resArray;
        };
      }

      this.state = {
        isAuthenticated: false,
        isCompanyRepAuthenticated: false,
        isAuthenticating: true,
        userId: undefined,
      };
      Users.auth = Users.auth;
      console.log("hey wassup ", Users.auth)
      this.userHasAuthenticated = this.userHasAuthenticated.bind(this);
      this.companyRepHasAuthenticated = this.companyRepHasAuthenticated.bind(this);
  }

  initializeReactGA() {
    // ReactGA.initialize('UA-130841552-1');
    // ReactGA.pageview('/homepage');
  }

  userHasAuthenticated(authenticated, userId) {
    console.log("here authenticated: ", authenticated, userId)
    if (!authenticated) {
      console.log("setting the empty user ", Users.emptyUser)
      Users.auth = Users.emptyUser
      userId = ""
    } else {
      // just signed up or logged in.
      this.cleanEmptyFields(Users.auth);
    }
    console.log("auth now: ", Users.auth);
    //this.setState({})
    this.setState({isAuthenticated: authenticated, userId: userId, isAuthenticating: false});
  }

  companyRepHasAuthenticated(authenticated, userId) {
    console.log("here authenticated: ", authenticated, userId)
    if (!authenticated) {
      console.log("setting the empty user ", Users.emptyUser)
      Users.auth = Users.emptyUser
      userId = ""
    } else {
      // just signed up or logged in.
      this.cleanEmptyFields(Users.auth);
    }
    console.log("auth now: ", Users.auth);
    this.setState({isCompanyRepAuthenticated: authenticated, userId: userId, isAuthenticating: false});
  }

  async checkUserAuthorization(cognitoUser) {
    this.setState({isAuthenticating: true});
    try {
      let user = cognitoUser
      console.log("current authenticated user from cognito: ", user);
      let userId = user.username;
      console.log("FROM BEFORE: ", Users.auth)
      Users.auth["userId"] = userId;
      let session = user.signInUserSession;
      let fullUser = await Backend.fetchUser(userId);
      if (!fullUser) {
        console.log("RETURNING HEREEEE")
        return false;
      }

      Users.auth["username"] = fullUser.username;
      Users.auth["userId"] = fullUser.userId;
      Users.auth["email"] = fullUser.email;
      Users.auth["firstName"] = fullUser.firstName;
      this.cleanEmptyFields(fullUser);
      for (var property in fullUser) {
          if (fullUser.hasOwnProperty(property)) {
            Users.auth[property] = fullUser[property];
          }
      }

      if (session && !fullUser.companyName) {
        console.log("user authenticated!!");
        console.log("THE ACCESSTOKEN: ", session.accessToken);
        Users.auth["accessToken"] = session.accessToken
        //this.setState({isAuthenticated: true});
        console.log("getting here boii")
        this.userHasAuthenticated(true, userId);
        //this.setState({isAuthenticating: false});
        return true;
      }
    } catch (e) {
      console.log("error finding auth user: ", e.message);
      return false;
    }
    //this.setState({ isAuthenticating: false });
    return false;
  }

  async checkCompanyRepAuthorization(cognitoUser) {
    console.log("checking companyrep authorization");
    this.setState({isAuthenticating: true});
    try {
      let user = cognitoUser;
      console.log("current authenticated companyrep from cognito: ", user);
      let userId = user.username;
      console.log("FROM BEFORE: ", Users.auth)
      Users.auth["userId"] = userId;
      let session = user.signInUserSession;
      let fullUser = await Backend.fetchCompanyRep(userId);
      console.log("got full company rep ", fullUser);
      Users.auth["userId"] = fullUser.companyRepId;
      Users.auth["companyRepId"] = fullUser.companyRepId;
      Users.auth["email"] = fullUser.email;
      Users.auth["firstName"] = fullUser.firstName;
      this.cleanEmptyFields(fullUser);
      for (var property in fullUser) {
          if (fullUser.hasOwnProperty(property)) {
            Users.auth[property] = fullUser[property];
          }
      }

      if (session) {
        console.log("companyrep authenticated!!");
        console.log("THE ACCESSTOKEN: ", session.accessToken);
        Users.auth["accessToken"] = session.accessToken
        //this.setState({isCompanyRepAuthenticated: true});
        this.companyRepHasAuthenticated(true, userId);
      }
    } catch (e) {
      console.log("error finding auth user: ", e.message);
    }
    this.setState({ isAuthenticating: false });
  }

  initializeReactGA() {
      //ReactGA.initialize('UA-130841552-1');
  }

  cleanEmptyFields(userObject) {
    for (var property in userObject) {
        if (userObject.hasOwnProperty(property)) {
          if (typeof userObject[property] === 'object' && userObject[property] !== null) {
            this.cleanEmptyFields(userObject[property]);
          } else {
            if (userObject[property] == "_EMPTY_") {
              userObject[property] = "";
            }
          }
        }
    }
  }

  async componentDidMount() {
    console.log("Veryfirst: ", Users.auth);
    //this.setState({isAuthenticating: true});
    try {
      let user = await Auth.currentAuthenticatedUser();
      let authenticated = await this.checkUserAuthorization(user);
      if (!authenticated) {
        this.checkCompanyRepAuthorization(user);
      }
    } catch (e) {
      console.log("error authenticating user");
      this.setState({isAuthenticating: false});
    }

    //this.initializeReactGA();
    hotjar.initialize(1118992, 6);
  }

  render() {
    let authUserProfilePath = Users.auth.whereInFlow;
    console.log("THE PATH ", authUserProfilePath);
    if (!authUserProfilePath) {
      authUserProfilePath = "/onboarding/1"
    }
    if (Users.auth.companyRepId) {
      authUserProfilePath = Users.auth.hasCandidatesAccess ? "/candidates" : "/setup"
    }

    console.log("the state ", this.state)

    return (
        <Router>
          <ScrollToTop>
          {!this.state.isAuthenticating &&
            <div>

              <Switch>
                <Route exact path="/" render={(props) => this.state.isAuthenticated || this.state.isCompanyRepAuthenticated ?
                                            (<Redirect to={{pathname: authUserProfilePath}} />) :
                                            (<HomePage {...props} isAuthenticated={this.state.isAuthenticated} userHasAuthenticated={this.userHasAuthenticated} companyRepHasAuthenticated={this.companyRepHasAuthenticated} />) } />

                <Route exact path="/onboarding/1" render={(props) => this.state.isAuthenticated ?
                   (<OnboardingStep1 userHasAuthenticated={this.userHasAuthenticated} {...props}/>) :
                    (<Redirect to={{pathname: "/"}} />)  }/>

                <Route exact path="/onboarding/2" render={(props) => this.state.isAuthenticated ?
                  (<OnboardingStep2 userHasAuthenticated={this.userHasAuthenticated} {...props}/>) :
                  (<Redirect to={{pathname: "/"}} />)}/>

                <Route exact path="/onboarding/3" render={(props) => this.state.isAuthenticated ?
                  <OnboardingStep3 userHasAuthenticated={this.userHasAuthenticated} {...props}/> :
                  <Redirect to={{pathname: "/"}} /> }/>

                <Route exact path="/profile" render={(props) => this.state.isAuthenticated ?
                  <ApplicantProfile userHasAuthenticated={this.userHasAuthenticated} {...props} /> :
                  <Redirect to={{pathname: "/"}} /> }/>

                <Route exact path="/interview" render={(props) => this.state.isAuthenticated ?
                  <Interview userHasAuthenticated={this.userHasAuthenticated} {...props} /> :
                  <Redirect to={{pathname: "/"}} /> }/>

                <Route exact path="/interview/1" render={(props) => this.state.isAuthenticated ?
                  <InterviewQuestionSingle userHasAuthenticated={this.userHasAuthenticated} filler={InterviewQuestions["Role"]} {...props} /> :
                  <Redirect to={{pathname: "/"}} /> }/>

                <Route exact path="/interview/2" render={(props) => this.state.isAuthenticated ?
                  <InterviewQuestion userHasAuthenticated={this.userHasAuthenticated} filler={InterviewQuestions["Team Skills"]} {...props} /> :
                  <Redirect to={{pathname: "/"}} /> }/>
                <Route exact path="/interview/3" render={(props) => this.state.isAuthenticated ?
                  <InterviewQuestion userHasAuthenticated={this.userHasAuthenticated} filler={InterviewQuestions["Execution Abilities"]} {...props} /> :
                  <Redirect to={{pathname: "/"}} />}/>
                <Route exact path="/interview/4" render={(props) => this.state.isAuthenticated ?
                  <InterviewQuestion userHasAuthenticated={this.userHasAuthenticated} filler={InterviewQuestions["Adaptability"]} {...props} /> :
                  <Redirect to={{pathname: "/"}} />}/>
                <Route exact path="/boost" render={(props) => this.state.isAuthenticated ? <ReviewLander userHasAuthenticated={this.userHasAuthenticated} {...props} /> :
                  <Redirect to={{pathname: "/"}} />}/>
                <Route exact path="/reviews" render={(props) => this.state.isAuthenticated ? <ReviewsPage userHasAuthenticated={this.userHasAuthenticated} {...props} /> :
                  <Redirect to={{pathname: "/"}} />}/>


                <Route exact path="/candidates" render={(props) => this.state.isCompanyRepAuthenticated && Users.auth.hasCandidatesAccess ?
                  <EmployerApplicantSearch userHasAuthenticated={this.companyRepHasAuthenticated} {...props} />  :
                  <Redirect to={{pathname: "/"}} /> }/>

                <Route exact path="/candidates/:candidateUsername" render={(props) => this.state.isCompanyRepAuthenticated && Users.auth.hasCandidatesAccess ?
                  <EmployerApplicantSearch userHasAuthenticated={this.companyRepHasAuthenticated} {...props} />  :
                  <Redirect to={{pathname: "/"}} /> }/>

                <Route exact path="/employers" render={(props) => <HomePageEmployers userHasAuthenticated={this.userHasAuthenticated} companyRepHasAuthenticated={this.companyRepHasAuthenticated} {...props}/> }/>

                <Route exact path="/setup" render={(props) => this.state.isCompanyRepAuthenticated ? <EmployerCreateAccount userHasAuthenticated={this.companyRepHasAuthenticated} {...props} /> :
                <Redirect to={{pathname: "/"}} />}/>

                <Route render={(props) => <Redirect to={{pathname: "/"}} /> } />
              </Switch>
            </div>
          }
          </ScrollToTop>
        </Router>
      );
    }
  }

export default App;
