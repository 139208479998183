import React, { Component } from 'react';
import './skill-block.css';


class SkillBlock extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {

    let percentile = 50;
    if (this.props.interviewResponse && this.props.interviewResponse.displayBucket) {
      percentile = this.props.interviewResponse.displayBucket;
    }
    let percentileDisplay = percentile.toString() + "%";

    return (
      <div className='skill-block'>
        <div className='skill-block-name'>
          {this.props.interviewResponse.skillName}
        </div>
        <div className='skill-block-display-score'>
          <div className='skill-block-display-score-bar-container'>
            <div className='skill-block-display-score-bar-background'>
              <div style={{width: percentileDisplay }} className='skill-block-display-score-bar-percentile'>
              </div>
            </div>
            <div className='skill-block-display-score-label'>
              {percentile}
            </div>

          </div>
        </div>
      </div>
  )}
}

export default SkillBlock;
