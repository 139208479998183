import React, { Component } from 'react';
import {BrowserRouter as Router, Route, Link, withRouter} from 'react-router-dom';
import './onboarding-steps.css';
import './custom-form.css';
import ReactDOM from 'react-dom';
import {Helmet} from "react-helmet";
import HomepageLogo from '../../svg/homepage-logo.js';
import MultiSelect from './multi-select-symbol.js';
import TextareaAutosize from 'react-autosize-textarea';
import TrashIcon from '../../svg/trash-icon.js';
import Backend from "../../backend.js";
import AnimatingSpinnerButton from '../../svg/animating-spinner-button.js';
import EditProfilePhotoCamera from '../../svg/edit-profile-photo-camera.js';
import LightHeader from '../basics/light-header.js';
import Users from "../../store/users.js";
//import ReactGA from 'react-ga';

class OnboardingStep2 extends Component {

  constructor(props) {
    super(props);

    let workExperiences = [{
                        "companyName": "",
                        "title": "",
                        "startDate": {"month": "", "year": ""},
                        "endDate": {"month": "", "year": ""},
                        }];



    let colleges = [{"collegeName": "", "degree": "", "graduationYear": ""}];

    let skills = {
                      "asian": {"label": "Asian", "selected": false},
                      "black": {"label": "Black / African origin", "selected": false},
                      "white": {"label": "White", "selected": false},
                      "latinx": {"label": "Latinx / Hispanic", "selected": false},
                      "native-american": {"label": "Native American", "selected": false},
                      "pacific-islander": {"label": "Pacific Islander", "selected": false},
                      "middle-eastern": {"label": "Middle Eastern", "selected": false},
                      "other": {"label": "Other", "selected": false},

                      }

    this.state = {
      user: Users.auth,
      isProfilePhotoLoading: false,
      skills: skills,
    };

    this.toggleLocationOption = this.toggleLocationOption.bind(this);
    this.addWorkExperience = this.addWorkExperience.bind(this);
    this.deleteWorkExperience = this.deleteWorkExperience.bind(this);
    this.deleteCollege = this.deleteCollege.bind(this);
    this.handleCompanyNameChange = this.handleCompanyNameChange.bind(this);
    this.handleEndDateMonthChange = this.handleEndDateMonthChange.bind(this);
    this.handleEndDateYearChange = this.handleEndDateYearChange.bind(this);
    this.handleStartDateMonthChange = this.handleStartDateMonthChange.bind(this);
    this.handleStartDateYearChange = this.handleStartDateYearChange.bind(this);
    this.handleDegreeChange = this.handleDegreeChange.bind(this);
    this.handleCollegeNameChange = this.handleCollegeNameChange.bind(this);
    this.handleGraduationYearChange = this.handleGraduationYearChange.bind(this);
    this.addCollege = this.addCollege.bind(this);

    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleShortBlurbChange = this.handleShortBlurbChange.bind(this);
    this.handlePersonalWebsiteChange = this.handlePersonalWebsiteChange.bind(this);
    this.nextStepClick = this.nextStepClick.bind(this);
    this.goBackClick = this.goBackClick.bind(this);
    this.onPhotoSelected = this.onPhotoSelected.bind(this);

    this.toggleSkillOption = this.toggleSkillOption.bind(this);

  }

  async componentDidMount() {
    console.log("doing thiss mount")
    console.log("auth user is: ", Users.auth)
  //  ReactGA.pageview('/onboarding2');
    // if (!this.state.user.workExperiences || this.state.user.workExperiences.length == 0) {
    //   this.addWorkExperience();
    // }
    //
    // if (!this.state.user.colleges || this.state.user.colleges.length == 0) {
    //   this.addCollege();
    // }

  }

  loadSelectedForOptions(allOptions, optionsSaved) {
    for (var option in allOptions) {
      var optionObj = allOptions[option]
        if (optionsSaved.indexOf(optionObj.label) > -1) {
          optionObj.selected = true;
        }
    }
    return allOptions;
  }

  getSelectedOptions(options) {
      let optionsArray = Object.keys(options).filter(option => options[option].selected);
      options = optionsArray.map(option => options[option].label);
      return options;
  }

  validateForm() {
    if (!this.state.user.firstName || this.state.user.firstName=='' || this.state.user.firstName=='_EMPTY_') {
      console.log('1')
      return false
    }

    if (!this.state.user.lastName || this.state.user.lastName=='' || this.state.user.lastName=='_EMPTY_') {
      console.log('2')
      return false
    }

    // if (!this.state.user.phone || this.state.user.phone=='' || this.state.user.phone=='_EMPTY_') {
    //   console.log('3')
    //   return false
    // }

    if (!this.state.user.shortBlurb || this.state.user.shortBlurb=='' || this.state.user.shortBlurb=='_EMPTY_') {
      console.log('3')
      return false
    }

    console.log('4')
    return true
  }


  nextStepClick() {
    //Backend.saveProfileInfo({"hey": "you"});
    if (!this.validateForm()) {
      alert('Make sure you complete all required fields');
      return;
    }

    Users.auth["firstName"] = this.state.user.firstName;
    Users.auth["lastName"] = this.state.user.lastName;
    Users.auth["phone"] = this.state.user.phone;
    Users.auth["personalWebsite"] = this.state.user.personalWebsite;
    Users.auth["profilePhoto"] = this.state.user.profilePhoto;
    Users.auth["shortBlurb"] = this.state.user.shortBlurb;
    if (this.state.user.whereInFlow == "/onboarding/2") {
      Users.auth["whereInFlow"] = "/onboarding/3";
    }
    let nonEmptyWorkExperiences = this.state.user.workExperiences.filter(experience => experience.companyName.length > 1);

    // leave only one empty work experience so the default for next time is an empty form for a college and the user doesn't have to click "add another".
    // if (nonEmptyWorkExperiences.length == 0) {
    //   let emptyWorkExperience = {
    //                       "companyName": "",
    //                       "title": "",
    //                       "startDate": {"month": "", "year": ""},
    //                       "endDate": {"month": "", "year": ""},
    //                       "currentWork": false,
    //                       };
    //   nonEmptyWorkExperiences.push(emptyWorkExperience);
    // }

    Users.auth["workExperiences"] = nonEmptyWorkExperiences;

    let nonEmptyColleges = this.state.user.colleges.filter(college => college.collegeName.length > 1);
    // leave only one empty college so the default for next time is an empty form for a college and the user doesn't have to click "add another".
    // if (nonEmptyColleges.length == 0) {
    //   let emptyCollege = {
    //                       "collegeName": "",
    //                       "degree": "",
    //                       "graduationYear": "",
    //                       };
    //   nonEmptyColleges.push(emptyCollege);
    // }

    Users.auth["colleges"] = nonEmptyColleges;
    Backend.saveApplicantInfo(Users.auth);
    this.props.history.push("/onboarding/3");
  }

  goBackClick() {
    //Backend.saveProfileInfo({"hey": "you"});
    Users.auth["firstName"] = this.state.user.firstName;
    Users.auth["lastName"] = this.state.user.lastName;
    Users.auth["phone"] = this.state.user.phone;
    Users.auth["personalWebsite"] = this.state.user.personalWebsite;
    Users.auth["shortBlurb"] = this.state.user.shortBlurb;

    let nonEmptyWorkExperiences = this.state.user.workExperiences.filter(experience => experience.companyName.length > 1);

    // leave only one empty work experience so the default for next time is an empty form for a college and the user doesn't have to click "add another".
    // if (nonEmptyWorkExperiences.length == 0) {
    //   let emptyWorkExperience = {
    //                       "companyName": "",
    //                       "title": "",
    //                       "startDate": {"month": "", "year": ""},
    //                       "endDate": {"month": "", "year": ""},
    //                       };
    //   nonEmptyWorkExperiences.push(emptyWorkExperience);
    // }

    Users.auth["workExperiences"] = nonEmptyWorkExperiences;

    let nonEmptyColleges = this.state.user.colleges.filter(college => college.collegeName.length > 1);
    // leave only one empty college so the default for next time is an empty form for a college and the user doesn't have to click "add another".
    // if (nonEmptyColleges.length == 0) {
    //   let emptyCollege = {
    //                       "collegeName": "",
    //                       "degree": "",
    //                       "graduationYear": "",
    //                       };
    //   nonEmptyColleges.push(emptyCollege);
    // }

    Users.auth["colleges"] = nonEmptyColleges;
    Backend.saveApplicantInfo(Users.auth);
    this.props.history.push("/onboarding/1");
  }

  async onPhotoSelected(file) {
    file = file[0];
    console.log("photo selected: ", file);
    const userId = Users.auth["userId"];
    this.setState({isProfilePhotoLoading: true});
    let result = await Backend.uploadPhotoToCloudinary(file, userId, "profilePicture");
    console.log("result from upload: ", result);
    let secureUrl = result.secure_url;
    var user = {...this.state.user}
    user.profilePhoto = secureUrl;
    this.setState({user})
    //this.setState({profilePhoto: secureUrl});
    this.setState({isProfilePhotoLoading: false});
  }

  toggleLocationOption(value) {
    console.log("they togglin' ", value);
    let locations = Object.assign({}, this.state.user.locations);
    locations[value]["selected"] = !locations[value]["selected"];
    this.setState({locations: locations});
  }


  addCollege() {
    let colleges = JSON.parse(JSON.stringify(this.state.user.colleges));
    let emptyCollege = {
                        "collegeName": "",
                        "degree": "",
                        "graduationYear": "",
                        };
    colleges.push(emptyCollege);
    //var user = {...this.state.user}
    var user = JSON.parse(JSON.stringify(this.state.user));
    user.colleges = colleges
    this.setState({user: user});
  }

  deleteCollege(index, event) {
    // let colleges = JSON.parse(JSON.stringify(this.state.user.colleges));
    // colleges.splice(index, 1);
    // var user = {...this.state.user}
    // user.colleges = colleges
    // this.setState({user: user});
    let user = JSON.parse(JSON.stringify(this.state.user));
    user.colleges.splice(index, 1);
    this.setState({user: user});
  }

  handleDegreeChange(index, evt) {
    // let colleges = JSON.parse(JSON.stringify(this.state.user.colleges));
    // colleges[index]["degree"] = evt.target.value;
    // var user = {...this.state.user}
    // user.colleges = colleges
    // this.setState({user: user});
    let user = JSON.parse(JSON.stringify(this.state.user));
    user.colleges[index]["degree"] = evt.target.value;
    this.setState({user: user});
  }

  handleGraduationYearChange(index, evt) {
    let user = JSON.parse(JSON.stringify(this.state.user));
    user.colleges[index]["graduationYear"] = evt.target.value;
    this.setState({user: user});
  }

  handleCollegeNameChange(index, evt) {
    let colleges = JSON.parse(JSON.stringify(this.state.user.colleges));
    colleges[index]["collegeName"] = evt.target.value;
    var user = {...this.state.user}
    user.colleges = colleges
    this.setState({user: user});
  }

  addWorkExperience() {
    let workExperiences = Object.assign([], this.state.user.workExperiences);
    let emptyWorkExperience = {
                        "companyName": "",
                        "title": "",
                        "startDate": {"month": "", "year": ""},
                        "endDate": {"month": "", "year": ""},
                        };
    workExperiences.push(emptyWorkExperience);
    var user = {...this.state.user}
    user.workExperiences = workExperiences
    this.setState({user: user});
  }

  deleteWorkExperience(index, event) {
    let workExperiences = JSON.parse(JSON.stringify(this.state.user.workExperiences));
    workExperiences.splice(index, 1);
    var user = {...this.state.user}
    user.workExperiences = workExperiences
    this.setState({user: user});
  }

  handleCompanyNameChange(index, evt) {
    let workExperiences = JSON.parse(JSON.stringify(this.state.user.workExperiences));
    workExperiences[index]["companyName"] = evt.target.value;
    var user = {...this.state.user}
    user.workExperiences = workExperiences
    this.setState({user: user});
  }

  handleTitleChange(index, evt) {
    let workExperiences = JSON.parse(JSON.stringify(this.state.user.workExperiences));
    workExperiences[index]["title"] = evt.target.value;
    var user = {...this.state.user}
    user.workExperiences = workExperiences
    this.setState({user: user});
  }

  handleEndDateMonthChange(index, evt) {
    let workExperiences = JSON.parse(JSON.stringify(this.state.user.workExperiences));
    workExperiences[index]["endDate"]["month"] = evt.target.value;
    var user = {...this.state.user}
    user.workExperiences = workExperiences
    this.setState({user: user});
  }

  handleEndDateYearChange(index, evt) {
    let workExperiences = JSON.parse(JSON.stringify(this.state.user.workExperiences));
    workExperiences[index]["endDate"]["year"] = evt.target.value;
    var user = {...this.state.user}
    user.workExperiences = workExperiences
    this.setState({user: user});
  }

  handleStartDateMonthChange(index, evt) {
    let workExperiences = JSON.parse(JSON.stringify(this.state.user.workExperiences));
    workExperiences[index]["startDate"]["month"] = evt.target.value;
    var user = {...this.state.user}
    user.workExperiences = workExperiences
    this.setState({user: user});
  }

  handleStartDateYearChange(index, evt) {

    let workExperiences = JSON.parse(JSON.stringify(this.state.user.workExperiences));
    workExperiences[index]["startDate"]["year"] = evt.target.value;
    var user = {...this.state.user}
    user.workExperiences = workExperiences
    this.setState({user: user});
  }

  handleFirstNameChange(evt) {
    var user = {...this.state.user}
    user.firstName = evt.target.value;
    this.setState({user})
  }

  handleLastNameChange(evt) {
    var user = {...this.state.user}
    user.lastName = evt.target.value;
    this.setState({user})
  }

  handlePhoneChange(evt) {
    var user = {...this.state.user}
    user.phone = evt.target.value;
    this.setState({user})
  }

  handleShortBlurbChange(evt) {
    var user = {...this.state.user}
    user.shortBlurb = evt.target.value;
    this.setState({user})
  }

  handlePersonalWebsiteChange(evt) {
    var user = {...this.state.user}
    user.personalWebsite = evt.target.value;
    this.setState({user})
  }

  handleCurrentlyWork(index, evt) {
    console.log("currently work ", index, evt.target.checked);
    let user = JSON.parse(JSON.stringify(this.state.user));
    user.workExperiences[index]["currentWork"] = evt.target.checked;
    //console.log("heyy  ", workExperiences[index]["currentWork"]);
    //var user = {...this.state.user}
    //user.workExperiences = workExperiences
    this.setState({user: user});
  }

  toggleSkillOption(value) {
    let skills = Object.assign({}, this.state.skills);
    skills[value]["selected"] = !skills[value]["selected"];

    let skillsForDB = this.getSelectedOptions(skills);
    if (Users.auth.lookingForJobs) {
      Users.auth.lookingForJobs.skills = skillsForDB;
    } else {
      Users.auth.lookingForJobs = {"skills": skills};
    }
    this.setState({skills: skills});
  }


  render() {

    console.log('USER.AUTH',Users.auth)

    let loadedSkills = this.state.skills;
    if (Users.auth.lookingForJobs && Users.auth.lookingForJobs.skills) {
      loadedSkills = this.loadSelectedForOptions(this.state.skills, Users.auth.lookingForJobs.skills);
    }

    let workExperiences = []
    if (this.state.user.workExperiences) {
      workExperiences = this.state.user.workExperiences.map((experienceObject, index) => {
        return (
          <div className='work-section' key={index}>
            <div className='form-block-2'>
              <div className='form-left-half'>
                <div className='form-label'>Company Name</div>
                <input data-hj-whitelist className='half-input' placeholder='Write the name of the company' onChange={this.handleCompanyNameChange.bind(this, index)} value={experienceObject.companyName && experienceObject.companyName!=="_EMPTY_"? experienceObject.companyName:""} type="text"></input>
              </div>
              <div>
                <div className='form-label'>Title</div>
                <input data-hj-whitelist className='half-input' placeholder='Write your title at this company' onChange={this.handleTitleChange.bind(this, index)} value={experienceObject.title}  type="text"></input>
              </div>
              <div className='trash-icon-container' onClick={this.deleteWorkExperience.bind(this, index)}>
                <TrashIcon className='trash-icon'/>
              </div>
            </div>

            <div className='form-block-2'>
              <div className='form-left-half'>
                <div className='form-label'>Start Date</div>
                <select className='date-input' value={experienceObject.startDate.month} onChange={this.handleStartDateMonthChange.bind(this, index)}>
                  <option className="placeholder">Month</option>
                  <option value="January">January</option>
                  <option value="February">February</option>
                  <option value="March">March</option>
                  <option value="April">April</option>
                  <option value="May">May</option>
                  <option value="June">June</option>
                  <option value="July">July</option>
                  <option value="August">August</option>
                  <option value="September">September</option>
                  <option value="October">October</option>
                  <option value="November">November</option>
                  <option value="December">December</option>
                </select>
                <input data-hj-whitelist className='quarter-input' placeholder='Year' value={experienceObject.startDate.year} onChange={this.handleStartDateYearChange.bind(this, index)} type="text"/>
              </div>

              <div className={experienceObject.currentWork ? 'disabled' : ''}>
                <div className='form-label'>End Date</div>
                <select className='date-input' disabled={experienceObject.currentWork} value={experienceObject.endDate.month} onChange={this.handleEndDateMonthChange.bind(this, index)}>
                  <option className="placeholder">Month</option>
                  <option value="January">January</option>
                  <option value="February">February</option>
                  <option value="March">March</option>
                  <option value="April">April</option>
                  <option value="May">May</option>
                  <option value="June">June</option>
                  <option value="July">July</option>
                  <option value="August">August</option>
                  <option value="September">September</option>
                  <option value="October">October</option>
                  <option value="November">November</option>
                  <option value="December">December</option>
                </select>
                <input data-hj-whitelist className='quarter-input' disabled={experienceObject.currentWork} placeholder='Year' value={experienceObject.endDate.year} onChange={this.handleEndDateYearChange.bind(this, index)}  type="text"/>
              </div>
            </div>
            <div className='form-block'>

              <div className='radio-section' >
                <div className='radio-container'>
                  <label class="container">I currently work here
                    <input type="checkbox" onChange={this.handleCurrentlyWork.bind(this, index)} checked={experienceObject.currentWork}/>
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>

            </div>
          </div>
        );
      })
    }

    let colleges = [];
    if (this.state.user.colleges) {
      colleges = this.state.user.colleges.map((college, index) =>
      {
        return (
          <div className='college-section' key={index}>
            <div className='form-block-2'>
              <div className='form-left-half'>
                <div className='form-label'>University/College</div>
                <input data-hj-whitelist className='half-input' placeholder='Write the name of your university' value={college.collegeName} onChange={this.handleCollegeNameChange.bind(this, index)} type="text"></input>
              </div>
              <div>
              <div className='form-label'>Graduation Year</div>
              <input data-hj-whitelist className='quarter-input' placeholder='Year' value={college.graduationYear} onChange={this.handleGraduationYearChange.bind(this, index)} type="text"/>
              </div>
              <div className='trash-icon-container-college' onClick={this.deleteCollege.bind(this, index)}>
                <TrashIcon className='trash-icon'/>
              </div>
            </div>
            <div className='form-block-2'>
              <div className='form-left-half'>
                <div>
                  <div className='form-label'>Degree</div>
                  <input data-hj-whitelist className='half-input' placeholder='Example: B.A. in Philosophy' value={college.degree} onChange={this.handleDegreeChange.bind(this, index)} type="text"></input>
                </div>
              </div>
            </div>
          </div>
        )
      })
    }

    return (
      <div className='onboarding-step'>
        <Helmet>
          <title>Symbol - Get a job</title>
        </Helmet>
        <LightHeader userHasAuthenticated={this.props.userHasAuthenticated} />

        <div className='onboarding-step-number-container'>
          <div className='onboarding-step-number'>
            Step 2 of 3
          </div>
        </div>

        <div className='onboarding-form-container'>
          <div className='onboarding-form-body'>
            <div className='onboarding-form-body-container'>
              <div className='onboarding-form-body-container-inner'>
                <div className='onboarding-form-header'>
                  <div className='onboarding-form-header-title'>
                    Build Your Profile
                  </div>
                  <div className='onboarding-form-header-subtitle'>
                    STEP 2 - SHARE ABOUT YOU
                  </div>
                  <div className='onboarding-form-header-text'>
                    Companies want to know some basic info about you.
                  </div>
                </div>

                <form>
                  <div className='form-title'>
                    Personal Info
                  </div>

                  <div className='edit-profile-modal-profile-photo'>
                      {this.state.isProfilePhotoLoading ?
                        <div className='edit-profile-modal-profile-picture'>
                          <AnimatingSpinnerButton/>
                        </div>
                        :
                        <div className='edit-profile-modal-profile-picture'>
                          {this.state.user.profilePhoto ?
                            <div className='edit-profile-modal-profile-photo-exists'>
                              <img src={this.state.user.profilePhoto}/>
                              <input className='edit-profile-modal-profile-photo-upload'
                                    type="file"
                                    id="fileupload"
                                    accept="image/*"
                                    ref={fileInputEl =>
                                        (this.fileInputEl = fileInputEl)
                                    }
                                    onChange={() =>this.onPhotoSelected(this.fileInputEl.files)}/>
                            </div>
                            :
                            <div className='edit-profile-modal-profile-photo-doesnt-exists'>
                              <input className='edit-profile-modal-profile-photo-upload'
                                  type="file"
                                  id="fileupload"
                                  accept="image/*"
                                  ref={fileInputEl =>
                                      (this.fileInputEl = fileInputEl)
                                  }
                                  onChange={() =>
                                      this.onPhotoSelected(
                                          this.fileInputEl.files
                                      )
                                  }
                              />
                              <div className='edit-profile-modal-profile-photo-overlay'>
                                <div className='edit-profile-modal-profile-photo-overlay-message'>
                                  <div className='edit-profile-modal-profile-photo-overlay-message-icon'>
                                    <EditProfilePhotoCamera />
                                  </div>
                                  <div className='edit-profile-modal-profile-photo-overlay-message-text'>

                                  </div>
                                </div>
                              </div>
                            </div>
                          }
                        </div>
                      }
                      <div className='form-label-secondary-image'>Add a profile photo. Profiles with photos are invited to onsite interviews 2x more often.</div>

                    </div>


                  <div className='form-block-2'>
                    <div className='form-left-half'>
                      <div className='form-label'>First Name</div>
                      <input data-hj-whitelist className='half-input' placeholder='Write your first name here' value={this.state.user.firstName} onChange={this.handleFirstNameChange} type="text"></input>
                    </div>
                    <div>
                      <div className='form-label'>Last Name</div>
                      <input data-hj-whitelist className='half-input' placeholder='Write your last name here' value={this.state.user.lastName} onChange={this.handleLastNameChange} type="text"></input>
                    </div>
                  </div>


                  <div className='form-block'>
                    <div className='form-label'>Mini-resume (160 characters)</div>
                    <div className='form-label-secondary'>This is the first thing companies will see about you.<br/>Use this space to summarize your experience and interests.</div>
                    <div>
                      <TextareaAutosize
                        data-hj-whitelist
                        maxRows={5}
                        rows={3}
                        placeholder={"e.g. Head of Growth @Stripe, Former product manager @Apple, Applied Math & Economics @Brown. Lover of great design. Jazz percussionist, audiobook junkie, and runner."}
                        value={this.state.user.shortBlurb && this.state.user.shortBlurb!=="_EMPTY_" ? this.state.user.shortBlurb : ""}
                        onChange={this.handleShortBlurbChange}
                        className={'text-area'}
                        maxLength={160}
                      />
                    </div>
                  </div>

                  <div className='form-divider'></div>
                  <div className='form-title'>
                    Work Experience
                  </div>
                  <div className='form-label-secondary-space'>We will hide your profile from current and past employers.</div>

                  {workExperiences}
                  <div className='add-another-button' onClick={this.addWorkExperience}>Add another</div>

                  <div className='form-title'>
                    Education
                  </div>
                  {colleges}
                  <div className='add-another-button' onClick={this.addCollege}>Add another</div>
                  </form>
                <div className='onboarding-form-buttons-area'>
                  <div className='step-buttons-container'>
                    <div className='go-back-button' onClick={this.goBackClick}>Go back</div>
                    <button className='small-button' onClick={this.nextStepClick}>{this.state.user.whereInFlow=='/profile'?"Save and Next":"Next Step"}</button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
    </div>
  )}
}

export default withRouter(OnboardingStep2);
