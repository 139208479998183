import React from 'react';

let Faqs = [

  {
    "question": "1 - Why do I become more visible to companies after completing reviews?",
    "answer": <div>Our job at Symbol is to identify the most talented candidates and show these candidates to companies. We've found that one of the clearest indicators of a candidate's talent is their ability to recognize other talented individuals.<br/><br/>Therefore, to demonstrate your talent and boost your visibility to companies, you can demonstrate your 'talent recognition' abilities by reviewing anonymous interview responses by other candidates.<br/><br/>We check to see if your assessments match the assessments of industry experts and top hiring managers, and if so, we'll boost your profile to companies.</div>
  },

  {
    "question": "2 - How do reviews work?",
    "answer": <div>We will show you two anonymous candidates at a time and their answers to the same set of interview questions. Your job is to review the answers of both candidates and decide which candidate demonstrates more expertise based on their answers. Once you make your selection, we'll show you two new candidates.
    <br/><br/>
    For each comparison, we'll increase your Symbol hiring bonus by $10 (up to $3,000).</div>
  },


  {
    "question": "3 - Who is reviewing my interview answers?",
    "answer": <div>Once you submit your interview answers, they will be reviewed by industry experts, top hiring managers, and (in some cases) other candidates. Your answers are always reviewed anonymously, which means we never show your name, photo, or other information to reviewers.
    <br/><br/>
This ensures that everyone is reviewed unbiasedly.</div>
  },

  {
    "question": "4 - How do I get my hiring bonus?",
    "answer": <div>If you get hired by any company who connects with you via Symbol, we send you your hiring bonus, which starts at $500 and can be increased up to $3,000 by completing reviews. If you don't get hired through Symbol, or if you get hired, but not through a process we facilitate, we do not pay you the Symbol hiring bonus.</div>
  },

]

export default Faqs;
