import React, { Component } from 'react';
import './reviews-page.css';
import HeaderBar from './header-bar.js';
import ProjectsPreviewSection from './projects-preview-section.js';
import AnimatingSpinnerBig from '../../svg/animating-spinner-big.js';
import ReviewMenu from './review-menu.js';
import Backend from "../../backend.js";
import GenericModal from '../modal/generic-modal.js';
import {Helmet} from "react-helmet";
import Users from "../../store/users.js";

const TOTAL_STARTING_NUMREVIEWS = 25;

class ReviewsPage extends Component {
  constructor(props) {
      super(props);
      this.state = {
        isSearchForNextReview: false,
        responseA: {},
        responseB: {},
        skillName: "",
        targetSkill: undefined,
        searchMessage: "",
        totalSkillsToQuantify: 0,
        reviewQueue: [],
        currentBatchNum: 1,
        allNumBatches: 1,
        skillsWithNoMoreProjects: {},
        placeInReviewQueue: 0,
        caseStudyTitleContributing: "",
        caseStudySkillContributing: "",
        contributedPart: false,
        doingOtherSkills: false,
        extraReviewsMade: 0,
        hasHiddenReviewMenu: false,
        isReviewMenuOpen: true,
        timeBeforeSubmitGood: false,
        isFinishedContributingModalOpen: false,
        isExhaustedSkillButNeedsMoreModalOpen: false,
        isFinishedSkillContributingModalOpen: false,
        isExhaustedAllSkillsInPlatformModalOpen: false,
        isLowQualityReviewModalOpen: false,
        noMoreCaseStudies: false,
        hiringBonus: Users.auth.hiringBonus
      };

    // if (!this.props.isAuthenticated) {
    //     this.props.history.push('/login');
    // }
    this.handleComparison = this.handleComparison.bind(this);
    this.setResponses = this.setResponses.bind(this);
    this.toggleIsReviewMenuOpen = this.toggleIsReviewMenuOpen.bind(this);
    // this.toggleFinishedContributingModal = this.toggleFinishedContributingModal.bind(this);
    // this.toggleExhaustedSkillButNeedsMoreModal = this.toggleExhaustedSkillButNeedsMoreModal.bind(this);
    // this.toggleFinishedSkillContributingModal = this.toggleFinishedSkillContributingModal.bind(this);
    this.toggleExhaustedAllSkillsInPlatformModal = this.toggleExhaustedAllSkillsInPlatformModal.bind(this);
    this.toggleLowQualityReviewModal = this.toggleLowQualityReviewModal.bind(this);
    this.startSubmitTimer = this.startSubmitTimer.bind(this);
    this.findNextSkillToReview = this.findNextSkillToReview.bind(this);
    this.exhaustedAllSkillsInPlatform = this.exhaustedAllSkillsInPlatform.bind(this);
  }

  async componentDidMount() {
    console.log('FIRST',Users.auth)
    console.log('FIRST',Users)
    await this.fetchReviewerInfo();
  }

  startSubmitTimer() {
    this.setState({timeBeforeSubmitGood: false, hasHiddenReviewMenu: false});
    setTimeout(() => {
        console.log("TIME SHOULD BE GOOD NOW");
        this.setState({timeBeforeSubmitGood: true});
    }, 30000);
  }

  async fetchReviewerInfo() {
    this.showSearching(true, "Searching for the best interview responses for you to review");
    this.fetchFreshProjects();
  }

  async fetchFreshProjects() {
    this.showSearching(true, "Searching for the best interview responses for you to review");
    let targetSkill = "_EXTRA_"
    if (!Users.auth.userId) {
      console.log("undefined userid bro ");
      return;
    }
    let responses = await Backend.getTwoResponses(Users.auth.userId);

    if (responses.responseA && responses.responseB) {
      this.setState({skillName: responses.skillName});
      this.setResponses(responses.responseA, responses.responseB);
      this.showSearching(false, "");
      this.startSubmitTimer();
    } else {

      this.toggleExhaustedAllSkillsInPlatformModal();

      // new, just didn't find anymore
      console.log("didnt find any more responses");
    }
  }
  //
  findNextSkillToReview() {
    console.log("finding next skill to review");
    let reviewQueue = this.state.reviewQueue;
    // go through review queue for the highest-density skills within the user's case studies.
    for (let i = 0; i < reviewQueue.length; i++) {
      let skillName = reviewQueue[i]["skill"];
      let skillsWithNoMoreProjects = this.state.skillsWithNoMoreProjects;
      console.log("goig through reviewQueue: ", skillName);
      console.log("skillsWithNoMoreProjects: ", skillsWithNoMoreProjects);
      if (skillName in this.state.skillsWithNoMoreProjects) {
        // if we just went through whole review queue (we're on the last one) and all the skills were exhausted (had no more projects)
        //if (i == reviewQueue.length - 1) {
        // new: if there's no more projects in this skill, get from otherskills

        console.log("do something new here, remove this section.")
        ///this.getOtherSkillToReview();
        return;
        //}
      } else {
        let skills = reviewQueue[i]["project"]["skills"];
        let numRevsNeeded = skills[skillName]["ownerNumReqReviews"];
        // if the user still needs to contribute reviews to this case study in their review queue
        if (numRevsNeeded > 0) {
          //this.setState({placeInReviewQueue: i});
          //console.log("HEYYYY U")
          // let message = "heyy contributing to batch " + i;
          // alert(message);
          let caseStudyTitleContributing = this.state.reviewQueue[i]["project"]["projectTitle"];
          let caseStudySkillContributing = this.state.reviewQueue[i]["skill"];
          let targetSkill = "_EXTRA_"
          if (this.state.reviewQueue[i]) {
            targetSkill = this.state.reviewQueue[i]["project"]["skills"][caseStudySkillContributing]
          }

          this.setState({targetSkill: targetSkill});
          console.log("CONTRIBUTING TO ", caseStudySkillContributing, caseStudyTitleContributing);
          this.setState({caseStudySkillContributing: caseStudySkillContributing, caseStudyTitleContributing: caseStudyTitleContributing});
          this.setState({contributedPart: false});
          this.setState({skillName: skillName});
          this.setPercentFromReviewsNeeded(numRevsNeeded);
          this.fetchFreshProjects();
          return;
        }
      }
    }

    console.log("passed the review queue");
    // passed the review queue.

    if (this.state.contributedPart) {
      //this.setPercentFromReviewsNeeded(TOTAL_STARTING_NUMREVIEWS);
      this.getOtherSkillToReview();
      return;
    }

    // got to the end with no positive numrevs needed.
    console.log("finished contributing inside findNextSkillToReview");
    //this.toggleFinishedContributingModal();
    //this.fetchFreshProjects();
  }
  validateSubmission() {
    // if (!this.state.hasHiddenReviewMenu) {
    //   this.toggleLowQualityReviewModal();
    //   return false;
    // }
    if (!this.state.timeBeforeSubmitGood) {
      this.toggleLowQualityReviewModal();
      return false;
    }
    return true;
  }
  //
  async handleComparison(personSelected) {
    if (personSelected == null || personSelected == "") {
      return;
    }
    // uncomment this.
    if (!this.validateSubmission()) {
      return;
    }
    console.log("handling comparison broughh: ", personSelected)
    this.showSearching(true, "Submitting your review and searching for new responses");
    let reviewerId = Users.auth.userId// TODO: comment back Users.auth.userId //Store.authUserId;
    let skillIdentifier = this.state.skillName
    console.log("responseA: ", this.state.responseA);
    let betteruserId = personSelected === "A" ? this.state.responseA.userId : this.state.responseB.userId
    let otheruserId = personSelected === "A" ? this.state.responseB.userId : this.state.responseA.userId
    console.log("submitting: ", reviewerId, skillIdentifier, betteruserId, otheruserId);

    let result = await Backend.submitComparison(reviewerId, skillIdentifier, betteruserId, otheruserId, this.state.hiringBonus);
    this.showSearching(false, "");
    this.setState({"hiringBonus": result.newHiringBonus})
    Users.auth["hiringBonus"] = result.newHiringBonus;

    if (result.newResponses.responseA && result.newResponses.responseB) {
      this.setResponses(result.newResponses.responseA, result.newResponses.responseB);
      //this.showSearching(false, "");
      this.setState({skillName: result.newResponses.skillName});
      this.startSubmitTimer();
    } else {
      console.log("this one in EVER HERE?");
      console.log("did not find more responses");
      this.toggleExhaustedAllSkillsInPlatformModal();
      //let message = "this one. You've finished reviewing all available projects for " + this.state.skillName + ". Switching you to another one!";
      //this.toggleFinishedContributingModal();
      //alert(message);
      //this.toggleExhaustedSkillButNeedsMoreModal();
      //this.findNextSkillToReview();
    }
  }

  setResponses(responseA, responseB) {
    console.log("responses creators ", responseA.userId, responseB.userId);
    ///let responseA_clean = {"userId": responseA.userId, "description": responseA.part1Response,  "process": JSON.stringify(responseA.part2Response)}
    //let responseB_clean = {"userId": responseB.userId, "description": responseB.part1Response,  "process": JSON.stringify(responseB.part2Response)}
    this.setState({responseA: responseA, responseB: responseB});
  }

  showSearching(searching, message) {
    this.setState({isSearchForNextReview: searching, searchMessage: message});
  }
  //
  toggleIsReviewMenuOpen() {
    this.setState(state => ({
      isReviewMenuOpen: !state.isReviewMenuOpen
    }));
    if (!this.state.isReviewMenuOpen) {
      this.setState({hasHiddenReviewMenu: true});
    }
  }

  toggleExhaustedAllSkillsInPlatformModal() {
    this.setState(state => ({
      isExhaustedAllSkillsInPlatformModalOpen: !state.isExhaustedAllSkillsInPlatformModalOpen
    }));

    if (this.state.isExhaustedAllSkillsInPlatformModalOpen) {
      this.setState({noMoreCaseStudies: true, isFinishedSkillContributingModalOpen: false, isFinishedContributingModalOpen: false, isExhaustedSkillButNeedsMoreModalOpen: false});
    }
  }
  //
  toggleLowQualityReviewModal() {
    this.setState(state => ({
      isLowQualityReviewModalOpen: !state.isLowQualityReviewModalOpen
    }));
  }
  //
  //
  exhaustedAllSkillsInPlatform() {
    // save in db we should notify this person when there's more case studies available.
    this.props.history.push('/');
  }

  render() {
    console.log("we got these candidates ", this.state.responseA.userId, this.state.responseB.userId)
    return (
      <div className="reviews-page">
        <Helmet>
          <title>Peer Reviews | Symbol</title>
        </Helmet>
        <HeaderBar skillToReview={this.state.skillName}/>
        <ProjectsPreviewSection responseA={this.state.responseA} responseB={this.state.responseB} skillToReview={this.state.skillName}/>
        <div className='searching-overlay' style={{opacity: this.state.isSearchForNextReview? '0.9': '0', visibility:this.state.isSearchForNextReview? 'visible': 'hidden'}}>
          <div className='searching-container'>
            <div className='searching-container-vertical-center'>
              <div className='searching-message-container'>
                <div className='searching-message'>
                  {this.state.searchMessage}
                </div>
              </div>
              <div className='searching-message-container'>
                <AnimatingSpinnerBig />
              </div>
            </div>
          </div>
        </div>
        <ReviewMenu
          hiringBonus={this.state.hiringBonus}
          caseStudySkillContributing={this.state.caseStudySkillContributing}
          caseStudyTitleContributing={this.state.caseStudyTitleContributing}
          currentBatchNum={this.state.currentBatchNum}
          allNumBatches={this.state.allNumBatches}
          handleComparison={this.handleComparison}
          hasRequiredReviews={!this.state.contributedPart}
          isReviewMenuOpen={this.state.isReviewMenuOpen}
          toggleIsReviewMenuOpen={this.toggleIsReviewMenuOpen}
          skillToReview={this.state.skillName}
          percentReviewsCompleted={this.state.percentReviewsCompleted}
          history={this.props.history}
          />

          {/* 1. FINISHED CONTRIBUTING BUT CAN CONTINUE EARNING INFLUENCE MODAL */}
          <GenericModal
             isModalOpen={this.state.isFinishedContributingModalOpen}
             toggleModal={this.toggleFinishedContributingModal}
             xDoesPrimaryAction={true}
             title={"Nice! Keep going?"}
             message={<div>
               <p>{"Nice job - You've finished submitting all of your required reviews!"}</p>
               <p>{"You can continue submitting reviews to boost your Reviews Score, to contribute to the community, or to get inspiration from other people's responses."}</p>
               </div>}
             primaryActionLabel={'Okay'}
             primaryAction={this.findNextSkillToReview}
          />

          {/* 2. ExhaustedSkillButNeedsMore reviews MODAL */}
          <GenericModal
             isModalOpen={this.state.isExhaustedSkillButNeedsMoreModalOpen}
             toggleModal={this.toggleExhaustedSkillButNeedsMoreModal}
             xDoesPrimaryAction={true}
             title={"Well Done!"}
             message={<div>
               <p>You've reviewed all case studies available for {this.state.skillName}, so we'll switch to another skill.</p>
               <p>{"All the peer reviews you do count towards getting your skills scored."}</p>
               </div>}
             primaryActionLabel={'Okay'}
             primaryAction={this.findNextSkillToReview}
          />

          {/* 3. FinishedSkillContributing MODAL */}
          <GenericModal
             isModalOpen={this.state.isFinishedSkillContributingModalOpen}
             toggleModal={this.toggleFinishedSkillContributingModal}
             title={"Nice job!"}
             xDoesPrimaryAction={true}
             message={<div>
               <p>You have contributed enough reviews to score your "{this.state.caseStudyTitleContributing}" case study.</p>
               <p>{"Let's get your other case study skills scored."}</p>
               </div>}
             primaryActionLabel={"Let's do it"}
             primaryAction={this.findNextSkillToReview}
          />

          {/* ExhaustedAllSkillsInPlatform MODAL */}
          <GenericModal
             isModalOpen={this.state.isExhaustedAllSkillsInPlatformModalOpen}
             toggleModal={this.toggleExhaustedAllSkillsInPlatformModal}
             title={"You're good for now"}
             xDoesPrimaryAction={true}
             message={<div>
               <p>{"There aren't any more available interview responses for you to review right now."}</p>
               <p>{"Come back later to continue your reviews."}</p>
               </div>}
             primaryActionLabel={"Okay"}
             primaryAction={this.exhaustedAllSkillsInPlatform}
          />

          {/* LowQualityReview MODAL */}
          <GenericModal
             isModalOpen={this.state.isLowQualityReviewModalOpen}
             toggleModal={this.toggleLowQualityReviewModal}
             title={"Good things take time"}
             xDoesPrimaryAction={true}
             message={<div>
               <p>Make sure you've taken the time necessary to look at both responses and make a thoughtful comparison.<br/><br/>High quality responses will help us better recognize your talent and show you to more companies.</p>
               </div>}
             primaryActionLabel={"Okay"}

          />
      </div>
    );
  }
}

export default ReviewsPage;
