import React, { Component } from 'react';
import {BrowserRouter as Router, Route, Link, withRouter} from 'react-router-dom';
import './applicant-profile.css';
import '../profile/onboarding-steps.css';
import ReactDOM from 'react-dom';
import {Helmet} from "react-helmet";
import HomepageLogo from '../../svg/homepage-logo.js';
import ApplicantCard from '../applicant/applicant-card.js';
import ApplicantInterviewCard from '../applicant/applicant-interview-card.js';
import DarkHeader from '../basics/dark-header.js';
import BoostProfile from './boost-profile.js';
import ReferFriend from './refer-friend.js';
import Users from "../../store/users.js";

class ApplicantProfile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      user: Users.auth,
      emptyUser: Users.emptyUser
    };
  }

  render() {

    let interviewResponses = []
    if (this.state.user && this.state.user.interviewResponses) {
      interviewResponses = Object.keys(this.state.user.interviewResponses).map(responseKey => {
        let interviewResponse = this.state.user.interviewResponses[responseKey];
        return (
          <div className='applicant-profile-cards-container' key={interviewResponse.skillName}>
            <ApplicantInterviewCard
              user={this.state.user}
              interviewResponse={interviewResponse}
              viewedByCompany={false}
            />
          </div>
        )
      });
    }
    return (
      <div className='applicant-profile'>
        <Helmet>
          <title>Your profile - Symbol</title>
        </Helmet>
        <div className='background'></div>
        <DarkHeader userHasAuthenticated={this.props.userHasAuthenticated} />
        <BoostProfile
          history={this.props.history}
        />
        <div className='applicant-profile-cards'>
          <div className='applicant-profile-cards-container'>
            <ApplicantCard
              user={this.state.user}
              viewedByCompany={false}
              history={this.props.history}
            />
          </div>
          {interviewResponses}
      </div>
      <ReferFriend
        history={this.props.history}
      />
    </div>
  )}
}

export default withRouter(ApplicantProfile);
