import React, { Component } from 'react';
import './applicant-interview-card.css';
import SkillBlock from './skill-block.js';
import BlueLinkIcon from '../../svg/blue-link-icon.js';

class ApplicantInterviewCard extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <div className='applicant-interview-card'>
        <div className='applicant-interview-card-container'>
          <div className='applicant-interview-card-title-container'>
            <div className='applicant-interview-card-title'>
              {this.props.interviewResponse.skillName + " Interview"}
            </div>
            {this.props.viewedByCompany &&
            <div className='applicant-card-interview-performance-container'>
              <SkillBlock
                interviewResponse={this.props.interviewResponse}
              />
            </div>
            }
          </div>

          <div className='applicant-interview-card-question-container'>
            <div className='applicant-interview-card-question'>
              {this.props.interviewResponse.firstPrompt}
            </div>
            <div className='applicant-interview-card-answer'>
              {this.props.interviewResponse.firstAnswer}
            </div>
          </div>

        </div>
      </div>
  )}
}

export default ApplicantInterviewCard;
