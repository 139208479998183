import React, { Component } from 'react';
import {BrowserRouter as Router, Route, Link, withRouter} from 'react-router-dom';
import './homepage.css';
import ReactDOM from 'react-dom';
import {Helmet} from "react-helmet";
import HomepageLogo from '../../svg/homepage-logo.js';
import CreateAccountModal from './create-account-modal.js';
import LogInModal from './log-in-modal.js';
import Users from '../../store/users.js';

class HomePage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isCreateAccountModalOpen: false,
      isLogInModalOpen: false,
      hasScrolled: false,
    };

    this.toggleCreateAccountModal = this.toggleCreateAccountModal.bind(this);
    this.toggleLogInModal = this.toggleLogInModal.bind(this);
    //this.gaTrigger = this.gaTrigger.bind(this);
    this.myRef = React.createRef();
  }


  componentDidMount() {
    //ReactGA.pageview('/homepage');
  }

  toggleCreateAccountModal() {
    this.setState(state => ({
      isCreateAccountModalOpen: !state.isCreateAccountModalOpen
    }));
  }

  // gaTrigger(actionName) {
  //     // ReactGA.event({
  //     //     category: 'User',
  //     //     action: actionName
  //     // });
  // }

  toggleLogInModal() {
    this.setState(state => ({
      isLogInModalOpen: !state.isLogInModalOpen
    }));
  }

  render() {
    return (
      <div className='homepage'>
        <Helmet>
          <title>Symbol - Get a job</title>
        </Helmet>

        <div className='homepage-hero-section'>
          <div className='homepage-hero-top-bar'>
            <div className='homepage-hero-top-bar-container'>
              <div className='homepage-logo'>
                <div onClick={() => this.props.history.push("/")} className='homepage-logo-container'>
                  <HomepageLogo />
                </div>
                <div className='homepage-logo-text-container'>
                  symbol
                </div>
              </div>

                <div className='homepage-links-container'>
                  <a className='hide-on-mobile' onClick={() =>
                  this.props.history.push("/employers")}>For employers</a>
                  <a className='hide-on-mobile' onClick={this.toggleCreateAccountModal}>Explore jobs</a>
                  <a onClick={this.toggleLogInModal}>Log in</a>
                  <a onClick={this.toggleCreateAccountModal}>Sign up</a>
                </div>

            </div>
          </div>
          <div className='homepage-hero-main-section'>
            <div className='homepage-hero-main-section-container'>
              <div className='homepage-center-container'>
                <div className='homepage-hero-headline'>
                  Get job offers faster.
                </div>
              </div>
              <div className='homepage-center-container'>
                <div className='homepage-hero-body-text'>
                  {"Complete one first round interview for 200+ top companies. Get invited to onsite interviews in less than 3 business days."}
                </div>
              </div>
              <div className='homepage-center-container'>
                <button  className='large-button'  onClick={this.toggleCreateAccountModal}>
                  Get started now →
                </button>
              </div>

            </div>
          </div>
        </div>

        <div className='homepage-companies-section'>
          <div className='homepage-companies-section-title'>
             BE SEEN BY 200+ OF THE BEST COMPANIES
          </div>
          <div className='homepage-companies-section-logos-container'>
            <div className='homepage-companies-section-logo-container'>
              <div className='homepage-company-logo'>
                <img src='https://uploads-ssl.webflow.com/5be8cea3dc46203a5ad06bda/5be8ceca8afd197c8ef3dae6_logo1.png'/>
              </div>
              <div className='homepage-company-logo'>
                <img src='https://uploads-ssl.webflow.com/5be8cea3dc46203a5ad06bda/5be8cecaaaba7a3e1eec848f_logo2.png'/>
              </div>
              <div className='homepage-company-logo'>
                <img src='https://uploads-ssl.webflow.com/5be8cea3dc46203a5ad06bda/5be8ceca0c11cfab9cb469a6_logo3.png'/>
              </div>
              <div className='homepage-company-logo'>
                <img src='https://uploads-ssl.webflow.com/5be8cea3dc46203a5ad06bda/5be8ceca8afd1978e0f3dae7_logo4.png'/>
              </div>
              <div className='homepage-company-logo'>
                <img src='https://uploads-ssl.webflow.com/5be8cea3dc46203a5ad06bda/5be8cecaf904b33b279c41d6_logo5.png'/>
              </div>
            </div>

          </div>

          <div className='homepage-jobs-bar'>
            <div className='homepage-jobs-bar-container'>
              <div className='homepage-jobs-bar-role'>
                Product Management
              </div>
              <div className='homepage-jobs-bar-role'>
                Design
              </div>
              <div className='homepage-jobs-bar-role'>
                Software Engineering
              </div>
              <div className='homepage-jobs-bar-role'>
                Sales
              </div>
              <div className='homepage-jobs-bar-role'>
                Data Science
              </div>
              <div className='homepage-jobs-bar-role'>
                Marketing
              </div>
              <div onClick={this.toggleCreateAccountModal} className='homepage-jobs-bar-role-link'>
                Explore all roles →
              </div>

            </div>

          </div>

        </div>

        <div className='homepage-how-it-works-section'>
          <div className='homepage-how-it-works-title'>
            How it works
          </div>

          {/*DESKTOP*/}

          <div className='homepage-how-it-works-timeline'>
            <div className='homepage-how-it-works-timeline-container'>

              {/*STEP 1*/}
              <div className='homepage-how-it-works-step'>
                <div className='homepage-how-it-works-timeline-step-column-1'>
                  <div className='homepage-how-it-works-timeline-image-left'>
                    <img src='https://uploads-ssl.webflow.com/5be8cea3dc46203a5ad06bda/5be9e59ffc16dcb2b0b35c1c_HIW1.png'/>
                  </div>
                </div>

                <div className='homepage-how-it-works-timeline-step-column-2'>
                  <div className='homepage-how-it-works-timeline-step-number'>
                    STEP 1
                  </div>
                  <div className='homepage-how-it-works-timeline-vertical-line'>
                  </div>
                </div>

                <div className='homepage-how-it-works-timeline-step-column-3'>
                  <div className='homepage-how-it-works-timeline-text-right'>
                    <div className='homepage-how-it-works-timeline-title'>
                      Create a profile
                    </div>
                    <div className='homepage-how-it-works-timeline-body'>
                      Tell us about you and what kinds of jobs you’re looking for. We'll make sure you're seen by the best companies based on your preferences.
                    </div>
                  </div>
                </div>
              </div>

              {/*STEP 2*/}
              <div className='homepage-how-it-works-step'>
                <div className='homepage-how-it-works-timeline-step-column-1'>
                  <div className='homepage-how-it-works-timeline-text-left'>
                    <div className='homepage-how-it-works-timeline-title'>
                      Get a guaranteed interview
                    </div>
                  </div>
                  <div className='homepage-how-it-works-timeline-text-left'>
                    <div className='homepage-how-it-works-timeline-body'>
                      Complete your first round interview directly on Symbol. This one written interview is accepted by all of our partner companies and allows you to demonstrate your skills in detail.
                    </div>
                  </div>
                </div>

                <div className='homepage-how-it-works-timeline-step-column-2'>
                  <div className='homepage-how-it-works-timeline-step-number'>
                    STEP 2
                  </div>
                  <div className='homepage-how-it-works-timeline-vertical-line'>
                  </div>
                </div>

                <div className='homepage-how-it-works-timeline-step-column-3'>
                  <div className='homepage-how-it-works-timeline-image-right'>
                    <img src='https://uploads-ssl.webflow.com/5be8cea3dc46203a5ad06bda/5be9e59773578e14968a8c67_HIW2.png'/>
                  </div>
                </div>
              </div>


              {/*STEP 3*/}
              <div className='homepage-how-it-works-step'>
                <div className='homepage-how-it-works-timeline-step-column-1'>
                  <div className='homepage-how-it-works-timeline-image-left-2'>
                    <img src='https://uploads-ssl.webflow.com/5be8cea3dc46203a5ad06bda/5be9e597dc4c4544bea34ef6_HIW3.png'/>
                  </div>
                </div>

                <div className='homepage-how-it-works-timeline-step-column-2'>
                  <div className='homepage-how-it-works-timeline-step-number'>
                    STEP 3
                  </div>
                  <div className='homepage-how-it-works-timeline-vertical-line'>
                  </div>
                </div>

                <div className='homepage-how-it-works-timeline-step-column-3'>
                  <div className='homepage-how-it-works-timeline-text-right'>
                    <div className='homepage-how-it-works-timeline-title'>
                      Companies invite you to onsites
                    </div>
                    <div className='homepage-how-it-works-timeline-body'>
                      After interviewing on Symbol, companies reach out to invite you to their final rounds of interviews. Move forward with your favorites.
                    </div>
                  </div>
                </div>
              </div>

              {/*STEP 4*/}
              <div className='homepage-how-it-works-step'>
                <div className='homepage-how-it-works-timeline-step-column-1'>
                  <div className='homepage-how-it-works-timeline-text-left'>
                    <div className='homepage-how-it-works-timeline-title'>
                      Accept a job offer
                    </div>
                  </div>
                  <div className='homepage-how-it-works-timeline-text-left'>
                    <div className='homepage-how-it-works-timeline-body'>
                      Compare your offers and, if there’s one you love, sign on the dotted line. To celebrate, we send you a $500 hiring bonus.
                    </div>
                  </div>
                </div>

                <div className='homepage-how-it-works-timeline-step-column-2'>
                  <div className='homepage-how-it-works-timeline-step-number'>
                    STEP 4
                  </div>
                </div>

                <div className='homepage-how-it-works-timeline-step-column-3'>
                  <div className='homepage-how-it-works-timeline-image-right-2'>
                    <img src='https://uploads-ssl.webflow.com/5be8cea3dc46203a5ad06bda/5be9e59771a762a6d407eea1_HIW4.png'/>
                  </div>
                </div>
              </div>



            </div>
          </div>

          {/*MOBILE*/}

          <div className='homepage-how-it-works-timeline-mobile'>
            <div className='homepage-how-it-works-timeline-container'>

              {/*STEP 1*/}
              <div className='homepage-how-it-works-step-mobile'>
                <div className='homepage-how-it-works-timeline-step-number'>
                  STEP 1
                </div>
                <div className='homepage-how-it-works-timeline-title'>
                    Create a profile
                </div>
                <div className='homepage-how-it-works-timeline-body'>
                  Tell us about you and what kinds of jobs you’re looking for. We'll make sure you're seen by the best companies based on your preferences.

                </div>
              </div>
              <div className='homepage-how-it-works-timeline-vertical-line-mobile'>
              </div>

              {/*STEP 2*/}
              <div className='homepage-how-it-works-step-mobile'>
                <div className='homepage-how-it-works-timeline-step-number'>
                  STEP 2
                </div>
                <div className='homepage-how-it-works-timeline-title'>
                    Get a guaranteed interview
                </div>
                <div className='homepage-how-it-works-timeline-body'>
                  Complete your first round interview directly on Symbol. This one written interview is accepted by all of our partner companies and allows you to demonstrate your skills in detail.

                </div>
              </div>
              <div className='homepage-how-it-works-timeline-vertical-line-mobile'>
              </div>

              {/*STEP 3*/}
              <div className='homepage-how-it-works-step-mobile'>
                <div className='homepage-how-it-works-timeline-step-number'>
                  STEP 3
                </div>
                <div className='homepage-how-it-works-timeline-title'>
                    Companies invite you to onsites
                </div>
                <div className='homepage-how-it-works-timeline-body'>
                  After interviewing on Symbol, companies reach out to invite you to their final rounds of interviews. Move forward with your favorites.

                </div>
              </div>
              <div className='homepage-how-it-works-timeline-vertical-line-mobile'>
              </div>

              {/*STEP 4*/}
              <div className='homepage-how-it-works-step-mobile'>
                <div className='homepage-how-it-works-timeline-step-number'>
                  STEP 4
                </div>
                <div className='homepage-how-it-works-timeline-title'>
                  Accept a job offer
                </div>
                <div className='homepage-how-it-works-timeline-body'>
                  Compare your offers and, if there’s one you love, sign on the dotted line. To celebrate, we send you a $500 hiring bonus.
                </div>
              </div>

            </div>
          </div>



        </div>

        {/*BENEFITS - DESKTOP*/}

        {/*BENEFIT 1*/}
        <div className='homepage-benefits-section'>
          <div className='homepage-benefits-section-container'>
            <div className='homepage-benefits-section-column-left'>
              <div className='homepage-benefits-section-blue-benefit'>
                GET HIRED FASTER
              </div>
              <div className='homepage-benefits-section-title'>
                Simplify the process with one interview for all companies
              </div>
              <div className='homepage-benefits-section-body'>
                We worked with hundreds of the best hiring managers to create a single first round interview accepted by 200+ top companies.<br/><br/>Complete this written interview once, on your own time, without the usual pressure. Start getting onsite interview requests in less than 3 business days.
            </div>
            </div>
            <div className='homepage-benefits-section-column-right'>
              <div className='homepage-benefits-image-right'>
                <img src='https://uploads-ssl.webflow.com/5be8cea3dc46203a5ad06bda/5be9ff7b7c44d683ecb69087_benefit1.png'/>
              </div>
            </div>
          </div>
        </div>

        {/*BENEFIT 2*/}
        <div className='homepage-benefits-section'>
          <div className='homepage-benefits-section-container'>
            <div className='homepage-benefits-section-column-left'>
              <div className='homepage-benefits-image-left'>
                <img src='https://uploads-ssl.webflow.com/5be8cea3dc46203a5ad06bda/5c0872bca0d38ce7ab9c0e4c_Group%209%20Copy%202.png'/>
              </div>
            </div>
            <div className='homepage-benefits-section-column-right-text'>
              <div className='homepage-benefits-section-blue-benefit'>
                BE DISCOVERED
              </div>
              <div className='homepage-benefits-section-title-right'>
                Ensure your talents are seen with our guaranteed reviews
              </div>
              <div className='homepage-benefits-section-body-right'>
                Every interview by every applicant on Symbol is reviewed dozens of times by humans, not machines, ensuring that no talent is overlooked.
                <br/><br/>
                If you’re a high-potential or high-skill candidate, regardless of your background, we’ll recognize your talents and connect you with jobs and companies you’ll love.
              </div>
            </div>
            <div className='homepage-benefits-section-column-right-mobile'>
              <div className='homepage-benefits-image-right'>
                <img src='https://uploads-ssl.webflow.com/5be8cea3dc46203a5ad06bda/5c0872bca0d38ce7ab9c0e4c_Group%209%20Copy%202.png'/>
              </div>
            </div>
          </div>
        </div>

        {/*BENEFIT 3*/}
        <div className='homepage-benefits-section'>
          <div className='homepage-benefits-section-container'>
            <div className='homepage-benefits-section-column-left'>
              <div className='homepage-benefits-section-blue-benefit'>
                CELEBRATE YOUR SUCCESS
              </div>
              <div className='homepage-benefits-section-title'>
              Earn a $500 hiring bonus if you or a friend gets hired
              </div>
              <div className='homepage-benefits-section-body'>
                If you accept a job offer from a company on Symbol, we’ll celebrate by sending you a congratulatory $500 hiring bonus.
                <br/><br/>
                Not looking for a job, but like helping your friends? Refer a friend to Symbol, and if they get hired, you both get $500.
              </div>
            </div>
            <div className='homepage-benefits-section-column-right'>
              <div className='homepage-benefits-image-right'>
                <img src='https://uploads-ssl.webflow.com/5be8cea3dc46203a5ad06bda/5c02508ca3c2def4c0a23891_Group%209.png'/>
              </div>
            </div>
          </div>
        </div>

        <div className='homepage-bottom-cta'>
          <div className='homepage-bottom-cta-container'>
            <div className='homepage-bottom-cta-title'>
              The job search just got easier
            </div>
            <div className='homepage-bottom-cta-body'>
            </div>
            <button className='large-button' onClick={this.toggleCreateAccountModal}>
              Get started now
            </button>
          </div>
        </div>

      <div className='homepage-footer'>
        <div className='homepage-footer-main'>
          <div className='homepage-footer-container'>
            <div className='homepage-footer-column'>
              <div className='homepage-logo'>
                <div className='homepage-logo-container'>
                  <HomepageLogo />
                </div>
                <div className='homepage-logo-text-container'>
                  symbol
                </div>
              </div>
            </div>
            <div className='homepage-footer-column'>
              <div className='homepage-footer-column-header'>
                Candidates
              </div>
              <div className='homepage-footer-link'>
                <a onClick={this.toggleCreateAccountModal}>Explore jobs</a>
              </div>
              <div className='homepage-footer-link'>
                <a onClick={this.toggleCreateAccountModal}>Candidate Sign Up</a>
              </div>
              <div className='homepage-footer-link'>
                <a href='/'>FAQ</a>
              </div>
            </div>

            <div className='homepage-footer-column'>
              <div className='homepage-footer-column-header'>
                Employers
              </div>
              <div className='homepage-footer-link'>
                <a href='/employers'>For employers</a>
              </div>
              <div className='homepage-footer-link'>
                <a href='/employers'>Employer Sign Up</a>
              </div>
              <div className='homepage-footer-link'>
                <a href='/'>FAQ</a>
              </div>
            </div>

            <div className='homepage-footer-column'>
              <div className='homepage-footer-column-header'>
                Company
              </div>
              <div className='homepage-footer-link'>
                <a href='/'>About</a>
              </div>
              <div className='homepage-footer-link'>
                <a href='mailto:hello@symbol.co'>Careers</a>
              </div>
              <div className='homepage-footer-link'>
                <a href='mailto:hello@symbol.co'>Support</a>
              </div>
            </div>
          </div>
          <div className='homepage-footer-container'>
            <div className='homepage-footer-column-half'>
              © 2018 Symbol, Inc. All Rights Reserved.
            </div>
            <div className='homepage-footer-column-half-2'>
              <div className='homepage-footer-legal-links'>
                <a target="_blank" href='https://www.notion.so/Symbol-Privacy-Policy-54cb653d8f66491389c123f59535a664'>Privacy	</a>
                <a className='add-space' target="_blank" href='https://www.notion.so/Symbol-Terms-of-Service-ddbc390b70fd465f8c7a8077a1452abf'>Terms</a>
              </div>
            </div>
          </div>

        </div>
      </div>


      <div style={{visibility:this.state.isCreateAccountModalOpen || this.state.isLogInModalOpen ? 'visible':'hidden', opacity:this.state.isCreateAccountModalOpen || this.state.isLogInModalOpen? '1' : '0'}} className='general-overlay'></div>
          <CreateAccountModal
            userHasAuthenticated = {this.props.userHasAuthenticated}
            toggleCreateAccountModal = {this.toggleCreateAccountModal}
            isCreateAccountModalOpen = {this.state.isCreateAccountModalOpen}
            toggleLogInModal = {this.toggleLogInModal}
           />

           <LogInModal
            companyRepHasAuthenticated = {this.props.companyRepHasAuthenticated}
              userHasAuthenticated = {this.props.userHasAuthenticated}
             toggleLogInModal = {this.toggleLogInModal}
             isLogInModalOpen = {this.state.isLogInModalOpen}
             toggleCreateAccountModal = {this.toggleCreateAccountModal}
            />
    </div>
  )}
}

export default withRouter(HomePage);
