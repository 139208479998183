import React from 'react'

const FilterPlus = props => (
  <svg width={9} height={10} {...props}>
    <path
      d="M4.5 4.591v-4.5 4.5H9 4.5zm0 0v4.5-4.5H0h4.5z"
      stroke="#000"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="square"
    />
  </svg>
)

export default FilterPlus
