import React, { Component } from 'react';
import {BrowserRouter as Router, Route, Link, withRouter} from 'react-router-dom';
import './employer-create-account.css';
import '../profile/custom-form.css';
import ReactDOM from 'react-dom';
import {Helmet} from "react-helmet";
import HomepageLogo from '../../svg/homepage-logo.js';
import Backend from "../../backend.js";
import LightHeader from '../basics/light-header.js';

class Jobs extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };

  }

  render() {

    return (
      <div className='create-account-step'>
        <Helmet>
          <title>Symbol - Get a job</title>
        </Helmet>
        <div className='dark-header-top-bar'>
          <div className='dark-header-top-bar-container'>
            <div className='dark-header-logo'>
              <div className='dark-header-logo-container'>
                <HomepageLogo />
              </div>
              <div className='dark-header-logo-text-container'>
                symbol
              </div>
            </div>
            <div className='dark-header-links-container'>
              <a onClick={() => this.props.history.push("/employers")}>For employers</a>
              <a onClick={() => this.props.history.push("/jobs")}>Explore jobs</a>
              <a onClick={this.toggleLogInModal}>Log in</a>
              <a onClick={this.toggleCreateAccountModal}>Sign up</a>
            </div>
          </div>
        </div>

    </div>
  )}
}

export default withRouter(Jobs);
