
const InterviewQuestions = {
    "Adaptability" :
      { "firstPrompt": "Convince us you are great at learning new things. People often describe the most impressive or challenging things they've learned in the past few years (both inside and outside of work).",
        "secondPrompt": "Describe a time that you received feedback (from a co-worker, customer, friend, etc.). What was the feedback and how did you take it? What actions did you take to implement the feedback and what was the outcome?",
        "firstLabel": "Learning Agility",
        "secondLabel": "Openness to Feedback",
        "intro": "Top companies are looking for openminded candidates who can learn quickly and adapt to the challenges at work. Use this opportunity to show off your abilities to learn and adapt.",
        "interviewNumberDescription": "Interview Question 4 of 4",
        "title": "QUESTION 4: ADAPTABILITY",
        "backLink": "/interview/3",
        "nextLink": "/profile",
        "skillName": "Adaptability"
      },
      "Team Skills" :
        { "firstPrompt": "What is your approach to working effectively with people when they have different ideas, areas of expertise, or work styles? If helpful, describe a specific example that demonstrates why your approach is successful.",
          "secondPrompt": "Describe a time when you led, motivated, or influenced other people. What was the situation and how did you get others to follow your lead? What was the outcome? How do you approach these kinds of situations in general?",
          "firstLabel": "Collaboration",
          "secondLabel": "Leadership",
          "intro": "Top companies are looking for candidates who can lead, motivate, and collaborate with others effectively. Use this opportunity to show off how you work on a team.",
          "interviewNumberDescription": "Interview Question 2 of 4",
          "title": "QUESTION 2: TEAM SKILLS",
          "backLink": "/interview/1",
          "nextLink": "/interview/3",
          "skillName": "Team Skills"
        },

    "Execution Abilities" :
      { "firstPrompt": "Describe a time when you took on an ambitious project (inside or outside of work), even though it wasn't necessary to do so. Why was this project ambitious, what were the challenges, and how did you find a way through? Why did you decide to take on this project and what was the outcome?",
        "secondPrompt": "Describe a time when you had too much to do, but not enough resources (time, money, people, etc.). How did you handle the workload / pressure, overcome the deficit, and achieve your goals? Why did you make your decisions the way you did? What is your approach to dealing with these situations in general?",
        "firstLabel": "Ambition",
        "secondLabel": "Getting Work Done",
        "intro": "Top companies are looking for ambitious candidates who take initiative, solve problems, and get things done. Use this opportunity to show off your abilities to execute at a high level.",
        "interviewNumberDescription": "Interview Question 3 of 4",
        "title": "QUESTION 3: EXECUTION ABILITIES",
        "backLink": "/interview/2",
        "nextLink": "/interview/4",
        "skillName": "Execution Abilities"
      },
      "Role":
      {
        "firstLabel": "[ROLE]",
        "firstPrompt": "Convince us you're great at [ROLE]. If you don't have much experience, convince us why you would be great at this role. (Sell yourself. We encourage you to brag. People often include a brief description of their most impressive accomplishments).",
        "title": "QUESTION 1: TALENT & EXPERIENCE",
        "intro": "Top companies want to know why you're great. Use this opportunity to show off your talent and experience.",
        "interviewNumberDescription": "Interview Question 1 of 3",
        "backLink": "/interview",
        "nextLink": "/interview/2",
        "skillName": "Role"

      }
}

export default InterviewQuestions
