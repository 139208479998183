import React, { Component } from 'react';
import {BrowserRouter as Router, Route, Link, withRouter} from 'react-router-dom';
import './homepage-employers.css';
import ReactDOM from 'react-dom';
import {Helmet} from "react-helmet";
import HomepageLogo from '../../svg/homepage-logo.js';
import CreateAccountModal from './employer-create-account-modal.js';
import LogInModal from './log-in-modal.js';
import Users from '../../store/users.js';

class HomePageEmployers extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isCreateAccountModalOpen: false,
      isLogInModalOpen: false,
      hasScrolled: false,
    };

    this.toggleCreateAccountModal = this.toggleCreateAccountModal.bind(this);
    this.toggleLogInModal = this.toggleLogInModal.bind(this);
    this.myRef = React.createRef();
  }
  componentDidMount() {

  }

  toggleCreateAccountModal() {
    this.setState(state => ({
      isCreateAccountModalOpen: !state.isCreateAccountModalOpen
    }));
  }

  toggleLogInModal() {
    this.setState(state => ({
      isLogInModalOpen: !state.isLogInModalOpen
    }));
  }

  render() {
    return (
      <div className='homepage'>
        <Helmet>
          <title>Symbol for Employers</title>
        </Helmet>

        <div className='homepage-e-hero-section'>
          <div className='homepage-e-hero-top-bar'>
            <div className='homepage-e-hero-top-bar-container'>
              <div onClick={() => this.props.history.push("/")} className='homepage-e-logo'>
                <div  className='homepage-e-logo-container'>
                  <HomepageLogo />
                </div>
                <div className='homepage-e-logo-text-container'>
                  symbol
                </div>
              </div>
              {
                this.props.isAuthenticated ?
                <div className='homepage-e-links-container'>
                  <a onClick={() => this.props.history.push("/me")}>Go to my profile</a>
                </div>
                :
                <div className='homepage-e-links-container'>
                  <a className='hide-on-mobile'>For employers</a>
                  <a className='hide-on-mobile' onClick={this.toggleCreateAccountModal}>Explore jobs</a>
                  <a onClick={this.toggleLogInModal}>Log in</a>
                  <a onClick={this.toggleCreateAccountModal}>Sign up</a>
                </div>
              }

            </div>
          </div>
          <div className='homepage-e-hero-main-section'>
            <div className='homepage-e-hero-main-section-container'>
              <div className='homepage-e-center-container'>
                <div className='homepage-e-hero-headline'>
                  Hire better with Symbol, a search engine for top talent.
                </div>
              </div>
              <div className='homepage-e-center-container'>
                <div className='homepage-e-hero-body-text'>
                  {"We've sourced, interviewed, sorted, and indexed thousands of top candidates, so you don't have to. Discover the highest-talent candidates in minutes. Hire 3x faster."}
                </div>
              </div>
              <div className='homepage-e-center-container'>
                <button className='large-button' onClick={this.toggleCreateAccountModal}>
                  Explore candidates
                </button>
              </div>

            </div>
          </div>
        </div>

        <div className='homepage-e-companies-section'>
          <div className='homepage-e-companies-section-title'>
             JOIN 200+ OF THE BEST COMPANIES ON SYMBOL
          </div>
          <div className='homepage-e-companies-section-logos-container'>
            <div className='homepage-e-companies-section-logo-container'>
              <div className='homepage-e-company-logo'>
                <img src='https://uploads-ssl.webflow.com/5be8cea3dc46203a5ad06bda/5be8ceca8afd197c8ef3dae6_logo1.png'/>
              </div>
              <div className='homepage-e-company-logo'>
                <img src='https://uploads-ssl.webflow.com/5be8cea3dc46203a5ad06bda/5be8cecaaaba7a3e1eec848f_logo2.png'/>
              </div>
              <div className='homepage-e-company-logo'>
                <img src='https://uploads-ssl.webflow.com/5be8cea3dc46203a5ad06bda/5be8ceca0c11cfab9cb469a6_logo3.png'/>
              </div>
              <div className='homepage-e-company-logo'>
                <img src='https://uploads-ssl.webflow.com/5be8cea3dc46203a5ad06bda/5be8ceca8afd1978e0f3dae7_logo4.png'/>
              </div>
              <div className='homepage-e-company-logo'>
                <img src='https://uploads-ssl.webflow.com/5be8cea3dc46203a5ad06bda/5be8cecaf904b33b279c41d6_logo5.png'/>
              </div>
            </div>
          </div>

          <div className='homepage-e-jobs-bar'>
            <div className='homepage-e-jobs-bar-container'>
              <div className='homepage-e-jobs-bar-role'>
                Product Management
              </div>
              <div className='homepage-e-jobs-bar-role'>
                Design
              </div>
              <div className='homepage-e-jobs-bar-role'>
                Software Engineering
              </div>
              <div className='homepage-e-jobs-bar-role'>
                Sales
              </div>
              <div className='homepage-e-jobs-bar-role'>
                Data Science
              </div>
              <div className='homepage-e-jobs-bar-role'>
                Marketing
              </div>
              <div onClick={this.toggleCreateAccountModal} className='homepage-jobs-bar-role-link'>
                Explore all roles →
              </div>
            </div>

          </div>
        </div>

        <div className='homepage-e-how-it-works-section'>
          <div className='homepage-e-how-it-works-title'>
            How it works
          </div>

          {/*DESKTOP*/}

          <div className='homepage-e-how-it-works-timeline'>
            <div className='homepage-e-how-it-works-timeline-container'>

              {/*STEP 1*/}
              <div className='homepage-e-how-it-works-step'>
                <div className='homepage-e-how-it-works-timeline-step-column-1'>
                  <div className='homepage-e-how-it-works-timeline-image-left'>

                    <img src='https://uploads-ssl.webflow.com/5be8cea3dc46203a5ad06bda/5be9e59773578e14968a8c67_HIW2.png'/>
                  </div>
                </div>

                <div className='homepage-e-how-it-works-timeline-step-column-2'>
                  <div className='homepage-e-how-it-works-timeline-step-number'>
                    STEP 1
                  </div>
                  <div className='homepage-e-how-it-works-timeline-vertical-line'>
                  </div>
                </div>

                <div className='homepage-e-how-it-works-timeline-step-column-3'>
                  <div className='homepage-e-how-it-works-timeline-text-right'>
                    <div className='homepage-e-how-it-works-timeline-title'>
                      Select the roles you're looking for
                    </div>
                    <div className='homepage-e-how-it-works-timeline-body'>
                      {"Specify the skills and experience you’re looking for, and we'll administer the first round interview for you."}
                    </div>
                  </div>
                </div>
              </div>

              {/*STEP 2*/}
              <div className='homepage-e-how-it-works-step'>
                <div className='homepage-e-how-it-works-timeline-step-column-1'>
                  <div className='homepage-e-how-it-works-timeline-text-left'>
                    <div className='homepage-e-how-it-works-timeline-title'>
                      Get a ranked list of matches
                    </div>
                  </div>
                  <div className='homepage-e-how-it-works-timeline-text-left'>
                    <div className='homepage-e-how-it-works-timeline-body'>
                      Use our Advanced Search filters to discover the perfect matches. Filter based on job requirements or by percentile performance on behavioral interview questions.
                    </div>
                  </div>
                </div>

                <div className='homepage-e-how-it-works-timeline-step-column-2'>
                  <div className='homepage-e-how-it-works-timeline-step-number'>
                    STEP 2
                  </div>
                  <div className='homepage-e-how-it-works-timeline-vertical-line'>
                  </div>
                </div>

                <div className='homepage-e-how-it-works-timeline-step-column-3'>
                  <div className='homepage-e-how-it-works-timeline-image-right'>
                    <img src='https://uploads-ssl.webflow.com/5be8cea3dc46203a5ad06bda/5be9e59ffc16dcb2b0b35c1c_HIW1.png'/>
                  </div>


                </div>
              </div>


              {/*STEP 3*/}
              <div className='homepage-e-how-it-works-step'>
                <div className='homepage-e-how-it-works-timeline-step-column-1'>
                  <div className='homepage-e-how-it-works-timeline-image-left-2'>
                    <img src='https://uploads-ssl.webflow.com/5be8cea3dc46203a5ad06bda/5be9e597dc4c4544bea34ef6_HIW3.png'/>
                  </div>
                </div>

                <div className='homepage-e-how-it-works-timeline-step-column-2'>
                  <div className='homepage-e-how-it-works-timeline-step-number'>
                    STEP 3
                  </div>
                  <div className='homepage-e-how-it-works-timeline-vertical-line'>
                  </div>
                </div>

                <div className='homepage-e-how-it-works-timeline-step-column-3'>
                  <div className='homepage-e-how-it-works-timeline-text-right'>
                    <div className='homepage-e-how-it-works-timeline-title'>
                      Skip straight to onsites
                    </div>
                    <div className='homepage-e-how-it-works-timeline-body'>
                      Read through candidate profiles and first round interview answers on Symbol, and invite the best to onsites.
                    </div>
                  </div>
                </div>
              </div>

              {/*STEP 4*/}
              <div className='homepage-e-how-it-works-step'>
                <div className='homepage-e-how-it-works-timeline-step-column-1'>
                  <div className='homepage-e-how-it-works-timeline-text-left'>
                    <div className='homepage-e-how-it-works-timeline-title'>
                      Hire the best candidates, faster
                    </div>
                  </div>
                  <div className='homepage-e-how-it-works-timeline-text-left'>
                    <div className='homepage-e-how-it-works-timeline-body'>
                      Make offers to the best candidates two weeks faster, ensuring you're moving quickly on top talent and boosting your offer acceptance rate.
                    </div>
                  </div>
                </div>

                <div className='homepage-e-how-it-works-timeline-step-column-2'>
                  <div className='homepage-e-how-it-works-timeline-step-number'>
                    STEP 4
                  </div>
                </div>

                <div className='homepage-e-how-it-works-timeline-step-column-3'>
                  <div className='homepage-e-how-it-works-timeline-image-right-2'>
                    <img src='https://uploads-ssl.webflow.com/5be8cea3dc46203a5ad06bda/5be9e59771a762a6d407eea1_HIW4.png'/>
                  </div>
                </div>
              </div>



            </div>
          </div>

          {/*MOBILE*/}

          <div className='homepage-e-how-it-works-timeline-mobile'>
            <div className='homepage-e-how-it-works-timeline-container'>

              {/*STEP 1*/}
              <div className='homepage-e-how-it-works-step-mobile'>
                <div className='homepage-e-how-it-works-timeline-step-number'>
                  STEP 1
                </div>
                <div className='homepage-e-how-it-works-timeline-title'>
                    Select the roles you're looking for
                </div>
                <div className='homepage-e-how-it-works-timeline-body'>
                  {"Specify the skills and experience you’re looking for, and we'll administer the first round interview for you."}
                </div>
              </div>
              <div className='homepage-e-how-it-works-timeline-vertical-line-mobile'>
              </div>

              {/*STEP 2*/}
              <div className='homepage-e-how-it-works-step-mobile'>
                <div className='homepage-e-how-it-works-timeline-step-number'>
                  STEP 2
                </div>
                <div className='homepage-e-how-it-works-timeline-title'>
                  Get a ranked list of matches
                </div>
                <div className='homepage-e-how-it-works-timeline-body'>
                  Use our Advanced Search filters to discover the perfect matches. Filter based on job requirements or by percentile performance on behavioral interview questions.
                </div>
              </div>
              <div className='homepage-e-how-it-works-timeline-vertical-line-mobile'>
              </div>

              {/*STEP 3*/}
              <div className='homepage-e-how-it-works-step-mobile'>
                <div className='homepage-e-how-it-works-timeline-step-number'>
                  STEP 3
                </div>
                <div className='homepage-e-how-it-works-timeline-title'>
                  Skip straight to onsites
                </div>
                <div className='homepage-e-how-it-works-timeline-body'>
                  Read through candidate profiles and first round interview answers on Symbol, and invite the best onsite.
                </div>
              </div>
              <div className='homepage-e-how-it-works-timeline-vertical-line-mobile'>
              </div>

              {/*STEP 4*/}
              <div className='homepage-e-how-it-works-step-mobile'>
                <div className='homepage-e-how-it-works-timeline-step-number'>
                  STEP 4
                </div>
                <div className='homepage-e-how-it-works-timeline-title'>
                  Hire the best candidates, faster
                </div>
                <div className='homepage-e-how-it-works-timeline-body'>
                  Make offers to the best candidates two weeks faster, ensuring you're moving quickly on top talent. Boost your offer acceptance rate.
                </div>
              </div>

            </div>
          </div>



        </div>

        {/*BENEFITS - DESKTOP*/}

        {/*BENEFIT 1*/}
        <div className='homepage-e-benefits-section'>
          <div className='homepage-e-benefits-section-container'>
            <div className='homepage-e-benefits-section-column-left'>
              <div className='homepage-e-benefits-section-blue-benefit'>
                HIRE TOP TALENT FASTER
              </div>
              <div className='homepage-e-benefits-section-title'>
                Streamline your hiring process and hire 3x faster
              </div>
              <div className='homepage-e-benefits-section-body'>
                {"We administer a single, hiring-manager-approved first round interview to every candidate on Symbol, so you don't have to."}<br/><br/>{"Candidates are assessed and ranked using Network Intelligence, allowing you to find the highest performing candidates instantly. No phone screen necessary. Accelerate straight to onsites."}
            </div>
            </div>
            <div className='homepage-e-benefits-section-column-right'>
              <div className='homepage-e-benefits-image-right'>
                <img className='grayscale' src='https://uploads-ssl.webflow.com/5be8cea3dc46203a5ad06bda/5c05920a39fbdcd9239208bb_stretch.png'/>
              </div>
            </div>
          </div>
        </div>

        {/*BENEFIT 2*/}
        <div className='homepage-e-benefits-section'>
          <div className='homepage-e-benefits-section-container'>
            <div className='homepage-e-benefits-section-column-left'>
              <div className='homepage-e-benefits-image-left'>
                <img src='https://uploads-ssl.webflow.com/5be8cea3dc46203a5ad06bda/5c0590061d20270434a91b04_people.png'/>
              </div>
            </div>
            <div className='homepage-e-benefits-section-column-right-text'>
              <div className='homepage-e-benefits-section-blue-benefit'>
                DIVERSIFY YOUR PIPELINE
              </div>
              <div className='homepage-e-benefits-section-title-right'>
                {"Don't compete for talent. Uncover the hidden gems."}
              </div>
              <div className='homepage-e-benefits-section-body-right'>
                Every interview by every applicant on Symbol is anonymized and reviewed dozens of times by expert reviewers, ensuring that no top talent is overlooked or filtered out, regardless of credentials or background.
                <br/><br/>
                {"This means you can source, interview, and hire world-class talent that isn't on anyone else's radar, boosting your offer acceptance rate."}
              </div>
            </div>
            <div className='homepage-e-benefits-section-column-right-mobile'>
              <div className='homepage-e-benefits-image-right'>
                <img src='https://uploads-ssl.webflow.com/5be8cea3dc46203a5ad06bda/5c0590061d20270434a91b04_people.png'/>
              </div>
            </div>
          </div>
        </div>

        {/*BENEFIT 3*/}
        <div className='homepage-e-benefits-section'>
          <div className='homepage-e-benefits-section-container'>
            <div className='homepage-e-benefits-section-column-left'>
              <div className='homepage-e-benefits-section-blue-benefit'>
                SIMPLIFY HIRING COSTS
              </div>
              <div className='homepage-e-benefits-section-title'>
                Connect with candidates for free. Only pay when you hire.
              </div>
              <div className='homepage-e-benefits-section-body'>
                There’s no upfront cost to browse or connect with candidates. Only pay when you hire. Ensure each candidate is the right fit with our 3 month money-back guarantee.
              </div>
            </div>
            <div className='homepage-e-benefits-section-column-right'>
              <div className='homepage-e-benefits-image-right'>
                <img src='https://uploads-ssl.webflow.com/5be8cea3dc46203a5ad06bda/5c02508ca3c2def4c0a23891_Group%209.png'/>
              </div>
            </div>
          </div>
        </div>

        <div className='homepage-e-bottom-cta'>
          <div className='homepage-e-bottom-cta-container'>
            <div className='homepage-e-bottom-cta-title'>
              Hiring just got easier
            </div>
            <div className='homepage-e-bottom-cta-body'>
            </div>
            <button className='large-button' onClick={this.toggleCreateAccountModal}>
              Get started now
            </button>
          </div>
        </div>

        <div className='homepage-e-footer'>
          <div className='homepage-e-footer-main'>
            <div className='homepage-e-footer-container'>
              <div className='homepage-e-footer-column'>
                <div className='homepage-e-logo'>
                  <div className='homepage-e-logo-container'>
                    <HomepageLogo />
                  </div>
                  <div className='homepage-e-logo-text-container'>
                    symbol
                  </div>
                </div>
              </div>
              <div className='homepage-e-footer-column'>
                <div className='homepage-e-footer-column-header'>
                  Candidates
                </div>
                <div className='homepage-e-footer-link'>
                  <a onClick={this.toggleCreateAccountModal}>Explore jobs</a>
                </div>
                <div className='homepage-e-footer-link'>
                  <a href='/'>Candidate Sign Up</a>
                </div>
                <div className='homepage-e-footer-link'>
                  <a href='/'>FAQ</a>
                </div>
              </div>

              <div className='homepage-e-footer-column'>
                <div className='homepage-e-footer-column-header'>
                  Employers
                </div>
                <div className='homepage-e-footer-link'>
                  <a href='/employers'>For employers</a>
                </div>
                <div className='homepage-e-footer-link'>
                  <a onClick={this.toggleCreateAccountModal}>Employer Sign Up</a>
                </div>
                <div className='homepage-e-footer-link'>
                  <a href='/'>FAQ</a>
                </div>
              </div>

              <div className='homepage-e-footer-column'>
                <div className='homepage-e-footer-column-header'>
                  Company
                </div>
                <div className='homepage-e-footer-link'>
                  <a href='/'>About</a>
                </div>
                <div className='homepage-e-footer-link'>
                  <a href='mailto:hello@symbol.co'>Careers</a>
                </div>
                <div className='homepage-e-footer-link'>
                  <a href='mailto:hello@symbol.co'>Support</a>
                </div>
              </div>
            </div>
            <div className='homepage-e-footer-container'>
              <div className='homepage-e-footer-column-half'>
                © 2018 Symbol, Inc. All Rights Reserved.
              </div>
              <div className='homepage-e-footer-column-half-2'>
                <div className='homepage-e-footer-legal-links'>
                  <a target="_blank" href='https://www.notion.so/Symbol-Privacy-Policy-54cb653d8f66491389c123f59535a664'>Privacy	</a>
                  <a className='add-space' target="_blank" href='https://www.notion.so/Symbol-Terms-of-Service-ddbc390b70fd465f8c7a8077a1452abf'>Terms</a>
                </div>
              </div>
            </div>

        </div>
      </div>


      <div style={{visibility:this.state.isCreateAccountModalOpen || this.state.isLogInModalOpen ? 'visible':'hidden', opacity:this.state.isCreateAccountModalOpen || this.state.isLogInModalOpen? '1' : '0'}} className='general-overlay'></div>
          <CreateAccountModal
            userHasAuthenticated = {this.props.companyRepHasAuthenticated}
            toggleCreateAccountModal = {this.toggleCreateAccountModal}
            isCreateAccountModalOpen = {this.state.isCreateAccountModalOpen}
            toggleLogInModal = {this.toggleLogInModal}
           />

           <LogInModal
              companyRepHasAuthenticated = {this.props.companyRepHasAuthenticated}
              userHasAuthenticated = {this.props.userHasAuthenticated}
              toggleLogInModal = {this.toggleLogInModal}
              isLogInModalOpen = {this.state.isLogInModalOpen}
              toggleCreateAccountModal = {this.toggleCreateAccountModal}
            />
    </div>
  )}
}

export default withRouter(HomePageEmployers);
