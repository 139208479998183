import React from 'react'

const StatsIcon4 = props => (
  <svg width={16} height={16} {...props}>
    <path
      d="M15.756 14.565l-3.874-3.958a6.368 6.368 0 0 0 1.542-4.15C13.424 2.896 10.476 0 6.853 0 3.229 0 .28 2.896.28 6.456c0 3.56 2.948 6.456 6.572 6.456 1.36 0 2.656-.403 3.764-1.168l3.904 3.988a.858.858 0 0 0 .617.258.86.86 0 0 0 .594-.234.833.833 0 0 0 .024-1.191zM6.853 1.685c2.678 0 4.857 2.14 4.857 4.771s-2.18 4.772-4.857 4.772c-2.679 0-4.858-2.14-4.858-4.772 0-2.631 2.18-4.772 4.858-4.772z"
      fill="#000"
      fillRule="nonzero"
    />
  </svg>
)

export default StatsIcon4
