import React, { Component } from 'react';
import './boost-profile.css';

class BoostProfile extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className='boost-profile'>
        <div className='boost-profile-container'>
          <div className='boost-profile-text-container'>
            <div className='boost-profile-title'>
              Boost your profile
            </div>
            <div className='boost-profile-text'>
              Boost your profile to increase your hiring bonus up to <span className='blue-text'>$3000</span>, and make your profile more visibile to top employers.
            </div>
          </div>
          <div className='boost-profile-button-container'>
            <button onClick={()=>this.props.history.push('/boost')} className='small-button'>Boost your profile →</button>
          </div>
        </div>
      </div>
  )}
}

export default BoostProfile;
