import Users from "./store/users.js"
import {ENV_API, CLOUDINARY_CLOUD_NAME, CLOUDINARY_UPLOAD_PRESET} from './constants.js'

const API = ENV_API;
//"http://symbol-staging-env.my55mapsej.us-west-2.elasticbeanstalk.com/";
const CORS_HEADER = "SYMBOL-HEADER"
const CORS_ORIGIN = "*"

export default class Backend {

  static async makePostRequest(endpoint, data) {
    let response = await fetch(API + endpoint, {
          method: 'POST',
          mode: 'cors',
          headers: {
            "accept": "application/json",
            "Access-Control-Request-Headers":CORS_HEADER,
            'Access-Control-Allow-Origin':CORS_ORIGIN
          },
          body: data
        })
    const result = await response.json();
    console.log("response for ", endpoint, " : ", result);
    return result
  }

  static async makeGetRequest(url) {
    let response = await fetch(url, {
          method: 'GET',
          mode: 'cors',
          headers: {
            "accept": "application/json",
            "Access-Control-Request-Headers":CORS_HEADER,
            'Access-Control-Allow-Origin':CORS_ORIGIN
          },
        })
    const result = await response.json();
    return result
  }

  static async saveApplicantInfo(applicantInfo) {
    console.log("upating applicant: ", applicantInfo);
    let applicantInfoCopy = JSON.parse(JSON.stringify(applicantInfo));
    delete applicantInfoCopy["accessToken"];
    delete applicantInfoCopy["userId"];
    var data = new FormData();
    applicantInfoCopy = JSON.stringify(applicantInfoCopy);
    data.append("userId", Users.auth.userId);
    data.append("applicantInfo", applicantInfoCopy);
    data.append("jwtToken", Users.auth["accessToken"]["jwtToken"]);

    const result = Backend.makePostRequest("/updateApplicant", data);
    return result;
  }

  static async setApplicantContactedByReps(userId, contactedByReps) {
    var data = new FormData();
    contactedByReps = JSON.stringify(contactedByReps);
    data.append("userId", userId);
    data.append("contactedByReps", contactedByReps);

    const result = Backend.makePostRequest("/setApplicantContactedByReps", data);
    return result;
  }

  static async saveCompanyRepInfo(companyRepInfo) {
    console.log("upating companyRepInfo: ", companyRepInfo);
    let companyRepInfoCopy = JSON.parse(JSON.stringify(companyRepInfo));
    delete companyRepInfoCopy["accessToken"];
    delete companyRepInfoCopy["userId"];
    var data = new FormData();
    companyRepInfoCopy = JSON.stringify(companyRepInfoCopy);
    data.append("userId", Users.auth.userId);
    data.append("companyRepInfo", companyRepInfoCopy);
    data.append("jwtToken", Users.auth["accessToken"]["jwtToken"]);

    const result = Backend.makePostRequest("/updateCompanyRep", data);
    return result;
  }


  static async createApplicant(userInfo) {
    console.log("creating applicant: ", userInfo);
    var data = new FormData();

    data.append("userId", userInfo.userId);
    data.append("firstName", userInfo.firstName);
    data.append("lastName", userInfo.lastName);
    data.append("email", userInfo.email);

    let result = await Backend.makePostRequest('/createApplicant', data);
    return result;
  }

  static async createCompanyRep(userInfo) {
    console.log("creating company rep: ", userInfo);
    var data = new FormData();

    data.append("companyRepId", userInfo.companyRepId);
    data.append("firstName", userInfo.firstName);
    data.append("lastName", userInfo.lastName);
    data.append("email", userInfo.email);
    data.append("phone", userInfo.phone);
    data.append("companyName", userInfo.companyName);

    let result = await Backend.makePostRequest('/createCompanyRep', data);
    return result;
  }

  static async saveInterviewResponse(interviewResponseInfo) {
    var data = new FormData();
    data.append("userId", Users.auth.userId);
    data.append("interviewResponseInfo", JSON.stringify(interviewResponseInfo));
    data.append("jwtToken", Users.auth["accessToken"]["jwtToken"]);

    let result = await Backend.makePostRequest('/saveInterviewResponse', data);
    return result;
  }

  static async scheduleInterview(user, startDate) {
    var data = new FormData();
    data.append("userId", Users.auth.userId);
    data.append("startDate", JSON.stringify(startDate));
    data.append("jwtToken", Users.auth["accessToken"]["jwtToken"]);
    
    let result = await Backend.makePostRequest('/scheduleInterview', data);
    return result;
  }

  static async submitComparison(reviewerId, skillIdentifier, betterUserId, otherUserId, hiringBonus): Promise<void> {
    var data = new FormData();
    data.append("otherCandidateId", otherUserId);
    data.append("betterCandidateId", betterUserId);
    data.append("reviewerId", reviewerId);
    data.append("currentHiringBonus", hiringBonus);
    data.append("skillIdentifier", skillIdentifier);
    let result = await Backend.makePostRequest('/compare', data);
    console.log("COMPARISON response: ", result);
    return result
  }

  static async sendCompanyInterviewEmail(emailInfo): Promise<void> {
    var data = new FormData();
    emailInfo = JSON.stringify(emailInfo);

    data.append("emailInfo", emailInfo);
    data.append("userId", Users.auth.userId);
    data.append("jwtToken", Users.auth["accessToken"]["jwtToken"]);

    let result = await Backend.makePostRequest('/sendCompanyInterviewEmail', data);
    console.log("company interview email response: ", result);
    return result
  }

  static async sendReferralEmail(emailInfo): Promise<void> {
    var data = new FormData();
    emailInfo = JSON.stringify(emailInfo);

    data.append("emailInfo", emailInfo);
    data.append("userId", Users.auth.userId);

    let result = await Backend.makePostRequest('/sendReferralEmail', data);
    console.log("send referral email response: ", result);
    return result
  }

  static async getTwoResponses(reviewerId): Promise<void> {
    var params = "?reviewerId="+reviewerId;
    let url = API  + '/gettworesponses' + params;

    const result = await Backend.makeGetRequest(url);
    console.log("THE TWO responses: ", result);
    return result;
  }

  static async uploadPhotoToCloudinary(file, userId, type) {
    const url = `https://api.cloudinary.com/v1_1/${
        CLOUDINARY_CLOUD_NAME
    }/upload`;
    var data = new FormData();
    data.append("upload_preset", CLOUDINARY_UPLOAD_PRESET);
    data.append("file", file);
    data.append('tags', `${type},${userId}`);
    data.append('context', `userId=${userId}`);
    let response = await fetch(url, {
          method: 'POST',
          body: data
        })
    const result = await response.json();
    return result;
  }

  static async uploadResumeToCloudinary(file, userId, type) {
    const url = `https://api.cloudinary.com/v1_1/${
        CLOUDINARY_CLOUD_NAME
    }/upload`;
    var data = new FormData();
    data.append("upload_preset", CLOUDINARY_UPLOAD_PRESET);
    data.append("file", file);
    data.append('tags', `${type},${userId},resume`);
    data.append('context', `userId=${userId}`);
    let response = await fetch(url, {
          method: 'POST',
          body: data
        })
    const result = await response.json();
    return result;
  }

  static async serverUp(): Promise<void> {
    let response = await fetch(API  + '/up', {
          method: 'GET',
          mode: 'cors',
          headers: {
            "accept": "application/json",
            "Access-Control-Request-Headers":CORS_HEADER,
            'Access-Control-Allow-Origin':CORS_ORIGIN
          },
        })
    const result = await response.json();
    console.log("Is server up? ", result);
    return result.up == "yes"
  }

  static async fetchUser(userId): Promise<void> {
    console.log("FETCHING userID is: ", userId);
    var params = "?userId="+userId;
    let url = API + "/getUser" + params;
    const result = await Backend.makeGetRequest(url);
    var user = result.user;
    console.log("got the user: ", user)
    return user
  }

  static async fetchUserByUsername(username): Promise<void> {
    console.log("FETCHING userID is: ", username);
    var params = "?username="+username;
    let url = API + "/getUserByUsername" + params;
    const result = await Backend.makeGetRequest(url);
    var user = result.user;
    console.log("got the user: ", user)
    return user
  }

  static async fetchCompanyRep(userId): Promise<void> {
    console.log("FETCHING userID is: ", userId);
    var params = "?companyRepId="+userId;
    let url = API + "/getCompanyRep" + params;
    const result = await Backend.makeGetRequest(url);
    var user = result.companyRep;
    console.log("got the company rep: ", user)
    return user
  }

  static async fetchCandidates(): Promise<void> {
    //var params = "?userId="+userId;
    let url = API + "/getCandidates";
    const result = await Backend.makeGetRequest(url);
    var candidates = result.candidates;
    console.log("got the candidates: ", candidates)
    return candidates
  }

}
