import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Amplify from "aws-amplify";
import {ENV_LOGS_OFF, COGNITO_REGION, COGNITO_APP_CLIENT_ID, COGNITO_USER_POOL_ID} from './constants';

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();

if (!Object.entries) {
  Object.entries = function( obj ){
    var ownProps = Object.keys( obj ),
        i = ownProps.length,
        resArray = new Array(i); // preallocate the Array
    while (i--)
      resArray[i] = [ownProps[i], obj[ownProps[i]]];

    return resArray;
  };
}

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: COGNITO_REGION,
    userPoolId: COGNITO_USER_POOL_ID,
    userPoolWebClientId: COGNITO_APP_CLIENT_ID
  }
});




if (ENV_LOGS_OFF) { //if ENV = production

  console.log = function(){};
  console.warn = function(){};
  console.error = function(){};
}

ReactDOM.render(
  <App />,

  document.getElementById('root')
);
